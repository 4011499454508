import { Controller } from "stimulus"
import { EventHelper } from "../helpers/event-helper";

export default class FlexiblePeriodButtonsController extends Controller {

  public static targets = ["button"]

  public buttonTargets: HTMLElement[];

  public selectPeriod(event: { currentTarget: any; }): void {
    const selectedButton = event.currentTarget;
    const range = selectedButton.getAttribute('data-range');

    // Add 'active' class to selected button, remove from others
    this.buttonTargets.forEach((button): void => {
      if (button === selectedButton) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    });

    EventHelper.dispatch(window, 'flexible-period-buttons-snd-set-range', { value: range });
  }
}