import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { secureFetch } from '../auth/secure_fetch_function';

export class ZipcodeLookupHelper {
  constructor(private zipcodeLookupUrl: string,
    private zipcodeTarget: HTMLInputElement,
    private houseNumberTarget: HTMLInputElement,
    private line1Target: HTMLInputElement,
    private cityTarget: HTMLInputElement,
    private stateTarget: HTMLInputElement,
    private countryCodeTarget: HTMLSelectElement,
    private zipcodeLookupErrorTarget: HTMLElement,
    private allowedCountries: string[]) { }

  public attachZipcodeLookup(): Subscription[] {
    this.setStreetAndCityState();
    const obs1 = merge(
      fromEvent((this.zipcodeTarget as HTMLInputElement), 'blur'),
      fromEvent((this.houseNumberTarget as HTMLInputElement), 'blur')
    ).pipe(
      debounceTime(500)
    ).subscribe(() => {
      if (this.lookupNeeded) {
        this.lookupZipcodeIfValid();
      }
    });

    const obs2 = fromEvent(this.countryCodeTarget, 'change')
      .subscribe(() => {
        this.setStreetAndCityState();
        if (this.lookupNeeded) {
          this.lookupZipcodeIfValid();
        }
      })
    return [obs1, obs2]
  }

  public lookupZipcodeIfValid() {
    this.addressFields.forEach(f => { f.value = '' });

    const usableZipcode =
      this.zipcodeTarget.value !== '' &&
      (this.zipcodeTarget.classList.contains('is-valid') || this.zipcodeTarget.classList.contains('is-pristine'));
    const usableHouseNumber = this.houseNumberTarget.value !== '' &&
      (this.houseNumberTarget.classList.contains('is-valid') || this.houseNumberTarget.classList.contains('is-pristine'));
    if (usableZipcode && usableHouseNumber) {
      this.lookupZipcode();
    }
  }

  private async lookupZipcode() {
    const params = {
      zipcode: this.zipcodeTarget.value,
      house_number: this.houseNumberTarget.value,
      country_code: this.countryCodeTarget.value,
    };

    this.addressFields.forEach(f => { f.closest('.form-group').classList.add('loading') });
    const result = await secureFetch(`${this.zipcodeLookupUrl}?${Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')}`, {
      method: 'GET',
    });
    this.addressFields.forEach(f => { f.closest('.form-group').classList.remove('loading') });

    if (result.status === 200) {
      const body = await result.json();
      this.line1Target.value = body.zipcode_info.address_line1;
      this.cityTarget.value = body.zipcode_info.city;
      if (this.stateTarget) {
        this.stateTarget.value = body.zipcode_info.province;
      }
      this.zipcodeLookupErrorTarget.classList.add('d-none');
      const keyupEvent = new Event('change');
      this.addressFields.forEach(f => { f.dispatchEvent(keyupEvent) });
      this.setStreetAndCityState(true);
    } else {
      this.setStreetAndCityState(true);
      this.zipcodeLookupErrorTarget.classList.remove('d-none');
    }
  }

  private setStreetAndCityState(forceEnabled = false) {
    if (this.lookupNeeded && !forceEnabled) {
      this.addressFields.forEach(f => { f.setAttribute('readonly', '') });
      this.zipcodeLookupErrorTarget.classList.add('d-none');
    } else {
      this.addressFields.forEach(f => { f.removeAttribute('readonly') });
    }
  }

  get lookupNeeded(): boolean {
    return this.allowedCountries.indexOf(this.countryCodeTarget.value) > -1;
  }

  get addressFields(): HTMLInputElement[] {
    return [this.line1Target, this.cityTarget, this.stateTarget].filter(e => e);
  }
}
