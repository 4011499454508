export class ViewportHelper {
  public static isInViewport(element: Element) {
    const bounding = element.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.height > 0 &&
        bounding.width > 0
    );
  }

  public static scrollIntoViewIfNeeded(element: Element, verticalOffset: number = 0) {
    if (!this.isInViewport(element)) {
      const scrollOptions = {
        top: element.getBoundingClientRect().top + window.scrollY - verticalOffset
      };

      window.scrollTo(scrollOptions);
    }
  }
}