import { Controller } from "@stimulus/core";
import { combineLatest, fromEvent, Unsubscribable, pipe } from "rxjs";
import { debounceTime, filter, map, startWith, tap, distinctUntilChanged } from "rxjs/operators";
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import DatePickerController from "../controllers/date_picker_controller";
import GroupSizePickerController from "../controllers/group_size_picker_controller";
import SearchTermController from "../controllers/search_term_controller";
import SearchBarSearchTermUpdatedEvent from "../models/custom_event/search_bar_search_term_updated_event";
import SearchBarPeriodUpdatedEvent from "../models/custom_event/search_bar_period_updated_event";
import SearchBarGroupSizeUpdatedEvent from "../models/custom_event/search_bar_group_size_updated_event";
import FilteredListFilterRemovedEvent from "../models/custom_event/filtered_list_filter_removed_event";
import { SearchTerm } from "../models/search_term";
import { DateHelper } from "../helpers/date-helper";
import { EventHelper } from "../helpers/event-helper";
import { ModalHelper } from "../helpers/modal-helper";
import { HistoryHelper } from "../helpers/history-helper";
import { ViewportHelper } from "../helpers/viewport-helper";
import { ResponsiveHelper } from "../helpers/responsive-helper";

const MODAL_ID = 'modal-search-bar';
const MIN_MODAL_WIDTH = 390;

type ModalMode = 'search-term' | 'date-picker' | 'group-size-picker' | null;
type Position = 'header' | 'main' | 'hero';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})

export default class SearchBarController extends Controller {
  public static targets: string[] = [
    "searchTerm", "searchTermText",
    "arrivalValue", "arrivalFlexiblePeriod", "departureValue", "departureFlexiblePeriod", "periodValue",
    "groupSizeValue", "groupSizeValueSmall", "groupSizeValueWide",
    "modalButton", "submitButton"
  ];

  private searchTermTarget: HTMLSpanElement;
  private searchTermTextTarget: HTMLSpanElement;
  private arrivalValueTarget: HTMLSpanElement
  private departureValueTarget: HTMLSpanElement;
  private periodValueTarget: HTMLSpanElement;
  private groupSizeValueTarget: HTMLSpanElement;
  private groupSizeValueSmallTarget: HTMLSpanElement;
  private groupSizeValueWideTarget: HTMLSpanElement;
  private modalButtonTargets: HTMLElement[];
  private submitButtonTarget: HTMLElement;

  private headerNavBar: HTMLElement;
  private header: HTMLElement;
  private searchBarModal: HTMLElement;
  private searchTermController: SearchTermController;
  private datePickerController: DatePickerController;
  private groupSizePickerController: GroupSizePickerController;
  private childElementsConnected: boolean = false;

  private modalMode: ModalMode = null;

  private readonly periodFilterKey: string = this.data.get('period-filter-key');
  private readonly groupSizeFilterKey: string = this.data.get('group-size-filter-key');

  private selectedGroupSize: number = null;
  private selectedGroupSizeFilterValue: string = null;
  private selectedGroupSizeFilterLabel: string = null;
  private selectedArrival: Date = null;
  private selectedDeparture: Date = null;
  private selectedPeriodFilterValue: string = null;
  private selectedSearchTerm: SearchTerm = { query: null, filterKey: null, filterValue: null };
  private searchTermHasUnconfirmedChanges: boolean = false;
  private position: Position;
  private searchBarTransitioning = true;

  @CombineSubscriptions()
  private subscribers: Unsubscribable;

  public connect(): void {
    this.position = this.data.get('position') as Position;
    this.setLoading(false);

    this.connectChildElements();
    this.initExternalTargets();
    this.setupEventListeners();
    this.setStickingClassWhenStickingBelowHeader();
  }

  public disconnect() {
    if (this.subscribers) {
      this.subscribers.unsubscribe();
    }
  }

  public onSubmit() {
    this.setLoading(true);
    this.closeModal();

    if (this.pairsWithFilterSet) {
      EventHelper.dispatch(this.element, "search-bar-snd-submit-search");
    } else {
      this.submitSearch();
    }
  }

  public onModalButtonClick(event: Event) {
    // prevent 'close modal on header click' from triggering when clicking a modal button
    event.stopImmediatePropagation();

    if (this.delegateModalButtonsToHeaderSearchBar) {
      this.element.dispatchEvent(new CustomEvent('search-bar-snd-delegated-button-click', { bubbles: true, detail: { event, forPosition: 'header' } }));
      return;
    }

    if (this.modalMode === 'search-term') {
      this.markSearchTermAsConfirmed();
    }
    const mode = (event.currentTarget as HTMLElement).dataset.mode as ModalMode;

    if (this.position === 'hero') {
      this.updateSearchBarModalOffsetYCSSVar();
    }
    this.switchToModal(mode);
  }

  public onDelegatedButtonClick(event: CustomEvent) {
    if (event.detail.forPosition === this.position) {
      this.onModalButtonClick(event.detail.event);
    }
  }

  // Filtered list controller events
  public onFilteredListFilterRemoved(event: FilteredListFilterRemovedEvent) {
    switch (event.detail.filterKey) {
      case (this.selectedSearchTerm.filterKey):
        this.searchTermController.clearQueryInput();
        break;
      case (this.periodFilterKey):
        this.datePickerController.clearDates();
        break;
      case (this.groupSizeFilterKey):
        this.groupSizePickerController.clearGroupSize();
        break;
    }
  }

  public clearDates() {
    this.datePickerController.clearDates();
  }

  public clearSearchTerm() {
    this.searchTermController.clearQueryInput();
  }

  public clearGroupSize() {
    this.groupSizePickerController.clearGroupSize();
  }

  // Child controller events
  public onSearchTermUpdated(event: CustomEvent): void {
    const searchTerm = event.detail.searchTerm as SearchTerm;
    this.setSelectedSearchTerm(searchTerm);
    this.searchTermHasUnconfirmedChanges = true;

    if (event.detail.confirmed) {
      this.markSearchTermAsConfirmed();
      this.handleModalInputCompleted();
    }
  }

  public onSearchTermConfirmed(event: CustomEvent): void {
    this.setSelectedSearchTerm(event.detail.searchTerm);
    this.handleModalInputCompleted();
  }

  public onDatePickerUpdated(event: CustomEvent): void {
    this.setSelectedArrival(event.detail.startDate);
    this.setSelectedDeparture(event.detail.endDate);
    if ((this.selectedArrival && this.selectedDeparture) || (this.selectedArrival === null && this.selectedDeparture === null)) {
      if (this.shouldDispatchFilteredListEvents) {
        const filterLabel = [event.detail.startDate, event.detail.endDate].filter(date => date).map(date => DateHelper.humanFormat(date, 'medium')).join(' → ');
        this.element.dispatchEvent(
          new SearchBarPeriodUpdatedEvent({
            startDate: this.selectedArrival,
            endDate: this.selectedDeparture,
            filterKey: this.periodFilterKey,
            filterValue: this.selectedPeriodFilterValue,
            filterLabel
          })
        );
      }
    }
  }

  public onDatePickerConfirmed(event: CustomEvent): void {
    this.onDatePickerUpdated(event);
    this.handleModalInputCompleted();
  }

  public onGroupSizePickerUpdated(event: CustomEvent): void {
    const groupSize = this.parseGroupSize(event.detail.groupSize);

    this.setSelectedGroupSize(groupSize);
    if (this.shouldDispatchFilteredListEvents) {
      this.element.dispatchEvent(
        new SearchBarGroupSizeUpdatedEvent({
          groupSize: this.selectedGroupSize,
          filterKey: this.groupSizeFilterKey,
          filterValue: this.selectedGroupSizeFilterValue,
          filterLabel: this.selectedGroupSizeFilterLabel
        })
      );
    }
    if (this.confirmGroupSizeOnSelect) {
      this.handleModalInputCompleted();
    }
  }

  public onGroupSizePickerGroupSizeConfirmed(): void {
    this.handleModalInputCompleted();
  }

  public onFilteredListLoadingFinished(): void {
    this.setLoading(false);
  }

  public onBookingConsoleDatesUpdated(event: CustomEvent) {
    const { startDate, endDate } = event.detail;
    if ((startDate && endDate) && (new Date(endDate) > new Date(startDate))) {
      if (!this.selectedArrival && !this.selectedDeparture) {
        this.setSelectedArrival(new Date(startDate));
        this.setSelectedDeparture(new Date(endDate));
        this.datePickerController.setDates(this.selectedArrival, endDate);
      }
    }
  }

  public onBookingConsoleGroupSizeUpdated(event: CustomEvent) {
    if (!this.selectedGroupSize) {
      const groupSize = event.detail.groupSize;
      this.setSelectedGroupSize(groupSize);
      this.groupSizePickerController.setGroupSize(groupSize);
    }
  }

  // Private methods
  private switchToModal(mode: ModalMode): void {
    if (mode === this.searchBarModal.dataset.mode) {
      this.closeModal();
    } else {
      this.modalMode = mode;
      this.searchBarModal.dataset.mode = mode;
      this.searchBarTransitioning = !document.body.classList.contains('modal-search-bar-open');
      document.body.classList.add('modal-search-bar-open', 'modal-open', `modal-search-bar-position-${this.position}`);
      const $modal = ModalHelper.openModalWithId(MODAL_ID);
      $modal.on('hidden.bs.modal', () => {
        this.modalMode = null;
        this.searchBarModal.dataset.mode = null;
        document.body.classList.remove('modal-search-bar-open', `modal-search-bar-position-${this.position}`);
        this.markSearchTermAsConfirmed();
        this.setActiveModalButton(null);
      });

      this.setActiveModalButton(mode);

      if (mode === 'search-term') {
        this.searchTermController.focusQueryInput();
      } else if (mode === 'date-picker') {
        this.searchTermController.blurQueryInput();
        this.datePickerController.onFocusNextDate();
      }

      if (this.position === 'hero' || this.position === 'header') {
        this.updateSearchModalOffsetAndWidth();
      }

      if (this.position === 'hero') {
        window.setTimeout(() => this.scrollModalToTopIfOutsideViewport(), 100);
      }

      this.searchBarTransitioning = false;
    }
  }

  private closeModal(): void {
    if (this.modalMode === 'search-term') {
      this.markSearchTermAsConfirmed();
    }
    this.modalMode = null;
    this.searchBarModal.dataset.mode = null;
    ModalHelper.closeModalWithId(MODAL_ID);
    this.setStickingClassWhenStickingBelowHeader();
  }

  private markSearchTermAsConfirmed() {
    if (this.searchTermHasUnconfirmedChanges && this.shouldDispatchFilteredListEvents) {
      this.element.dispatchEvent(
        new SearchBarSearchTermUpdatedEvent(
          { searchTerm: this.selectedSearchTerm, 
            retainFilterKeys: [this.periodFilterKey, this.groupSizeFilterKey] }
        )
      );
    }
    this.searchTermHasUnconfirmedChanges = false;
  }

  private handleModalInputCompleted() {
    switch (this.modalMode) {
      case 'search-term':
        if (this.selectedDeparture && this.pairsWithFilterSet) {
          this.closeModal();
        } else {
          this.switchToModal('date-picker');
        }
        break;
      case 'date-picker': this.switchToModal('group-size-picker'); break;
      case 'group-size-picker': this.closeModal(); break;
    }
  }

  private setActiveModalButton(mode?: ModalMode) {
    this.modalButtonTargets.forEach(button => button.classList.toggle('active', button.dataset.mode === mode));
  }

  private getActiveModalButton(): HTMLElement {
    return this.modalButtonTargets.find(button => button.classList.contains('active'));
  }

  private initExternalTargets() {
    this.searchBarModal = document.getElementById('modal-search-bar');
    this.headerNavBar = document.getElementById('header-nav-bar');
    this.header = document.getElementsByTagName('header')[0];
  }

  private connectChildElements() {
    if (this.childElementsConnected) return;

    if (this.connectChildControllers()) {
      this.childElementsConnected = true;
      this.initSearchFields();
    } else {
      setTimeout(() => this.connectChildElements(), 100);
    }
  }

  private connectChildControllers(): boolean {
    this.searchTermController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("#modal-search-bar [data-controller='search-term']"), 'search-term'
    ) as SearchTermController;
    this.datePickerController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("#modal-search-bar [data-controller='date-picker']"), 'date-picker'
    ) as DatePickerController;
    this.groupSizePickerController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("#modal-search-bar [data-controller='group-size-picker']"), 'group-size-picker'
    ) as GroupSizePickerController;

    return !!(this.searchTermController && this.datePickerController && this.groupSizePickerController);
  }

  private initSearchFields() {
    this.setSelectedSearchTerm({
      query: this.data.get('selectedSearchTerm'),
      filterKey: this.data.get('selectedSearchTermFilterKey'),
      filterValue: this.data.get('selectedSearchTermFilterValue')
    });
    this.setSelectedArrival(DateHelper.parseISODateUTC(this.data.get('selectedArrival')));
    this.setSelectedDeparture(DateHelper.parseISODateUTC(this.data.get('selectedDeparture')));
    this.setSelectedGroupSize(this.parseGroupSize(this.data.get('selectedGroupSize')));

    this.searchTermController.setQueryInputValue(this.selectedSearchTerm.query);
    this.searchTermController.fillDefaultSuggestionsOnConnect();
    this.datePickerController.setDates(this.selectedArrival, this.selectedDeparture);
    this.groupSizePickerController.setGroupSize(this.selectedGroupSize);
  }

  private setSelectedSearchTerm(searchTerm: SearchTerm) {
    this.selectedSearchTerm = searchTerm;
    this.searchTermTextTarget.innerHTML = searchTerm.query;
    this.searchTermTarget.classList.toggle('show', !!searchTerm.query);
  }

  private setSelectedArrival(value: Date) {
    this.selectedArrival = value;
    this.arrivalValueTarget.innerHTML = DateHelper.humanFormat(value, 'shortest');
    this.updateSelectedPeriod();
  }

  private setSelectedDeparture(value: Date) {
    this.selectedDeparture = value;
    this.departureValueTarget.innerHTML = DateHelper.humanFormat(value, 'shortest');
    this.updateSelectedPeriod();
  }

  private updateSelectedPeriod() {
    this.periodValueTarget.classList.toggle('show', !!(this.selectedArrival || this.selectedDeparture));
    if (this.selectedArrival) {
      const departure = this.selectedDeparture || DateHelper.addDays(this.selectedArrival, 1);
      this.selectedPeriodFilterValue = `${DateHelper.isoDateString(this.selectedArrival)},${DateHelper.isoDateString(departure)}`;
    } else {
      this.selectedPeriodFilterValue = null;
    }
  }

  private setSelectedGroupSize(value?: number) {
    this.selectedGroupSize = value;
    this.selectedGroupSizeFilterValue = this.groupSizePickerController.getFilterValueForGroupSize(value);
    this.selectedGroupSizeFilterLabel = this.groupSizePickerController.getLabelForGroupSize(value);

    const valueSmall = this.groupSizePickerController.getSmallLabelForGroupSize(value);
    this.groupSizeValueSmallTarget.innerHTML = valueSmall;
    this.groupSizeValueWideTarget.innerHTML = this.selectedGroupSizeFilterLabel;

    this.groupSizeValueTarget.classList.toggle("show", value > 0);
    this.searchBarModal.classList.toggle('group-size-selected', (value > 0));
  }

  private submitSearch() {
    const searchURL = this.calculateSearchPath();
    if (searchURL !== window.location.pathname) {
      HistoryHelper.visit(searchURL);
    }
  }

  private calculateSearchPath() {
    const queryURLEncoded = encodeURIComponent(this.selectedSearchTerm.query);

    const paths = [];
    const params = [];
    if (this.selectedSearchTerm.query || this.selectedArrival || this.selectedGroupSize) {
      if (this.selectedArrival) {
        paths.push(`/${this.periodFilterKey}:${this.selectedPeriodFilterValue}`);
      }
      if (this.selectedGroupSize) {
        const filterKey = this.groupSizeFilterKey;
        paths.push(`/${filterKey}:${this.selectedGroupSizeFilterValue}`);
      }
      if (this.selectedSearchTerm.filterKey !== SearchTermController.SEARCH_TERM_FILTER_KEY) {
        paths.push(`/${this.selectedSearchTerm.filterKey}:${this.selectedSearchTerm.filterValue}`);
        params.push(`fk=${this.selectedSearchTerm.filterKey}`);
        params.push(`fv=${this.selectedSearchTerm.filterValue}`);
      } else if (this.selectedSearchTerm.query) {
        params.push(`q=${queryURLEncoded}`);
      }
    }
    params.push(`f=${queryURLEncoded}`);
    return [this.searchBaseUrl, ...paths].join('') + '?' + params.join('&') + window.location.hash;
  }

  private get searchBaseUrl(): string {
    return this.data.get('searchBaseUrl');
  }

  private setupEventListeners() {
    if (this.position === 'main') {
      window.addEventListener('scroll', () => this.setStickingClassWhenStickingBelowHeader(), { passive: true });
    }
    if (this.position === 'header') {
      window.addEventListener('resize', () => this.updateSearchBarOffsetXCSSVar(), { passive: true });
      this.header.addEventListener('click', () => {
        this.closeModal();
      });
    }
    if (this.position === 'hero' || this.position === 'header') {
      window.addEventListener('resize',
        () => {
          this.updateSearchBarModalOffsetYCSSVar();
          this.updateSearchModalOffsetAndWidth();
        },
        { passive: true });
    }
    if (this.position === 'hero') {
      this.addScrollOutOfViewSubscriber();
    }
  }

  private updateSearchBarOffsetXCSSVar() {
    // --search-bar-header-offset-x is a CSS variable used to center the searchbar inside the header
    const offsetMiddle = Math.round((this.element.getBoundingClientRect().left + (this.element.getBoundingClientRect().width / 2)) - (window.innerWidth / 2));
    document.documentElement.style.setProperty('--search-bar-header-offset-x', `${offsetMiddle}px`);
  }

  private updateSearchBarModalOffsetYCSSVar() {
    // --search-bar-hero-modal-offset-y is a CSS variable used to position the modal directly below the searchbar
    const searchBarBottomY = Math.round(this.element.getBoundingClientRect().top + this.element.getBoundingClientRect().height) + window.scrollY;
    document.documentElement.style.setProperty('--search-bar-hero-modal-offset-y', `${searchBarBottomY}px`);
  }

  private setStickingClassWhenStickingBelowHeader() {
    if (this.position === 'main') {
      const headerBottom = this.headerNavBar.getBoundingClientRect().bottom;
      const searchBarTop = this.element.getBoundingClientRect().top;
      const isSticking = searchBarTop <= headerBottom;
      document.body.classList.toggle('searchbar-sticking', isSticking);
    }
  }

  private setLoading(loading: boolean) {
    this.submitButtonTarget.classList.toggle('loading', loading);
  }

  private parseGroupSize(groupSize: string): number {
    const intGroupSize = parseInt(groupSize, 10);
    return isNaN(intGroupSize) ? null : intGroupSize;
  }

  private get shouldDispatchFilteredListEvents(): boolean {
    return this.position !== 'main';
  }

  private get pairsWithFilterSet(): boolean {
    return this.data.get('pairsWithFilterSet') === 'true';
  }

  private addScrollOutOfViewSubscriber() {
    window.requestAnimationFrame(() => {
      this.switchNavbarWidget(ViewportHelper.isInViewport(this.element));
    })

    this.subscribers = fromEvent(window, 'scroll', { passive: true }).pipe(
      debounceTime(100),
      map(() => ViewportHelper.isInViewport(this.element)),
      distinctUntilChanged()
    ).subscribe((isFormInsideViewport: boolean) => {
      this.switchNavbarWidget(isFormInsideViewport);
    });
  }

  private switchNavbarWidget(isFormInsideViewport: boolean) {
    document.body.classList.toggle('hide-header-search-bar', isFormInsideViewport);
  }

  private updateSearchModalOffsetAndWidth() {
    setTimeout(() => {
      this.calculateAndSetModalOffsetAndWidth();
    }, this.position === 'header' && this.searchBarTransitioning ? 250 : 0);
  }

  private calculateAndSetModalOffsetAndWidth() {
    if (ResponsiveHelper.isBreakpointAtLeast('lg')) {
      const activeButton = this.getActiveModalButton();
      if (activeButton) {
        const buttonBoundingClientRect = activeButton.getBoundingClientRect();
        let offsetWidth = Math.round(buttonBoundingClientRect.width);
        let extraOffset = 0;
        if (offsetWidth < MIN_MODAL_WIDTH) {
          extraOffset = (MIN_MODAL_WIDTH - offsetWidth) / 2
          offsetWidth = MIN_MODAL_WIDTH;
        }
        const offsetLeft = Math.round((((window.innerWidth / 2) - buttonBoundingClientRect.left) * -1) + (buttonBoundingClientRect.width / 2) + extraOffset);
        document.documentElement.style.setProperty(`--search-bar-${this.position}-modal-offset-x`, `${offsetLeft}px`);
        document.documentElement.style.setProperty(`--search-bar-${this.position}-modal-offset-width`, `${offsetWidth}px`);
      }
    }
  }

  private scrollModalToTopIfOutsideViewport() {
    if (ResponsiveHelper.isBreakpointAtLeast('md')) {
      // use less vertical spacing on small height
      const spacingPx = window.innerHeight > 635 ? 32 : 16;
      const innerModal = this.searchBarModal.querySelector('.modal-body');
      const modalBottomPosition = innerModal.getBoundingClientRect().bottom;
      if (modalBottomPosition > window.innerHeight) {
        // scroll bottom of innerModal to bottom of window
        const modalBottomPosition = innerModal.getBoundingClientRect().bottom;
        const scrollTo = window.scrollY + modalBottomPosition + spacingPx - window.innerHeight;
        window.scrollTo({ top: scrollTo });
      }
    }
  }

  private get delegateModalButtonsToHeaderSearchBar() {
    return (this.position === 'hero' && ResponsiveHelper.isBreakpointBelow('lg'));
  }

  private get confirmGroupSizeOnSelect() {
    return ResponsiveHelper.isBreakpointAtLeast("lg") && this.position !== 'header';
  }
}