export class ServerResponseHelper {
  public static handleServerError(event) {
    const [data, _, request] = event.detail;

    let handled = false;

    if (data.alert) {
      alert(data.alert);
      handled = true;
    } else if (request.status === 500) {
      this.internalErrorMessageAlert();
      handled = true;
    } else if (request.status === 422 && data.detail === 'invalid_authenticity_token') {
      window.location.reload();
      handled = true;
    } 
    return handled;
  }

  public static internalErrorMessageAlert() {
    alert(document.body.dataset.internalErrorMessage);
  }
}
