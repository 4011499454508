import {secureFetch} from "../../auth/secure_fetch_function";
import {LogHelper} from "../../helpers/log-helper";
import {ProcessEnabledFacetsCallback} from "../../controllers/filtered_list_controller";
import HouseImage from '../../images/house.png';
import ClusterImage2Digit from '../../images/cluster-2-digit.png';
import ClusterImage3Digit from '../../images/cluster-3-digit.png';
import ClusterImage4Digit from '../../images/cluster-4-digit.png';
import BlueHouseImage from  '../../images/blue-house.png';
import {Map, InfoWindow, MapOptions} from 'google.maps';

interface Listing {
  id: number;
  title: string;
  lat: number;
  lon: number;
  imgSrc: string;
  listingPath: string;
  fromPrice: string;
  groupSize: number;
  priceFromLabel: string;
  perNightLabel: string;
  averageReviewRating: string;
}

interface ClusterOptions {
  minPoints: number;
  maxZoom: number;
}

interface BoundingBox {
  north: number;
  east: number;
  south: number;
  west: number;
}

interface Coordinates {
  lat: number;
  lng: number;
}

interface ConstructorParams {
  mapTarget: HTMLElement;
  basePath: string;
  filterPath?: string;
  active?: boolean;
  processEnabledFacetsCallback?: ProcessEnabledFacetsCallback | null;
  clusterOptions?: ClusterOptions;
  selectListingId?: number | null;
  openInfoWindow?: boolean;
  coordinates?: Coordinates;
}

export class MapView {
  private mapOptions: MapOptions = {
    mapTypeControl: false,
    zoom: 6,
    minZoom: 2,
    maxZoom: 14,
    zoomControl: false,
    streetViewControl: false,
    rotateControl: false,
    scaleControl: true,
    fullscreenControl: false,
    gestureHandling: 'greedy',
    keyboardShortcuts: true,
    styles: [
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {visibility: "off"}
        ]
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      },
      {
        featureType: "water",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      },
      {
        featureType: "administrative.neighborhood",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      }, {
        featureType: "transit",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      },
      {
        featureType: "landscape",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      }
    ]
  };

  public map: google.maps.Map;
  private lastFetchedFilterPath?: string;
  private latestRequest: Promise<Response>;
  private markers: any[] = [];
  private currentlyOpenInfoWindowDetails?: { marker: google.maps.Marker, infoWindow: google.maps.InfoWindow };
  private spiderfier: any;
  private viewport: BoundingBox;
  private mapTarget: HTMLElement;
  private basePath: string;
  private filterPath: string;
  private active: boolean;
  private processEnabledFacetsCallback: ProcessEnabledFacetsCallback;
  private clusterOptions: ClusterOptions;
  private selectListingId: number;
  private openInfoWindow: InfoWindow;
  private coordinates: Coordinates;

  // The @googlemaps.markerclusterer MarkerClusterer package is imported directly as UMD package
  // from unpkg.com, for performance considerations, and therefore has no typings available in the project
  // A possible improvement is to create a separate javascript pack only loaded in listings#index
  // and include it there.
  private clusterer: any;

  constructor(params: ConstructorParams) {
    const {
      mapTarget,
      basePath,
      filterPath = '',
      active= false,
      processEnabledFacetsCallback = null,
      clusterOptions = {
        minPoints: 20,
        maxZoom: 12,
      },
      selectListingId = null,
      openInfoWindow = false,
      coordinates
    } = params;

    this.mapTarget = mapTarget;
    this.basePath = basePath;
    this.filterPath = filterPath;
    this.active = active;
    this.processEnabledFacetsCallback = processEnabledFacetsCallback;
    this.clusterOptions = clusterOptions;
    this.selectListingId = selectListingId;
    this.openInfoWindow = openInfoWindow;
    this.coordinates = coordinates;

    this.preloadMapsLibrary();
  }

  public async setActive(active: boolean) {
    this.logGroupStart('setActive');
    if (active && !this.active) {
      await this.rebuildMap();
    }
    this.active = active;
    this.logGroupEnd("setActive");
  }

  public async filterPathChanged(filterPath: string, finishedCallback: () => void) {
    this.logGroupStart('filterPathChanged');
    if (this.filterPath === filterPath) {
      return
    }
    this.filterPath = filterPath;
    this.refreshMap(finishedCallback);
    this.logGroupEnd('filterPathChanged');
  }

  public async refreshMap(finishedCallback: () => void) {
    if (this.active) {
      await this.rebuildMap();
      finishedCallback();
    }
  }

  private createCustomControl() {
    const customControlDiv = document.createElement('div');
    customControlDiv.className = 'maps-controls btn-group-vertical';

    const zoomInButton = document.createElement("button");
    zoomInButton.type = "button";
    zoomInButton.className = "maps-controls-zoom-in btn btn-white";
    zoomInButton.addEventListener("click", () => {
      this.map.setZoom(this.map.getZoom() + 1);
    });
    customControlDiv.appendChild(zoomInButton);

    const zoomOutButton = document.createElement("button");
    zoomOutButton.type = "button";
    zoomOutButton.className = "maps-controls-zoom-out btn btn-white";
    zoomOutButton.addEventListener("click", () => {
      this.map.setZoom(this.map.getZoom() - 1);
    });
    customControlDiv.appendChild(zoomOutButton);

    return customControlDiv;
  }

  private async initMap() {
    if (this.map) {
      return;
    }

    this.log("initMap");

    // Ensure maps has completed loading
    await google.maps.importLibrary("maps");

    this.map = new google.maps.Map(this.mapTarget, this.mapOptions);

    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.createCustomControl());

    this.createSpiderfier(this.map);
    this.createClusterer(this.map);

    google.maps.event.addListener(this.map, "click", () => {
      if (this.currentlyOpenInfoWindowDetails) {
        this.closeInfoWindow()
      }
    });

    google.maps.event.addListener(this.map, "bounds_changed", () => {
      if (this.currentlyOpenInfoWindowDetails && !this.isMarkerInViewport(this.currentlyOpenInfoWindowDetails.marker)) {
        this.closeInfoWindow();
      }
    });
  }

  private isMarkerInViewport(marker) {
    const bounds = this.map.getBounds();
    return bounds && bounds.contains(marker.getPosition());
  }

  private async createSpiderfier(map: any) {
    this.spiderfier = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
      legWeight: 1,
      keepSpiderfied: true,
      circleSpiralSwitchover: 9, // default is 9
    });
  }

  private createClusterer(map: any) {
    this.log("createClusterer");
    const renderer = {
      render: ({ count, position }) => {
        let image = ClusterImage2Digit;
        let scaledSize = new google.maps.Size(32, 32);
        let anchor = new google.maps.Point(16, 16);
        if(count < 100) {
          image = ClusterImage2Digit;
          scaledSize = new google.maps.Size(32, 32);
          anchor = new google.maps.Point(16, 16);
        } else if(count < 1000) {
          image = ClusterImage3Digit;
          scaledSize = new google.maps.Size(42, 32);
          anchor = new google.maps.Point(20, 16);
        } else if(count < 10000) {
          image = ClusterImage4Digit;
          scaledSize = new google.maps.Size(48, 32)
          anchor = new google.maps.Point(24, 16);
        }
        const marker = new google.maps.Marker({
          position,
          label: {
            text: `${count}`,
            color: '#4A154B',
            fontWeight: 'bolder',
            fontSize: '14px'
          },
          icon: {
            url: image,
            scaledSize,
            anchor
          },
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count, // above other markers
        });

        marker.addListener('mouseover', () => this.increaseMarkerSize(marker));
        marker.addListener('mouseout', () => this.decreaseMarkerSize(marker));

        return marker;
      }
    };

    const algorithm = new window.markerClusterer.SuperClusterAlgorithm(this.clusterOptions);
    this.clusterer = new window.markerClusterer.MarkerClusterer({
      map: this.map,
      renderer,
      algorithm
    });
  }


  private async rebuildMap() {
    this.log("rebuildMap");
    if (this.filterPath === this.lastFetchedFilterPath) {
      return;
    }
    this.clearMarkers();
    await this.initMap();
    const listings = await this.fetchListings();
    this.createMarkers(listings);
    this.centerMap();
    this.lastFetchedFilterPath = this.filterPath;
  }

  private clearMarkers() {
    this.log('clearMarkers');
    this.markers.forEach((marker) => marker.setMap(null));
    this.clusterer?.clearMarkers();
    this.spiderfier?.clearMarkers();
    this.markers.length = 0;
  }

  private centerMap() {
    this.log("centerMap");
    if (this.coordinates) {
      const coordinates = new google.maps.LatLng(this.coordinates.lat, this.coordinates.lng);
      this.map.setZoom(11);
      this.map.setCenter(coordinates);
    } else if (this.viewport) {
      const bounds = new google.maps.LatLngBounds(
        {lat: this.viewport.south, lng: this.viewport.west},
        {lat: this.viewport.north, lng: this.viewport.east}
      );
      this.map.fitBounds(bounds);
    }
  }

  private createMarkers(listings: Listing[]) {
    this.log("createMarkers");
    listings.forEach((listing: Listing) => {
      if (listing) {
        const marker = new google.maps.Marker({
          position: {lat: listing.lat, lng: listing.lon},
          map: this.map,
          listing,
          icon: {
            url: HouseImage,
            scaledSize: new google.maps.Size(32, 32),
            anchor: new google.maps.Point(16, 16),
          },
        });
        this.addInfoWindowHandler(marker);
        this.markers.push(marker);
        this.spiderfier.addMarker(marker);
        if (listing.id === this.selectListingId) {
          this.showInfoWindow(marker);
          this.waitUntilSpiderfierCanOperate(() => google.maps.event.trigger(marker, 'click'));
        }

        marker.addListener('mouseover', () => this.increaseMarkerSize(marker));
        marker.addListener('mouseout', () => this.decreaseMarkerSize(marker));
      }
    });
    this.clusterer.addMarkers(this.markers);
    this.setLoadingClass(false);
  }

  // When triggering a marker spiderfy programatically, we need to ensure that the map has been loaded
  // Spiderfy uses the Map Projection object internally, and when we trigger a marker click
  // too soon, it will throw an error.
  private waitUntilSpiderfierCanOperate(callback: Function) {
    if (this.map.getProjection().fromLatLngToDivPixel) {
      try { 
        callback();
      } catch(error) { 
        console.error(error) 
      };
    } else {
      google.maps.event.addListenerOnce(
        this.map, 
        'tilesloaded', 
        callback
      )
    }
  }

  private addInfoWindowHandler(marker) {
    marker.addListener("spider_click", () => this.showInfoWindow(marker));
  }

  private showInfoWindow(marker) {
    if (this.currentlyOpenInfoWindowDetails) {
      this.currentlyOpenInfoWindowDetails.infoWindow.close();
      this.setMarkerIcon(this.currentlyOpenInfoWindowDetails.marker, HouseImage)
    }

    const infoWindow = this.createInfoWindow(marker.listing);
    infoWindow.open({ anchor: marker, map: this.map });
    this.setMarkerIcon(marker, BlueHouseImage, { width: 32 * 1.2, height: 32 * 1.2 }, { x: 16 * 1.2, y: 16 * 1.2})
    this.currentlyOpenInfoWindowDetails = { marker, infoWindow };

    google.maps.event.addListener(infoWindow, 'closeclick', () => {
      this.setMarkerIcon(marker, HouseImage)
      this.currentlyOpenInfoWindowDetails = undefined;
    });
  }


  private createInfoWindow(listing: Listing): InfoWindow {
    const reviewDiv = listing.averageReviewRating ? `<div class="iw-review">${listing.averageReviewRating}</div>` : '';

    const content = 
      `<a target="_blank" href=${listing.listingPath} data-controller='offer-card' data-action='offer-card#cardClicked'>
      <div class="iw-img-wrap">
        <img class="iw-img" src="${listing.imgSrc}" alt=${listing.title}>
        <div class="iw-people">${listing.groupSize}</div>
      </div>
      <div class="iw-content">
        <div class="iw-title">${listing.title}</div>
        <div class="iw-price-review">
          <div class="iw-price">
            <div class="from">${listing.priceFromLabel}</div>
            <span class="updatable">${listing.fromPrice}</span>
            <small>${listing.perNightLabel}</small>
          </div>
          ${reviewDiv}
        </div>
      </div>
    </a>`;


    return new google.maps.InfoWindow({
      content: content,
      ariaLabel: listing.title,
    });
  }

  private async fetchListings(): Promise<Listing[]> {
    this.log('fetchListings');
    try {
      this.setLoadingClass(true);
      const request = secureFetch(this.buildUrl, {method: "GET", headers: {"Accept": "application/json"}});
      this.latestRequest = request;
      const response = await request;
      if (request != this.latestRequest) {
        this.log("FetchListings request superseded by newer request");
        return;
      }
      if (response.status >= 200 && response.status < 300) {
        if( this.processEnabledFacetsCallback !== null) {
          this.processEnabledFacetsData(response.headers.get('X-Enabled-Facets'));
        }
        this.processViewportData(response.headers.get('X-Map-Viewport'));
        return await response.json();
      } else {
        throw new Error(`Status ${response.status} fetching listings JSON`);
      }
    } catch (error) {
      console.error("MapView: Failed to fetch listings:", error);
      return [];
    }
  }

  private preloadMapsLibrary() {
    google.maps.importLibrary("maps");
  }

  private processEnabledFacetsData(enabledFacetsJSON: string) {
    try {
      this.processEnabledFacetsCallback(JSON.parse(enabledFacetsJSON));
    } catch (e) {
      LogHelper.logError('JSON from result could not be parsed correctly, skipping', {enabledFacetsJSON, error: e});
    }
  }

  private processViewportData(viewportJSON: string) {
    try {
      this.viewport = JSON.parse(viewportJSON);
    } catch (e) {
      LogHelper.logError('JSON from result could not be parsed correctly, skipping', {viewportJSON, error: e});
    }
  }

  private setMarkerIcon(marker, iconUrl, size = { width: 32, height: 32 }, anchor = { x: 16, y: 16 }) {
    marker.setIcon({
      url: iconUrl,
      scaledSize: new google.maps.Size(size.width, size.height),
      anchor: new google.maps.Point(anchor.x, anchor.y),
    });
  }

  private closeInfoWindow() {
    if (this.currentlyOpenInfoWindowDetails) {
      this.currentlyOpenInfoWindowDetails.infoWindow.close();
      this.setMarkerIcon(this.currentlyOpenInfoWindowDetails.marker, HouseImage);
      this.currentlyOpenInfoWindowDetails = undefined;
    }
  }

  private increaseMarkerSize(marker: google.maps.Marker) {
    const currentIcon = marker.getIcon();
    const hoverIcon: google.maps.Icon = {
      url: currentIcon.url,
      size: new google.maps.Size(currentIcon.size.width * 1.2, currentIcon.size.height * 1.2),
      scaledSize: new google.maps.Size(currentIcon.scaledSize.width * 1.2, currentIcon.scaledSize.height * 1.2),
      anchor: new google.maps.Point(currentIcon.size.width / 2 * 1.2, currentIcon.size.height / 2 * 1.2)
    };
    marker.setIcon(hoverIcon);

    const currentLabel = marker.getLabel();
    if(currentLabel !== undefined) {
      const hoverLabel: google.maps.MarkerLabel = {
        text: currentLabel.text,
        color: currentLabel.color,
        fontSize: '16.8px',
        fontWeight: currentLabel.fontWeight
      };
      marker.setLabel(hoverLabel);
    }
  }

  private decreaseMarkerSize(marker: google.maps.Marker) {
    const currentIcon = marker.getIcon();
    const hoverIcon: google.maps.Icon = {
      url: currentIcon.url,
      size: new google.maps.Size(currentIcon.size.width / 1.2, currentIcon.size.height / 1.2),
      scaledSize: new google.maps.Size(currentIcon.scaledSize.width / 1.2, currentIcon.scaledSize.height / 1.2),
      anchor: new google.maps.Point(currentIcon.size.width / 2 / 1.2, currentIcon.size.height / 2 / 1.2)
    };
    marker.setIcon(hoverIcon);

    const currentLabel = marker.getLabel();
    if(currentLabel !== undefined){
      const hoverLabel: google.maps.MarkerLabel = {
        text: currentLabel.text,
        color: currentLabel.color,
        fontSize: '14px',
        fontWeight: currentLabel.fontWeight
      };
      marker.setLabel(hoverLabel);
    }
  }
  private log(...messages: array) {
    console.timeLog("MapView", ...messages);
  }

  private logGroupStart(name: string) {
    console.group(name);
    console.time("MapView");
  }

  private logGroupEnd(name: string) {
    console.timeEnd("MapView");
    console.groupEnd(name);
  }

  get buildUrl() {
    return (
      `${this.basePath}/${this.filterPath}`
        .replace(/\/\Z/, '')        // remove trailing slash
    );
  }

  private setLoadingClass(loading: boolean) {
    this.mapTarget.classList.toggle('loading', loading);
  }
}
