import { Controller } from '@stimulus/core';
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { ResponsiveHelper } from '../helpers/responsive-helper';
import * as $ from 'jquery';
import { Unsubscribable, fromEvent } from 'rxjs';
import { debounceTime, startWith, throttleTime, map, distinctUntilChanged, pairwise, filter } from 'rxjs/operators';

const HIDE_HEADER_MIN_SCROLL_DOWN = 82;
const SCROLL_THRESHOLD = 32;

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class HeaderController extends Controller {
  public static targets = ['navSubCollapse', 'subNavbar'];

  public element: HTMLElement;

  private readonly navSubCollapseTarget?: HTMLElement;
  private readonly subNavbarTarget?: HTMLElement;
  private readonly hasNavSubCollapseTarget: boolean;
  private readonly hasSubNavbarTarget: boolean;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.scheduleScrollListener();
    this.addClickListener();

    this.subscriber = fromEvent(window, 'load').subscribe(() => {
      this.subscriber = fromEvent(window, 'resize').pipe(
        startWith(null),
        debounceTime(40),
      ).subscribe(() => this.updateNavWidthSSVar());
    });
  }

  public toggleSubNavbar(event) {
    if (this.hasSubNavbarTarget) {
      if (!this.subNavbarTarget.classList.contains('animate')) {
        this.subNavbarTarget.classList.add('animate');
      }
      this.subNavbarTarget.classList.toggle('show');
      const isExpanded = this.subNavbarTarget.classList.contains('show');
      event.currentTarget.setAttribute('aria-expanded', isExpanded.toString());
    }
  }

  private scheduleScrollListener() {
    this.subscriber = fromEvent(window, 'scroll').pipe(
      throttleTime(100),
      map(() => window.scrollY),
      distinctUntilChanged(),
      pairwise(),
      filter(([prevScrollY, currentScrollY]) => Math.abs(currentScrollY - prevScrollY) >= SCROLL_THRESHOLD),
      map(([prevScrollY, currentScrollY]) => ({
        scrollY: currentScrollY,
        direction: currentScrollY > prevScrollY ? 'down' : 'up',
      }))
    ).subscribe(({ scrollY, direction }) => {
      this.onScroll(scrollY, direction);
    });
  }
  
  private onScroll(scrollY: number, direction: 'up' | 'down') {
    if (this.shouldHideHeaderOnScroll) {
      if (scrollY >= 0) {
        if (this.isNavBarVisible && direction === 'down' && this.scrolledPastTopOfPage(scrollY)) {
          this.hideNavBar();
        } else if (!this.isNavBarVisible && direction === 'up') {
          this.showNavBar();
        }
      }
    } else {
      this.showNavBar();
    }
  }
  
  private scrolledPastTopOfPage(scrollY: number): boolean {
    return scrollY > HIDE_HEADER_MIN_SCROLL_DOWN;
  }
  
  private showNavBar() {
    document.body.classList.remove('nav-hide');
  }
  
  private hideNavBar() {
    document.body.classList.add('nav-hide');
    this.hideSubNavbar();
  }
  
  private get isNavBarVisible(): boolean {
    return !document.body.classList.contains('nav-hide');
  }
  
  private get shouldHideHeaderOnScroll(): boolean {
    return ResponsiveHelper.isBreakpointBelow('md');
  }

  private hideSubNavbar() {
    if (this.hasSubNavbarTarget) {
      this.subNavbarTarget.classList.remove('show');
      const toggleElement = this.element.querySelector('[data-action*="toggleSubNavbar"]');
      if (toggleElement) {
        toggleElement.setAttribute('aria-expanded', 'false');
      }
    }
  }  

  private addClickListener(): void {
    document.addEventListener('click', (event: MouseEvent) => {
      if (this.hasNavSubCollapseTarget && !this.navSubCollapseTarget.contains(event.target as Node)) {
        $(this.navSubCollapseTarget).collapse('hide');
      }
    })
  }

  private updateNavWidthSSVar() {
    const navLogoElement = document.querySelector('.nav-logo');
    const navMainElement = document.querySelector('.nav-main .main-nav .navbar-nav');
    const navSubElement = document.querySelector('.nav-sub');

    if (navLogoElement && navMainElement && navSubElement) {
      const navLogoWidth = navLogoElement.getBoundingClientRect().width;
      let navMainWidth = navMainElement.getBoundingClientRect().width;
      let navSubWidth = navSubElement.getBoundingClientRect().width;

      if (ResponsiveHelper.isBreakpointAtLeast('xxl')) {
        if ((navLogoWidth + navMainWidth) > navSubWidth) {
          navSubWidth = navLogoWidth + navMainWidth;
        } else {
          navMainWidth = navSubWidth - navLogoWidth;
        }
      }

      document.documentElement.style.setProperty('--nav-main-width', `${navMainWidth}px`);
      document.documentElement.style.setProperty('--nav-sub-width', `${navSubWidth}px`);
    }

  }


}
