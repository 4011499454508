import { Controller } from '@stimulus/core';
import {fromEvent, Unsubscribable} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {CombineSubscriptions, DestroySubscribers} from "ngx-destroy-subscribers";

@DestroySubscribers({
    destroyFunc: 'disconnect'
})
export default class OfferBarController extends Controller {

    @CombineSubscriptions()
    private subscriber: Unsubscribable;

    public connect(){
        this.subscriber = fromEvent(window, 'scroll').pipe(
            debounceTime(10)
        ).subscribe(() => {
            this.element.classList.toggle('fixed', [121, 39].includes(this.element.getBoundingClientRect().top));
        })
    }

    public disconnect() {
        this.subscriber.unsubscribe();
    }
}