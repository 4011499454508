import { Controller } from '@stimulus/core';
import { CookieHelper } from '../helpers/cookie-helper'

export default class OfferCardController extends Controller {
  public cardClicked(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.preventDefault();

    const listingQueryString = CookieHelper.get('listing-query-string');
    const baseHref = (this.element as HTMLAnchorElement).href;
    const href = listingQueryString ? `${baseHref}?${listingQueryString}` : baseHref;

    window.open(href);
  }
}
