import { Controller } from '@stimulus/core';
import { EventHelper } from '../helpers/event-helper';

export default class ListingItemController extends Controller {
  public bookNowClicked(event: Event) {
    EventHelper.dispatch((event.target as Element), 'listing-item-snd-book-now');
  }

  public changePeriodClicked() {
    EventHelper.dispatch((event.target as Element), 'listing-item-snd-change-period');
  }

  public changeGroupSizeClicked() {
    EventHelper.dispatch((event.target as Element), 'listing-item-snd-change-group-size');
  }
}
