import { Controller } from '@stimulus/core';
import Container from 'typedi';
import { TimeToken } from '../subjects/time_subject';
import { LogHelper } from '../helpers/log-helper';

export default class TimeController extends Controller {
  private clockTickIntervalPointer: number;
  private clockDelta = 0;

  public initialize() {
    this.setupClockTickSender()
  }

  public disconnect() {
    window.clearInterval(this.clockTickIntervalPointer);
  }

  private setupClockTickSender() {
    if (this.clockTickIntervalPointer) {
      window.clearInterval(this.clockTickIntervalPointer);
    }
    this.clockTickIntervalPointer = window.setInterval(() => {
      const now = +(new Date());
      Container.get(TimeToken).next({
        real_now: now,
        clock_delta: this.clockDelta
      });
    }, 1000);
  }
}
