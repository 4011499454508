import { Subject } from 'rxjs';
import { Service, Token } from 'typedi';
import { Like } from '../models/like';

export const LikeToken = new Token<LikeSubject>();

class LikeFactory {
  create() {
    return new LikeSubject();
  }
}

@Service({
  id: LikeToken,
  factory: [LikeFactory, "create"]
})
export class LikeSubject extends Subject<Like>  { }