import * as $ from 'jquery';
import { Controller } from '@stimulus/core';
import { ModalHelper } from '../helpers/modal-helper';

export default class StartNameAndAddressController extends Controller {
  public openModal(event) {
    const url = event.currentTarget.dataset.url;
    ModalHelper.openModalWithUrl(url);
    $(this.element.closest('.modal')).modal('hide');
  }
}
