import { Controller } from '@stimulus/core';
import * as $ from 'jquery';
import { CombineSubscriptions, DestroySubscribers } from 'ngx-destroy-subscribers';
import { fromEvent, Unsubscribable } from 'rxjs';
import { EventHelper } from '../helpers/event-helper';
import { LogHelper } from '../helpers/log-helper';
import { UploadQueueEntry } from './file_upload_controller';
import * as loadImage from 'blueimp-load-image';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class OrderedUploadController extends Controller {
  public static targets = ['form', 'dropzone', 'filePreviews', 'filePreviewTemplate', 'dummyNode',
    'alertContainer', 'alertErrorLine', 'alertErrorLineFilenames', 'alertMaxReachedLine', 'alertFilesizeLine',
    'alertMaxReachedLineFilenames', 'alertFilesizeLineFilenames',
    'submit', 'minimumCountWarning'];

  private readonly formTarget!: HTMLFormElement;
  private readonly dropzoneTarget!: HTMLDivElement;
  private readonly filePreviewsTarget!: HTMLDivElement;
  private readonly filePreviewTemplateTarget!: HTMLTemplateElement;
  private readonly dummyNodeTarget!: HTMLDivElement;

  private readonly alertContainerTarget!: HTMLDivElement;

  private readonly alertErrorLineTarget!: HTMLDivElement;
  private readonly alertErrorLineFilenamesTarget!: HTMLDivElement;

  private readonly alertMaxReachedLineTarget!: HTMLDivElement;
  private readonly alertMaxReachedLineFilenamesTarget!: HTMLDivElement;

  private readonly alertFilesizeLineTarget!: HTMLDivElement;
  private readonly alertFilesizeLineFilenamesTarget!: HTMLDivElement;

  private readonly minimumCountWarningTarget!: HTMLDivElement;
  private readonly submitTarget: HTMLButtonElement;

  private maxUploadItems: number;
  private minUploadItems: number;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.initializeMaxUploadItems();

    this.filePreviewsTarget.querySelectorAll('[data-file-preview]').forEach(node => {
      this.attachDraggableEvents(node as HTMLElement);
    })

    window.addEventListener("drop", (event: DragEvent) => {
      this.filePreviewsTarget.querySelectorAll('div[data-file-preview]:not(.drop-dummy)').forEach(row => row.classList.remove('d-none'));
      this.dummyNodeTarget.classList.add('d-none');
      $(this.dummyNodeTarget).insertAfter($(this.filePreviewsTarget).find('div[data-file-preview]:last'));

      event.preventDefault();
    }, false);

    this.subscriber = fromEvent(this.filePreviewsTarget, 'drop').subscribe((event: DragEvent) => {
      event.preventDefault();
      if (event.dataTransfer.types.indexOf('Files') === -1) {
        const data = JSON.parse(event.dataTransfer.getData('text'));
        const draggableElement = this.filePreviewsTarget.querySelector('div[data-index="' + data.index + '"]');
        draggableElement.classList.remove('d-none');
        draggableElement.classList.remove('dragging');
        const $imgDropzone = $(event.target as HTMLElement).parents('div[data-index]:first');

        this.filePreviewsTarget.insertBefore(draggableElement, $imgDropzone.get(0));
        this.dummyNodeTarget.classList.add('d-none');
        $(this.dummyNodeTarget).insertAfter($(this.filePreviewsTarget).find('div[data-file-preview]:last'));
        setTimeout(() => {
          // added a small timeout to have the event dispatch handling finished
          this.updateHiddenInputOrder();
        }, 80);
      }
    });
    this.subscriber = fromEvent(this.filePreviewsTarget, 'dragover').subscribe((event: DragEvent) => {
      if (event.dataTransfer.types.indexOf('Files') === -1) {
        const $imgDropzone = $(event.target as HTMLElement).parents('div[data-index]:first');
        if (!$imgDropzone.hasClass('drop-dummy')) {
          if (event['layerX'] && event['layerX'] > $imgDropzone.width() / 2 && !$imgDropzone.is(':first-child')) {
            $(this.dummyNodeTarget).insertAfter($($imgDropzone)).removeClass('d-none');
          } else {
            $(this.dummyNodeTarget).insertBefore($($imgDropzone)).removeClass('d-none');
          }
        }
      }
      event.preventDefault();
    });
    if (this.data.has('singleFile') && this.filePreviewsTarget.querySelector('[data-existing="true"]')) {
      this.dropzoneTarget.classList.add("d-none");
    }
    this.checkUploadAllowed();
  }

  public updateFileList(e: CustomEvent) {
    const { uploads } = e.detail;

    this.addOrUpdatePreviewDivs(uploads);
    this.updateHiddenInputOrder();
    this.saveWhenDoneUploading(uploads);
  }

  public removeFile(event: MouseEvent) {
    event.stopPropagation();
    const linkElement = event.currentTarget as HTMLLinkElement;
    const $parent = $(linkElement).parents('div[data-file-preview]');
    if ($parent.attr('data-existing') !== 'true') {
      const id = $parent.attr('data-upload-queue-id');
      const signedId = $parent.attr('data-upload-queue-signed-id');

      EventHelper.dispatch(this.element, 'ordered-upload-snd-remove-file', { id, signedId });
    }
    $parent.remove();
    if (this.data.has('singleFile')) {
      this.dropzoneTarget.classList.remove("d-none");
    }
    setTimeout(() => {
      // added a small timeout to have the event dispatch handling finished
      this.updateHiddenInputOrder();
      this.checkUploadAllowed();
    }, 80);
  }

  public handleErroredUploads(event: CustomEvent) {
    const erroredUploads: UploadQueueEntry[] = event.detail.erroredUploads;
    if (erroredUploads.length > 0) {
      this.alertContainerTarget.classList.remove('d-none');
      this.alertErrorLineTarget.classList.remove('d-none');
      this.filePreviewsTarget.querySelectorAll(erroredUploads.map(item => `[data-file-preview][data-upload-queue-id="${item.id}"]`).join(', ')).forEach((row: HTMLElement) => {
        row.remove();
      });
      erroredUploads.forEach(upload => {
        this.alertErrorLineFilenamesTarget.insertAdjacentHTML('beforeend', `${upload.file.name}<br>`);
      })
    }

  }

  public handleRefusedUploads(event: CustomEvent) {
    const { filename, reason } = event.detail;
    this.alertContainerTarget.classList.remove('d-none');
    if (reason === 'max-items-reached') {
      this.alertMaxReachedLineTarget.classList.remove('d-none');
      this.alertMaxReachedLineFilenamesTarget.insertAdjacentHTML('beforeend', `${filename}<br>`);
    } else if (reason === 'file-too-large') {
      this.alertFilesizeLineTarget.classList.remove('d-none');
      this.alertFilesizeLineFilenamesTarget.insertAdjacentHTML('beforeend', `${filename}<br>`);

    }
  }


  private saveWhenDoneUploading(uploads: UploadQueueEntry[]) {
    if (uploads.every(row => row.state === 'ready') && uploads.length > 0) {
      this.submitTarget.click();
    }
  }

  private updateHiddenInputOrder() {
    this.element.querySelectorAll('[data-file-preview]:not(.drop-dummy)').forEach((previewDiv: HTMLDivElement, index) => {
      const input: HTMLInputElement = this.element.querySelector('input[type=hidden][value="' + previewDiv.getAttribute('data-upload-queue-signed-id') + '"]');
      if (input) {
        input.name = input.name.replace(/\[\d+\]/g, `[${index}]`);
      }
    });
  }

  private addOrUpdatePreviewDivs(uploads: UploadQueueEntry[]) {
    const existingDivs = this.filePreviewsTarget.querySelectorAll('div[data-existing]');
    uploads.filter(row => ['error', 'error_dispatched'].indexOf(row.state) === -1).forEach((row, index) => {
      const existingNode = this.filePreviewsTarget.querySelector("div[data-upload-queue-id='" + row.id + "']");
      if (existingNode) {
        (existingNode.querySelector("div[data-upload-queue-progress]") as HTMLDivElement).style.width = `${row.progress}%`;
        existingNode.querySelector(".progress").classList.toggle('d-none', !this.isPendingFile(row));
        existingNode.setAttribute('data-upload-queue-state', row.state);
        existingNode.setAttribute('data-upload-queue-signed-id', row.signed_id);
      }
      else {
        loadImage(
          URL.createObjectURL(row.file),
          (img) => {
            document.getElementById(`canvas-preview-${row.id}`).appendChild(img);
          },
          { meta: true, orientation: true }
        );
        const content = this.filePreviewTemplateTarget.innerHTML
          .replace(/{{file_name}}/gi, row.file.name)
          .replace(/{{state}}/gi, row.state)
          .replace(/{{id}}/gi, `${row.id}`)
          .replace(/{{index}}/gi, `${index + existingDivs.length}`)
          // .replace(/{{error}}/gi, `${row.error_reason}`)
          .replace(/{{signed_id}}/gi, `${row.signed_id}`)
          .replace(/{{progress}}/gi, `${row.progress}`);
        this.filePreviewsTarget.insertAdjacentHTML('beforeend', content);
        // content
        const newNode = this.filePreviewsTarget.querySelector("div[data-upload-queue-id='" + row.id + "']");
        this.attachDraggableEvents(newNode as HTMLElement);
      }
    });
    $(this.dummyNodeTarget).insertAfter($(this.filePreviewsTarget).find('div[data-file-preview]:last'));
    this.checkUploadAllowed();
  }

  private attachDraggableEvents(node: HTMLElement) {
    this.subscriber = fromEvent(node, 'dragstart').subscribe((event: DragEvent) => {

      const target = event.target as HTMLElement;
      if (this.filePreviewsTarget.querySelectorAll('div[data-file-preview]:not(.drop-dummy').length === 1) {
        this.dummyNodeTarget.classList.remove('d-none');
      }
      event.dataTransfer.clearData();
      event.dataTransfer.setData('text/plain', JSON.stringify({
        index: target.getAttribute('data-index'),

      }));
      target.classList.add('dragging');

      setTimeout(() => {
        target.classList.add('d-none');
        $(target).insertAfter($(this.filePreviewsTarget).find('div[data-file-preview]:last'));
      }, 1);
    });
  }

  private initializeMaxUploadItems() {
    try {
      this.maxUploadItems = this.data.get("maxItems") ? parseInt(this.data.get("maxItems"), 0) : null;
      this.minUploadItems = this.data.get("minItems") ? parseInt(this.data.get("minItems"), 0) : null;
    }
    catch (e) {
      LogHelper.logError('max upload items is not a number');
    }
  }

  private checkUploadAllowed(): boolean {
    let uploadAllowed = true;
    const numberOfItems = this.filePreviewsTarget.querySelectorAll('div[data-file-preview]:not(.drop-dummy)').length;
    if (this.maxUploadItems) {
      uploadAllowed = numberOfItems <= this.maxUploadItems;
    }
    this.element.setAttribute('data-file-upload-upload-allowed', `${uploadAllowed}`);

    if (this.minUploadItems && numberOfItems > 0 && numberOfItems < this.minUploadItems) {
      const missing = this.minUploadItems - numberOfItems;
      this.minimumCountWarningTarget.classList.remove('d-none');
      const messageAttr = missing === 1 ? 'messageSingular' : 'messagePlural';
      const message = this.minimumCountWarningTarget.dataset[messageAttr].replace('{{count}}', missing.toString());
      this.minimumCountWarningTarget.innerHTML = message;
    } else {
      this.minimumCountWarningTarget.classList.add('d-none');
    }
    return uploadAllowed;
  }

  private isPendingFile(item: UploadQueueEntry) {
    return ['waiting', 'allowedforupload', 'inprogress'].includes(item.state);
  }

}
