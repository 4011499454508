import * as $ from 'jquery';
import { Controller } from '@stimulus/core';

export default class extends Controller {

  public connect() {
    this.element.querySelectorAll('input[data-target]').forEach(input => {
      const id = input.getAttribute('data-target');
      const target: HTMLElement = document.getElementById(id);
      $(target).on('hide.bs.collapse', () => {
        target.querySelectorAll('input, select, textarea').forEach(node => {
          (node as HTMLFormElement).setAttribute('disabled', 'disabled');
        });
      });
      $(target).on('show.bs.collapse', () => {
        target.querySelectorAll('input, select, textarea').forEach(node => {
          (node as HTMLFormElement).removeAttribute('disabled');
        });
      });
    });
  }

  public toggleRadioSections(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    let clickedButton = event.target as HTMLElement;
    if (clickedButton.parentElement.children.length === 2 &&
      clickedButton.classList.contains('active')) {
      clickedButton = clickedButton.closest('.btn-group').querySelector('.btn:not(.active)');
    }
    if (clickedButton !== this.element && !clickedButton.classList.contains('active')) {
      const selectedInput = clickedButton.querySelector('input');
      const id = selectedInput.getAttribute('data-target');
      const target: HTMLElement = document.getElementById(id);
      if (!target.classList.contains('collapsing')) {
        const direction: 'show' | 'hide' = $(target).is(':hidden') ? 'show' : 'hide';
        if (this.isToggleAllowed(target, direction)) {
          clickedButton.parentElement.querySelectorAll('input').forEach(input => input.removeAttribute('checked'));
          selectedInput.setAttribute('checked', 'checked');
          $(target).collapse(direction);
          this.element.querySelectorAll(`input:not([data-target='${target}'])`).forEach(otherInput => {
            $(document.getElementById(otherInput.getAttribute('data-target'))).collapse('hide');
          });
          this.setActiveButton(clickedButton);
        } else {
          alert(target.getAttribute(`data-disable-${direction}-if-div-not-empty-message`));
        }
      }
    }
  }

  private isToggleAllowed(target: HTMLElement, direction: 'show' | 'hide') {
    if (target.hasAttribute(`data-disable-${direction}-if-div-not-empty`)) {
      const checkDivQuery = target.getAttribute(`data-disable-${direction}-if-div-not-empty`);
      const checkDiv = target.querySelector(checkDivQuery);
      if (checkDiv && checkDiv.innerHTML.trim() !== '') {
        return false
      }
    }
    return true;
  }

  private setActiveButton(selectedButton: HTMLElement) {
    this.element.querySelectorAll('.btn').forEach(btn => btn.classList.remove('active'))
    selectedButton.classList.add('active');
  }

}
