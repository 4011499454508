import { Controller } from '@stimulus/core';
import { ModalHelper } from '../../helpers/modal-helper';
import { EventHelper } from '../../helpers/event-helper';
import { secureFetch } from "../../auth/secure_fetch_function";

interface Item {
  itemId: string;
  itemHTML: string;
}
interface ItemSavedResponse {
  itemId: string;
  itemHTML: string;
  items: Item[];
}

export default class EditableListController extends Controller {
  public async deleteItem(event: Event) {
    event.preventDefault();
    const item = event.currentTarget as HTMLElement;

    const prompt = item.dataset.deletePrompt;
    if (prompt && !confirm(prompt)) {
      event.preventDefault();
      return
    }
    const url = item.dataset.deleteUrl;
    const result = await secureFetch(url, { method: 'DELETE' });

    if (result.status >= 200 && result.status <= 299) {
      const containerId = item.dataset.containerId;
      if (containerId) {
        const container = document.getElementById(containerId);
        container.parentNode.removeChild(container);
      }
      const response = await result.json();
      if (response.items) {
        response.items.forEach(i => this.updateOrAddItem(i.itemId, i.itemHTML))
      }
      if (item.dataset.deleteCloseModalId) {
        ModalHelper.closeModalWithId(item.dataset.deleteCloseModalId);
      }
      EventHelper.dispatch(window, 'place-wizard--editable-list-snd-item-deleted');
    } else {
      alert(document.body.dataset.internalErrorMessage);
    }
  }

  public itemSaved(event: CustomEvent) {
    const [response]: [ItemSavedResponse] = event.detail;

    if (response.itemId && response.itemHTML) {
      this.updateOrAddItem(response.itemId, response.itemHTML, this.data.get('listId'));
    } else if (response.items) {
      response.items.forEach(i => this.updateOrAddItem(i.itemId, i.itemHTML))
    }
    EventHelper.dispatch(window, 'place-wizard--editable-list-snd-item-saved');
  }

  private updateOrAddItem(itemId: string, itemHTML: string, listId?: string) {
    const item = document.getElementById(itemId)
    if (item) {
      item.outerHTML = itemHTML;
    } else if (listId) {
      const list = document.getElementById(listId);
      list.innerHTML += itemHTML;
    }
  }
}
