import { Controller } from '@stimulus/core';
import { fromEvent, Unsubscribable } from 'rxjs';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';
import { filter, debounceTime } from 'rxjs/operators';
import HouseImage from '../images/house.png';

declare var google: any;

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class RichMapsController extends Controller {
  private mapOptions: MapOptions = {
    mapTypeControl: false,
    zoom: 14,
    zoomControl: true,
    streetViewControl: false,
    rotateControl: true,
    scaleControl: true,
    fullscreenControl: true,
    gestureHandling: 'auto',
    keyboardShortcuts: false,
    styles: [
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {visibility: "off"}
        ]
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      },
      {
        featureType: "water",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      },
      {
        featureType: "administrative.neighborhood",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      }, {
        featureType: "transit",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      },
      {
        featureType: "landscape",
        elementType: "labels",
        stylers: [
          {visibility: "off"}
        ]
      }
    ]
  };

  private SCROLL_BUFFER = 150;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  private map;
  private marker;

  public async initialize() {
    // Preload maps on initialize
    await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
  }

  public connect(): void {
    this.setupScrollSubscriber();
    if (this.isTopInViewport()) {
      this.initMap();
    }
  }

  private async initMap() {
    if (this.map) {
      return;
    }

    const coordinates = {
      lat: parseFloat(this.data.get('latitude')),
      lng: parseFloat(this.data.get('longitude'))
    };

    // Ensure maps has completed loading
    const {Map} = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(this.element, {...this.mapOptions, center: coordinates});

    this.marker = new google.maps.Marker({
      position: coordinates,
      map: this.map,
      icon: {
        url: HouseImage,
        scaledSize: new google.maps.Size(32, 32),
        anchor: new google.maps.Point(16, 16)
      }
    });
  }

  private setupScrollSubscriber(): void {
    this.subscriber = fromEvent(window, 'scroll').pipe(
      filter(() => !this.map),
      debounceTime(40),
      filter(() => this.isTopInViewport()),
    ).subscribe(() => this.initMap());
  }

  private isTopInViewport(): boolean {
    const bounding = this.element.getBoundingClientRect();
    const isTopVisible = bounding.top >= 0;
    const isBufferVisible = bounding.top - this.SCROLL_BUFFER <= (window.innerHeight || document.documentElement.clientHeight);
    return isTopVisible && isBufferVisible;
  }
}
