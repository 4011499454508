import { CombineSubscriptions, DestroySubscribers } from 'ngx-destroy-subscribers';
import { Unsubscribable } from 'rxjs';
import { Controller } from '@stimulus/core';
import Container from 'typedi';
import { FormatHelper } from '../helpers/format-helper';
import { Time } from '../models/time';
import { TimeToken } from '../subjects/time_subject';
import { LogHelper } from '../helpers/log-helper';
import { ViewportHelper } from '../helpers/viewport-helper';
// import {EventHelper} from "../helpers/event-helper";

const SECONDS_PER_DAY = 60 * 60 * 24;
const SECONDS_PER_HOUR = 60 * 60;
const SECONDS_PER_MINUTE = 60;

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class ClockController extends Controller {
  public static targets = ['days', 'daysBox', 'hours', 'hoursBox', 'minutes', 'minutesBox', 'seconds', 'secondsBox'];

  public readonly daysTarget!: Element;
  public readonly daysBoxTarget!: Element;
  public readonly hoursTarget!: Element;
  public readonly hoursBoxTarget!: Element;
  public readonly minutesTarget!: Element;
  public readonly minutesBoxTarget!: Element;
  public readonly secondsTarget!: Element;
  public readonly secondsBoxTarget!: Element;
  public readonly hasDaysBoxTarget: boolean;

  public timeInfo: Time;
  public locale: string;
  public initializing = true;
  public onlyUpdateInViewport = false;

  @CombineSubscriptions()
  public subscriber: Unsubscribable;

  public connect() {
    this.locale = this.data.get('locale');
    this.element['update'] = this.update.bind(this);
    this.onlyUpdateInViewport = this.data.has('only-in-viewport');

    this.initializing = false;

    this.timeInfo = Container.get(TimeToken).getValue();
    this.update(true);
    this.subscriber = Container.get(TimeToken).subscribe(timeInfo => {
      this.timeInfo = timeInfo;
      this.update();
    });
  }

  public update(forceUpdate: boolean = false) {
    LogHelper.log(`Clock: update (${this.initializing}, ${this.data.get('endsAt')}), ${forceUpdate})`);
    if (this.initializing) { return }

    const endsAt = parseInt(this.data.get('endsAt'), 0);
    if (isNaN(endsAt)) { return }

    const shouldUpdate = forceUpdate || !this.onlyUpdateInViewport || (this.onlyUpdateInViewport && ViewportHelper.isInViewport(this.element));
    if (shouldUpdate) {
      // let remainingSeconds = endsAt - this.timeInfo.real_now / 1000;
      let remainingSeconds = endsAt - Date.now() / 1000;
      if (remainingSeconds < 0) { remainingSeconds = 0 }
      // if( remainingSeconds === 0 ) { EventHelper.dispatch(window, 'clock-snd-time-ran-out'); }

      const remainingTime = this.splitRemainingTime(remainingSeconds);
      this.secondsTarget.innerHTML = FormatHelper.prefixZero(remainingTime.seconds);
      this.secondsBoxTarget.classList.add('d-flex');
      this.minutesBoxTarget.classList.toggle('obsolete', remainingSeconds < SECONDS_PER_MINUTE);
      const minutesString = FormatHelper.prefixZero(remainingTime.minutes);
      const hoursString = FormatHelper.prefixZero(remainingTime.hours);
      const daysString = remainingTime.days.toString();
      if (this.minutesTarget.innerHTML !== minutesString) {
        this.minutesTarget.innerHTML = minutesString;
      }
      if (this.hoursTarget.innerHTML !== hoursString) {
        this.hoursTarget.innerHTML = hoursString;
      }
      if (this.hasDaysBoxTarget && this.daysTarget.innerHTML !== daysString) {
        this.daysTarget.innerHTML = daysString;
      }
      this.hoursBoxTarget.classList.toggle('obsolete', remainingSeconds < SECONDS_PER_HOUR);
      if(this.hasDaysBoxTarget){
        this.daysBoxTarget.classList.toggle('obsolete', remainingSeconds < SECONDS_PER_DAY);
        this.daysBoxTarget.setAttribute('data-many', (remainingTime.days >= 2).toString());
      }
    } else {
      LogHelper.log('Clock: not updating - outside viewport', this.element);
    }
  }

  public onAttributeChange(event: CustomEvent) {
    const { key } = event.detail;
    if (key === 'ends_at') {
      this.update(true);
    }
  }

  public disconnect() {
    this.subscriber.unsubscribe();
  }

  private splitRemainingTime(remainingSeconds: number): any {
    if (remainingSeconds < 0) {
      return { 'days': 0, 'hours': 0, 'minutes': 0, 'seconds': 0 }
    } else {
      return {
        'days': Math.floor(remainingSeconds / SECONDS_PER_DAY),
        'hours': Math.floor((remainingSeconds % SECONDS_PER_DAY) / SECONDS_PER_HOUR),
        'minutes': Math.floor((remainingSeconds % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE),
        'seconds': Math.floor(remainingSeconds % SECONDS_PER_MINUTE)
      }
    }
  }
}
