import { Controller } from '@stimulus/core';
import { fromEvent, Unsubscribable } from 'rxjs';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';
import { filter, debounceTime } from 'rxjs/operators';
import { MapView } from "../models/filtered_list/map_view";


@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class MapsController extends Controller {
  public static targets = [
    'map'
  ];

  private mapView: MapView;
  private readonly mapTarget?: HTMLElement;

  private SCROLL_BUFFER: number = 150;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect(): void {
    this.setupScrollSubscriber();
    if (this.isTopInViewport()) {
      this.initMap();
    }
  }

  private initMap(): void {
    if (this.mapView) {
      return;
    }

    const coordinates: { lat: number, lng: number } = {
      lat: parseFloat(this.data.get('latitude')),
      lng: parseFloat(this.data.get('longitude'))
    };

    this.mapView = new MapView({
      mapTarget: this.mapTarget,
      basePath: this.listingsPath,
      coordinates,
      selectListingId: this.listingId
    });

    this.mapView.setActive(true);
  }

  private setupScrollSubscriber(): void {
    this.subscriber = fromEvent(window, 'scroll').pipe(
      filter(() => !this.mapView),
      debounceTime(40),
      filter(() => this.isTopInViewport()),
    ).subscribe(() => this.initMap());
  }

  private isTopInViewport(): boolean {
    const bounding = this.element.getBoundingClientRect();
    const isTopVisible = bounding.top >= 0;
    const isBufferVisible = bounding.top - this.SCROLL_BUFFER <= (window.innerHeight || document.documentElement.clientHeight);
    return isTopVisible && isBufferVisible;
  }

  get listingsPath() {
    return this.data.get('listingsPath')
  }

  get listingId() {
    const listingId = this.data.get('listingId');
    return listingId ? parseInt(listingId) : null;
  }
}
