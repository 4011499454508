import { BehaviorSubject } from 'rxjs';
import { Service, Token } from 'typedi';
import { Time } from '../models/time';

export const TimeToken = new Token<TimeSubject>();

class TimeFactory {
  create() {
    return new TimeSubject({
      real_now: new Date().getTime(),
      clock_delta: 0,
    });
  }
}

@Service({
  id: TimeToken,
  factory: [TimeFactory, "create"]
})
export class TimeSubject extends BehaviorSubject<Time>  { }
