import ModalFormController from '../modal_form_controller';

export default class extends ModalFormController {
  public onAjaxSuccessJson(event: CustomEvent) {
    const [data] = event.detail;
    alert(data.message);
    super.onAjaxSuccessJson(event);
    window.location.reload();
  }
}
