import { Controller } from "stimulus"
import {EventHelper} from "../helpers/event-helper";
import {CombineSubscriptions} from "ngx-destroy-subscribers";
import {fromEvent, pipe, Subscription, Unsubscribable} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {secureFetch} from "../auth/secure_fetch_function";
import {LogHelper} from "../helpers/log-helper";

export default class FaqSearchController extends Controller {
  public static targets = [
    'queryInput', 'autoCompleteResults', 'autoCompleteTemplate',
    'clearInputBtn', 'autoCompleteItem', 'startDateISO8601', 'endDateISO8601',
    'groupSizeSelect', 'datePicker', 'flexiblePeriodInput'
  ];
  private MIN_SEARCH_LENGTH = 3;

  private readonly queryInputTarget!: HTMLInputElement;
  private readonly clearInputBtnTarget!: HTMLElement;
  private readonly autoCompleteResultsTarget!: HTMLElement;
  private readonly autoCompleteItemTargets!: HTMLElement[];
  private readonly autoCompleteTemplateTarget!: HTMLTemplateElement;

  @CombineSubscriptions()
  private subscribers: Unsubscribable;

  private queryInputSubscription: Subscription;

  public connect() {
    this.showClearInputBtnOnInput(this.queryInputTarget.value);
  }

  public disconnect() {
    if (this.queryInputSubscription) {
      this.queryInputSubscription.unsubscribe();
    }
    if (this.subscribers) {
      this.subscribers.unsubscribe();
    }
  }

  public onQueryInputFocused() {
    // this.fillDefaultSuggestions();
    if (!this.queryInputSubscription || this.queryInputSubscription.closed) {
      this.setupSearchSubscription();
    }
  }

  public onQueryInputBlurred() {
    // this.emptyAutoCompleteResults();
    // this.hideAutoCompleteResults();
    if (this.queryInputSubscription) {
      this.queryInputSubscription.unsubscribe();
    }
  }

  public onAutoCompleteItemClick(targetOrEvent: PointerEvent | HTMLElement | null) {
    const resultNode = ((targetOrEvent as PointerEvent).currentTarget || targetOrEvent) as HTMLElement;
    if (resultNode) {
      this.selectAutoCompleteItem(resultNode);
      this.emptyAutoCompleteResults();
      this.hideAutoCompleteResults();
    }
  }

  public clearQueryInput() {
    this.hideAutoCompleteResults();
    this.setQueryInputValue('');
    this.clearInputBtnTarget.classList.add('d-none');
    this.focusQueryInput();
    this.fillDefaultSuggestions();
  }

  public focusQueryInput() {
    this.queryInputTarget.focus();
  }

  public blurQueryInput() {
    this.queryInputTarget.blur();
  }

  public setQueryInputValue(value?: string) {
    this.queryInputTarget.value = value;
    EventHelper.dispatch(this.queryInputTarget, 'change');
    this.showClearInputBtnOnInput(value);
  }

  public fillDefaultSuggestionsOnConnect() {
    const isQueryInputEmpty: boolean = this.queryInputTarget.value.length === 0;

    if (isQueryInputEmpty) {
      this.fillDefaultSuggestions();
    }
  }

  private setupSearchSubscription() {
    this.queryInputSubscription = fromEvent(this.queryInputTarget, 'keydown').pipe(
      filter(this.handleQueryInputKeyboardEvent.bind(this)),
      debounceTime(50),
      map(() => this.queryInputTarget.value.trim()),
      pipe(distinctUntilChanged()),
      tap(this.hideAutoCompleteResultsWhenInputBelowMinLength.bind(this)),
      tap(this.showClearInputBtnOnInput.bind(this)),
    ).subscribe(
      this.updateAutoCompleteResults.bind(this)
    )
  }

  private selectAutoCompleteItem(item: HTMLElement) {
    const query = item.dataset.value;
    this.setQueryInputValue(query);

    const faqId = item.dataset.faqId;
    if (faqId) {
      const faqElement = $('#faq' + faqId);
      if (faqElement.length > 0) {
        faqElement[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.animateElement(this, faqElement);

        const collapseElementId = faqElement.attr('data-target');
        if (collapseElementId) {
          $(collapseElementId).collapse('show');
          faqElement.removeClass('collapsed');
        }
        this.clearQueryInput();
      }
    }
  }

  private animateElement(context: any, element: any) {
    element[0].parentElement.classList.add('show');
    setTimeout(() => {
      context.removeShowClass(element);
    }, 1000);
  }

  private removeShowClass(element: any) {
    element[0].parentElement.classList.remove('show');
  }

  private handleQueryInputKeyboardEvent(event: KeyboardEvent): boolean {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
      event.preventDefault();
      this.moveAutoCompleteSelection(event.code === 'ArrowDown' ? 'down' : 'up')
      return false;
    } else if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (this.isAutoCompleteResultsVisible()) {
        const selectedItem =
          this.autoCompleteItemTargets.find(item => item.classList.contains('selected')) ||
          this.autoCompleteItemTargets.find(item => item.dataset.value === this.queryInputTarget.value) ||
          this.autoCompleteItemTargets[0];

        if (selectedItem) {
          event.preventDefault();
          this.selectAutoCompleteItem(selectedItem);
          this.hideAutoCompleteResults();
          return false;
        }
      }
    } else if (event.code === 'Escape') {
      event.preventDefault();
      this.hideAutoCompleteResults();
      this.clearQueryInput();
      this.blurQueryInput();
      return false;
    }
    return true;
  }

  private hideAutoCompleteResultsWhenInputBelowMinLength(input: string) {
    if (input.length < this.MIN_SEARCH_LENGTH) {
      this.emptyAutoCompleteResults();
      this.hideAutoCompleteResults();
    }
  }

  private showClearInputBtnOnInput(input: string) {
    if (input.length > 0) {
      this.clearInputBtnTarget.classList.remove('d-none');
    }
  }

  private emptyAutoCompleteResults() {
    this.autoCompleteResultsTarget.innerHTML = '';
  }

  private showAutoCompleteResults() {
    this.autoCompleteResultsTarget.classList.remove('d-none');
  }

  private hideAutoCompleteResults() {
    this.autoCompleteResultsTarget.classList.add('d-none');
  }

  private isAutoCompleteResultsVisible() {
    return !this.autoCompleteResultsTarget.classList.contains('d-none');
  }

  private async updateAutoCompleteResults(input: string) {
    if (input.length >= this.MIN_SEARCH_LENGTH) {
      const url = new URL(this.data.get('autocompleteUrl'), location.href);
      url.searchParams.append('q', input);
      url.searchParams.append('audience', this.data.get('audience'));
      url.searchParams.append('_ts', Date.now().toString());
      const result = await secureFetch(url.toString(), { cache: 'no-cache' });
      if (result.status === 200) {
        const list: AutoCompleteResult[] = await result.json();
        this.emptyAutoCompleteResults();
        list.forEach(item => this.addAutoCompleteItem(item));
        this.showAutoCompleteResults();
        if (list.length < 1) {
          // this.fillDefaultSuggestions();
        }
      } else {
        LogHelper.logError(`Request to ${url} returned status ${result.status}`);
      }
    } else {
      // this.fillDefaultSuggestions();
    }
  }

  private fillDefaultSuggestions() {
    const list: AutoCompleteResult[] = JSON.parse(this.data.get("dafaultSuggestions"));
    this.emptyAutoCompleteResults();
    list.forEach(item => this.addAutoCompleteItem(item));
    this.showAutoCompleteResults();
  }

  private addAutoCompleteItem(item: AutoCompleteResult) {
    const templateInstance = this.autoCompleteTemplateTarget.content.cloneNode(true) as HTMLElement;
    const nodeContainer = document.createElement("div");
    nodeContainer.appendChild(templateInstance);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{value}}/g, item.value);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{question}}/g, item.question);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{category}}/g, item.category);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{type}}/g, item.type);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{faqId}}/g, item.faqId);
    this.autoCompleteResultsTarget.appendChild(nodeContainer.firstChild);
  }

  private moveAutoCompleteSelection(upOrDown: 'up' | 'down') {
    let selectedItemIndex = this.autoCompleteItemTargets.findIndex(item => item.classList.contains('selected'));

    if (selectedItemIndex !== -1 || (selectedItemIndex === -1 && upOrDown === 'down')) {
      selectedItemIndex = upOrDown === 'up' ? selectedItemIndex - 1 : selectedItemIndex + 1;
      selectedItemIndex = Math.max(0, selectedItemIndex);
      selectedItemIndex = Math.min(selectedItemIndex, this.autoCompleteItemTargets.length - 1);
      this.autoCompleteItemTargets.forEach((item, index) => {
        if (index === selectedItemIndex) {
          item.classList.add('selected');
        } else {
          item.classList.remove('selected');
        }
      })
    }
  }
}

interface AutoCompleteResult {
  faqId: string;
  type: string;
  value: string;
  question: string;
  category: string;
}