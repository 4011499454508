import { Controller } from '@stimulus/core';
import { EventHelper } from '../../helpers/event-helper';

export default class SidebarController extends Controller {
  public clickStep(event: Event) {
    const element = event.currentTarget as HTMLElement;
    if (!element.classList.contains('disabled')) {
      const step = element.dataset.step;
      EventHelper.dispatch(this.element, 'sidebar-snd-submit', { toStep: step });
    }
  }
}
