import { Controller } from '@stimulus/core';
import * as Honeybadger from '@honeybadger-io/js';

export default class ImageController extends Controller {
  public connect() {
    if (this.imageLoaded) {
      this.handleBroken()
    }
  }

  public handleBroken() {
    if (this.imageBroken) {
      const missingSrc = this.element.src;
      const fallbackSrc = this.data.get('fallbacksrc');
      if (fallbackSrc) {
        this.data.delete('fallbacksrc');
        this.element.src = fallbackSrc;
        this.element.parentElement.querySelectorAll('source').forEach(e => e.remove());
        console.log("replaced image", this.element);
      }
      const path = this.path(6);
      const message = fallbackSrc ? `Using fallback for ${path}` : `No fallback for ${path}`;
      Honeybadger.notify({
        name: "ImageSourceNotFound",
        message: message,
        context: {
          src: missingSrc,
          hasFallback: !!fallbackSrc,
          path: this.path(6)
        }
      });
    }
  }

  public setLoadedClass() {
    this.element.classList.add('image-loaded');
  }

  get imageLoaded() {
    return this.element.complete && this.element.src;
  }

  get imageBroken() {
    return this.element.naturalHeight === 0 && this.element.src;
  }

  private path(depth) {
    try {
      let path = 'img';
      for (let i = 0, e = this.element.parentElement; i <= depth && e; i++, e = e.parentElement) {
        const tag = e.tagName === "DIV" ? '' : e.localName;
        path = `${tag}.${e.className} ${path}`;
      }
      return path;
    } catch {
      return "";
    }
  }
}
