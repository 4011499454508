import { BehaviorSubject } from 'rxjs';
import { Session } from '../models/session';
import { Service, Token } from 'typedi';

export const SessionToken = new Token<SessionSubject>();

class SessionFactory {
  create() {
    return new SessionSubject(null);
  }
}

@Service({
  id: SessionToken,
  factory: [SessionFactory, "create"]
})
export class SessionSubject extends BehaviorSubject<Session>  { }
