import { Controller } from '@stimulus/core';
import { ModalHelper } from '../helpers/modal-helper';
import { EventHelper } from '../helpers/event-helper';

export default class ItemPhotoController extends Controller {
  public async openCarousel(event: Event) {
    const { target } = event;
    const modal = await ModalHelper.openModalWithUrl(this.data.get('url'));
    const carouselEl = (modal && modal.get(0).querySelector('[data-controller=carousel]'));
    if (carouselEl) {
      modal.on('shown.bs.modal', () => {
        EventHelper.dispatch(carouselEl, 'carousel-rcv-init');
        if ((target as HTMLElement).hasAttribute('data-item-photo-index')) {
          EventHelper.dispatch(carouselEl, 'carousel-rcv-set-index', {
            index: parseInt((target as HTMLElement).getAttribute('data-item-photo-index'), 0)
          })
        }
      })
    }
    event.stopPropagation();
  }
}
