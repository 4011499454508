import { Controller } from '@stimulus/core';
import { EventHelper } from '../../helpers/event-helper';
import { ClipboardHelper } from '../../helpers/clipboard-helper';
import { delay } from 'rxjs/operators';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';
import { Unsubscribable, timer, of, empty } from 'rxjs';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class CalendarLinkController extends Controller {
  public static targets = ['importUrl', 'exportUrl', 'importButton', 'saveButton', 'copyToClipboard'];

  private readonly importUrlTarget: HTMLInputElement;
  private readonly exportUrlTarget: HTMLInputElement;
  private readonly copyToClipboardTarget!: HTMLElement;
  private readonly importButtonTarget: HTMLElement;
  private readonly saveButtonTarget: HTMLElement;

  @CombineSubscriptions()
  subscriber: Unsubscribable;

  public connect() {
    this.updateButtonState();
  }

  public updateButtonState() {
    const isFilled = !!this.importUrlTarget.value;

    this.importButtonTarget.classList.toggle('d-none', !isFilled);
    this.saveButtonTarget.classList.toggle('d-none', isFilled);
  }

  public sendCalendarRefresh() {
    EventHelper.dispatch(window, 'place-wizard--calendar-rcv-calendar-refresh');
  }

  public copyToClipboard(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (ClipboardHelper.copyInputValueToClipboard(this.exportUrlTarget)) {
      this.copyToClipboardTarget.classList.add('copied');
    } else {
      this.copyToClipboardTarget.classList.add('error');
    }
    this.subscriber = of(null).pipe(
      delay(5000)
    ).subscribe(() => this.copyToClipboardTarget.classList.remove('copied', 'error'));
  }
}
