import { Controller } from '@stimulus/core';

export default class ImageReorderingController extends Controller {
  public static targets = ['image', 'dropZone'];

  private readonly imageTargets!: HTMLElement[];
  private readonly dropZoneTarget!: HTMLDivElement;
  private draggedElement: HTMLDivElement = null;

  public onDragStart(event: DragEvent) {
    const target = (event.target as HTMLElement);
    if (this.imageTargets.includes(target)) {
      this.draggedElement = target as HTMLDivElement;
    }
  }

  public onDragEnter(event: DragEvent) {
    const dragTarget = this.detectDragTargetImage(event);
    if (dragTarget !== this.draggedElement) {
      event.preventDefault();
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      this.element.insertBefore(this.dropZoneTarget, dragTarget);
      this.dropZoneTarget.classList.remove("d-none");
      this.draggedElement.classList.add("d-none");
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  public onDrop(event: DragEvent) {
    if (this.draggedElement) {
      this.element.insertBefore(this.draggedElement, this.dropZoneTarget);
      event.preventDefault();
      console.debug('drop');
    }
  }

  public onDragEnd(event: DragEvent) {
    if (this.draggedElement) {
      this.dropZoneTarget.classList.add("d-none");
      this.draggedElement.classList.remove("d-none");
      event.preventDefault();
      console.debug('drag end');
    }
  }

  private detectDragTargetImage(event: DragEvent) {
    return this.imageTargets.find(image => {
      const bounds = image.getBoundingClientRect();
      return (bounds.left < event.clientX && bounds.right > event.clientX &&
        bounds.top < event.clientY && bounds.bottom > event.clientY)
    })
  }
}