import { Controller } from '@stimulus/core';

export default class OptionalListController extends Controller {
  public static targets = ['list'];

  private readonly listTarget: HTMLDivElement;

  public additionalItemSelected(event: Event) {
    const select = event.target as HTMLSelectElement;
    const selectedItem = select.options[select.selectedIndex];
    const item = this.listTarget.querySelector(`div[data-item-id="${selectedItem.value}"]`);
    
    this.listTarget.appendChild(this.listTarget.removeChild(item));
    const checkBox = item.querySelector('input[type=checkbox]') as HTMLInputElement;
    const focusInput = item.querySelector('input[data-focus-on-select=true]') as HTMLElement;

    item.classList.remove('d-none');

    checkBox.checked = true;
    focusInput?.focus();
    
    select.removeChild(selectedItem);
    if (select.children.length === 1) {
      select.classList.add('d-none');
    }
  }
}
