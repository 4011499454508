import { Controller } from '@stimulus/core';

export default class PopupMessageController extends Controller {
  public static targets = ['message'];

  private timerId: number;
  private delay: number;

  private readonly messageTarget: HTMLElement;
  private readonly DEFAULT_DELAY = 7000;

  public connect() {
    this.delay = this.data.has('delay') ? parseInt(this.data.get('delay'), 0) : this.DEFAULT_DELAY;
  }

  public disconnect() {
    this.clearTimer();
  }

  public show(event: CustomEvent) {
    const message = event.detail.message;
    this.messageTarget.innerHTML = message;
    this.element.classList.remove('show');
    this.element.classList.add('show');
    this.clearTimer();
    this.timerId = window.setTimeout(this.hide.bind(this), this.delay);
  }

  public hide() {
    this.element.classList.remove('show');
    this.clearTimer();
  }

  private clearTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }
}
