import { Application } from "stimulus";

export const runTurboLinksCleanUp = (application: Application) => {
  application.controllers.forEach(controller => {
    if (typeof (controller as any).onCleanUp === 'function') {
      (controller as any).onCleanUp.apply((controller as any));
    }
  });
}
export const addTurboLinksCleanUpEvent = (application: Application) => {
  document.addEventListener('turbolinks:before-cache', () => {
    runTurboLinksCleanUp(application);
  });

}

