import { Controller } from "@stimulus/core";

export default class AccordionController extends Controller {
  public static targets: string[] = [ 
    "mainImage"
  ];

  private mainImageTargets: HTMLImageElement[];

  public changeImage(event: MouseEvent): void {
    const grandParent = (event.currentTarget as HTMLElement).parentElement.parentElement;
    const index = Array.from(grandParent.parentElement.children).indexOf(grandParent);
    this.mainImageTargets.forEach(image => {
      image.classList.remove('show');
    });
    this.mainImageTargets[index].classList.add('show');
  }
}
  