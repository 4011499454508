import { Controller } from '@stimulus/core';

interface LoadMoreResponse {
  moreHTML: string;
  loadMoreButtonHTML?: string;
}

export default class LoadMoreController extends Controller {
  public static targets = ['container', 'loadMoreButton'];

  private readonly containerTarget: HTMLElement;
  private readonly loadMoreButtonTarget: HTMLElement;
  private readonly hasLoadMoreButtonTarget: boolean;

  public moreReceived(event: CustomEvent) {
    const [data] = event.detail;

    if (data.body) {
      this.loadMoreButtonTarget.remove();
      this.containerTarget.innerHTML += data.body.innerHTML;
      return;
    }

    if (data.moreHTML) {
      this.containerTarget.innerHTML += data.moreHTML;
    }

    if (data.loadMoreButtonHTML && this.hasLoadMoreButtonTarget) {
      this.loadMoreButtonTarget.innerHTML = data.loadMoreButtonHTML;
    }
  }
}
