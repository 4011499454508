import { Controller } from '@stimulus/core';
import { CookieHelper } from '../helpers/cookie-helper';

export default class PromoBarController extends Controller {
  public static PROMO_BAR_PATH = 'promo_bar';

  public connect() {
    const showPromoBar = CookieHelper.get(PromoBarController.PROMO_BAR_PATH) !== 'true';

    if (showPromoBar) {
      this.element.classList.add('show');
    }
  }

  public closePromoBar(event: PointerEvent) {
    this.element.classList.remove('show');
    this.saveCloseState();
    event.preventDefault();
    event.stopPropagation();
  }

  public goToURL() {
    location.href = this.data.get('url');
  }

  private saveCloseState() {
    CookieHelper.set(PromoBarController.PROMO_BAR_PATH, 'true', { expires: 14 });
  }
}
