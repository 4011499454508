
export class FormatHelper {

  public static prefixZero(digit: number | string): string {
    if (typeof digit === 'string') {
      digit = parseInt(digit, 0);
    }
    if (digit < 10) {
      return `0${digit}`;
    }
    return `${digit}`;
  }

  public static formatTime(time: number): string {
    const days = Math.floor(time / 86400);
    time %= 86400;
    const hours = Math.floor(time / 3600);
    time %= 3600;
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const dStr = days > 0 ? `${days}d ` : '';
    const hStr = hours > 0 ? `${days}h ` : '';
    const mStr = minutes > 0 ? `${minutes}m ` : '';
    const sStr = seconds > 0 ? `${FormatHelper.prefixZero(seconds)}s ` : '';
    return `${dStr}${hStr}${mStr}${sStr}`;
  }

  public static formatCurrency(amount: number, currency: string = 'EUR', showSymbol = false) {
    let options: Intl.NumberFormatOptions;
    if (showSymbol) {
      options = {
        style: 'currency',
        currency
      }
    } else {
      options = {
        maximumFractionDigits: 0
      }
    }
    return new Intl.NumberFormat(document.documentElement.getAttribute('lang'), options).format(amount);
  }

}