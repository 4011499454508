import { Controller } from '@stimulus/core';
import { EventHelper } from '../helpers/event-helper';
import { fromEvent, combineLatest, Unsubscribable } from 'rxjs';
import { startWith, debounceTime, timeout, delay } from 'rxjs/operators';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class FormTextInputController extends Controller {
  static targets = ['input', 'count', 'circle'];

  private readonly inputTarget!: HTMLInputElement;
  private readonly countTarget!: HTMLSpanElement;
  private readonly circleTarget!: SVGCircleElement;

  private initialStrokeColor: String;

  @CombineSubscriptions()
  subscriber: Unsubscribable;

  circleRadius: number;
  circumference: number;

  connect() {
    if (this.inputTarget.getAttribute("maxlength")) {
      this.circleRadius = parseInt(this.circleTarget.getAttribute('r'));
      this.circumference = 2 * Math.PI * this.circleRadius;
      this.attachLengthCheck();
    }
  }

  private attachLengthCheck() {
    this.circleTarget.style.strokeDasharray = `${this.circumference}`;
    this.initialStrokeColor = this.circleTarget.style.stroke;

    this.subscriber = combineLatest(
      fromEvent(this.inputTarget, 'change').pipe(startWith(null)),
      fromEvent(this.inputTarget, 'keydown').pipe(startWith(null)),
    ).pipe(
      debounceTime(10),
      delay(10)
    ).subscribe(() => {
      // Allow the value to be set to the input first, before calculating, hence the delay
      this.calculateFieldLength();
    });

  }

  private calculateFieldLength() {
    const maxLength = parseInt(this.inputTarget.getAttribute("maxlength"));
    const textLength = this.inputTarget.value.length;
    this.countTarget.innerHTML = `${maxLength - textLength}`;
    this.calculateProgress(textLength / maxLength);
  }

  private calculateProgress(percentage: number) {
    var dashoffset = this.circumference * (1 - percentage);
    this.circleTarget.style.strokeDashoffset = `${dashoffset}`;
    this.circleTarget.style.stroke = percentage > 1 ? 'red' : this.initialStrokeColor;
  }

}
