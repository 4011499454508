import { Controller } from '@stimulus/core';
import { ModalHelper } from '../../helpers/modal-helper';

export default class RoomConfigController extends Controller {
  public static targets = ['template', 'list', 'modal', 'modalTitle', 'modalOptionList', 'modalOption', 'roomsJson',
                           'roomTemplate', 'roomOptionSummaryTemplate', 'roomOptionTemplate'];

  public addRoom(event: Event) {
    const select = event.currentTarget as HTMLSelectElement;
    const option = select.options[select.selectedIndex];
    ModalHelper.openModalWithUrl(option.dataset.url);
    select.selectedIndex = 0;
  }
}
