import * as $ from 'jquery';
import { Controller } from '@stimulus/core';
import { CookieHelper } from '../helpers/cookie-helper';
import { combineLatest, fromEvent, Unsubscribable } from 'rxjs';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';
import { filter, debounceTime } from 'rxjs/operators';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class NewsletterPopupController extends Controller {
  public static DISMISSED_COOKIE_NAME = 'nwsltr_dismissed';
  public static SHOW_AT_SCROLL_PERCENTAGE = 35;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    const hasDismissedCookie = CookieHelper.get(NewsletterPopupController.DISMISSED_COOKIE_NAME) === 'true';
    const hasConsentMode = localStorage.getItem('consentMode') != null
    const positionToShow = (document.body.scrollHeight - window.innerHeight) * (NewsletterPopupController.SHOW_AT_SCROLL_PERCENTAGE / 100);
    if (!hasDismissedCookie && hasConsentMode) {
      this.subscriber = combineLatest(
        fromEvent(window, 'scroll'),
      ).pipe(
        filter(() => !this.element.classList.contains('show')),
        filter(() => !hasDismissedCookie),
        filter(() => document.body.dataset.signedIn !== 'true'),
        debounceTime(100),
      ).subscribe(() => {
        if (window.scrollY > positionToShow) {
          this.element.classList.add('show');
        }
      });
    }
  }
  public dismissed() {
    CookieHelper.set(NewsletterPopupController.DISMISSED_COOKIE_NAME, 'true', {
      expires: 1
    });
  }

  public subscribed() {
    CookieHelper.set(NewsletterPopupController.DISMISSED_COOKIE_NAME, 'true', {
      expires: 1024
    });
    $(this.element).alert('close');
  }
}
