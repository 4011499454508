import { Controller } from '@stimulus/core';
import Container from 'typedi';
import { secureFetch } from "../auth/secure_fetch_function";
import { CookieHelper } from '../helpers/cookie-helper';
import { EventHelper } from '../helpers/event-helper';
import { SessionToken } from '../subjects/session_subject';
import LikeController from './like_controller';

export default class FavoritesListController extends Controller {
  public static targets = ['header', 'counter', 'item'];

  private readonly headerTarget: HTMLElement;
  private readonly counterTarget: HTMLElement;
  private readonly itemTargets: HTMLElement[];

  public connect() {
    this.updateHeader();
    const session = Container.get(SessionToken).getValue();
    const cookieLikes = CookieHelper.getSerialized(LikeController.LIKE_COOKIE_PATH);
    if (session && session.user && (
      cookieLikes.some(v => session.user.likes.indexOf(v) === -1) || session.user.likes.some(v => cookieLikes.indexOf(v) === -1)
    )) {
      //window.location.reload();
    }
  }

  public async remove(clickEvent: Event) {
    clickEvent.stopPropagation();
    const favorite = clickEvent.currentTarget as HTMLElement;

    const url = favorite.dataset.deleteUrl;
    const result = await secureFetch(url, { method: 'DELETE' });

    if (result.status === 204) {
      const containerSelector = favorite.dataset.container;
      const container = favorite.closest(containerSelector);
      container.remove();

      this.updateHeader();
    }
  }

  private updateHeader() {
    const numItems = this.itemTargets.length;
    this.counterTarget.innerHTML = `(${numItems})`;

    const isEmpty = numItems === 0;
    this.headerTarget.classList.toggle('d-none', isEmpty);
    (this.element as HTMLElement).dataset.isEmpty = isEmpty.toString();
    EventHelper.dispatch(this.element, 'favorites-list-updated');
  }
}
