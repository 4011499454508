import { Controller } from "stimulus"

export default class ScrollToFaqController extends Controller {
    public static targets = ["select"];

    private readonly selectTarget!: HTMLSelectElement;

    public connect() {
        this.selectTarget.addEventListener('change', this.scrollToFaq.bind(this))
    }

    public disconnect() {
        this.selectTarget.removeEventListener('change', this.scrollToFaq.bind(this))
    }

    public scrollToFaq(event) {
        const selectedFaq = event.target.value
        location.href = `#${selectedFaq}`
    }
}