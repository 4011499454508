import ModalFormController from '../modal_form_controller';
import { EventHelper } from '../../helpers/event-helper';

export default class extends ModalFormController {
  public static targets = ['form', 'toStep', 'backButton', 'backInput', 'chainedSelect'];

  private readonly formTarget: HTMLFormElement;
  private readonly toStepTarget: HTMLInputElement;
  private readonly backButtonTarget: HTMLButtonElement;
  private readonly backInputTarget: HTMLInputElement;
  private readonly chainedSelectTargets!: HTMLSelectElement[];

  public navigate(event: CustomEvent) {
    const step = event.detail.toStep;
    this.toStepTarget.value = step;
    this.toStepTarget.disabled = false;
    EventHelper.dispatch(this.formTarget, 'submit');
  }

  public toggleInputsDisabled(event: Event) {
    const checkBox = event.currentTarget as HTMLInputElement;
    const targetSections = checkBox.dataset.disableSections.split(' ').map(id => document.getElementById(id));
    const inputsPerSection = targetSections.map(section => (
      [...Array.from(section.getElementsByTagName('input')) as Array<HTMLInputElement | HTMLTextAreaElement>,
      ...Array.from(section.getElementsByTagName('textarea')) as Array<HTMLInputElement | HTMLTextAreaElement>]
    ));
    const inputs = inputsPerSection.reduce((array, val) => array.concat(val, []));
    inputs.forEach(input => {
      checkBox.checked ? input.setAttribute('disabled', '') : input.removeAttribute('disabled')
    });
  }

  public checkChainedSelects(event: Event) {
    const currentSelect = event.currentTarget as HTMLSelectElement;
    const chainedSelects = this.chainedSelectTargets.filter(s => s.getAttribute('data-chain-type') === currentSelect.getAttribute('data-chain-type'));
    chainedSelects.forEach((select, index) => {
      if (index > 0) {
        const prevSelects = chainedSelects.slice(0, index);

        select.querySelectorAll(`option`).forEach((option: HTMLOptionElement) => option.removeAttribute('disabled'));
        if (prevSelects[prevSelects.length - 1].value) {
          select.removeAttribute('disabled');
          prevSelects.forEach(prevSelect => {
            select.querySelectorAll(`option[value='${prevSelect.value}']`).forEach((option: HTMLOptionElement) => {
              option.setAttribute('disabled', 'disabled');
            })
            if (select.value === prevSelect.value) {
              select.value = "";
            }
          })
        } else {
          select.value = "";
          select.setAttribute('disabled', 'disabled');
        }
      }
    })
  }

  public goToPreviousStep() {
    this.backInputTarget.removeAttribute('disabled');
    super.onSubmit();
    this.formTarget.submit();
  }

  public disableEnter(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
  }
}
