import { Controller } from '@stimulus/core';
import { FormValidationHelper } from '../helpers/form-validation-helper';
import { ServerResponseHelper } from '../helpers/server-response-helper';
import { ModalHelper } from '../helpers/modal-helper';

export default class FormController extends Controller {
  protected formValidator: FormValidationHelper;

  public connect() {
    this.formValidator = new FormValidationHelper();
    this.formValidator.attachFormValidation(this.element);
  }

  public disconnect() {
    this.formValidator.cleanUp();
  }

  public onAjaxSuccessHtml(event: CustomEvent) {
    this.formValidator.enableAllInputFields();
    const [data] = event.detail;
    ModalHelper.openModalWithContents(data);
  }

  public onAjaxSuccessJson(event: CustomEvent) {
    const [data] = event.detail;

    if (data.redirect) {
      window.location = data.redirect.url
    } else {
      this.formValidator.enableAllInputFields();
    }
  }

  public onAjaxError(event: CustomEvent) {
    const [data] = event.detail;
    if (!ServerResponseHelper.handleServerError(event)) {
      this.formValidator.setErrorsFromServerResponse(data.errors);
    }
  }

  public onSubmit() {
    this.formValidator.hideFormErrors();
    this.formValidator.disableAllInputFields();
  }
}
