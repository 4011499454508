export class DateHelper {
  public static isoDatePattern = /^(\d{4})-(\d{2})-(\d{2})$/;

  public static isoDateString(date: Date): string | null {
    if (date === null) {
      return null;
    }
    return date.toISOString().split('T')[0];
  }

  public static startOfMonth(date: Date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
  }

  public static isSameDay(date1: Date, date2: Date) {
    return date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date1.getUTCMonth() === date2.getUTCMonth() &&
      date1.getUTCDate() === date2.getUTCDate();
  }

  public static parseISODateUTC(isoDateString?: string) {
    if (!isoDateString) {
      return null
    }
    const [, year, month, day] = this.isoDatePattern.exec(isoDateString);
    if (year && month && day) {
      return new Date(Date.UTC(parseInt(year, 0), parseInt(month, 0) - 1, parseInt(day, 0)));
    } else {
      return null;
    }
  }

  public static addDays(date: Date, days: number) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + days));
  }

  public static daysDiff(date1: Date, date2: Date) {
    return Math.ceil((date2.valueOf() - date1.valueOf()) / 1000 / 60 / 60 / 24);
  }

  // TODO: Refactor this method, clean up formats and locale
  public static humanFormat(date: Date, format: ('shortest' | 'short' | 'medium'), locale = null) {
    if (date === null) {
      return '';
    }
    if (!locale) {
      locale = document.body.dataset.language || 'nl';
    }
    if (format === 'shortest') {
      return date.toLocaleString(locale, { day: 'numeric', month: 'short', timeZone: 'UTC' });
    }
    else if (format === 'short') {
      return date.toLocaleString(locale, { weekday: 'short', day: 'numeric', month: 'short', timeZone: 'UTC' });
    }
    else {
      const dayMonth = date.toLocaleString(locale, { day: 'numeric', month: 'short', timeZone: 'UTC' });
      const year = date.toLocaleString(locale, { year: '2-digit', timeZone: 'UTC' });
      return `${dayMonth} '${year}`;
    }
  }
}
