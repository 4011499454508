import { Controller } from '@stimulus/core';
import Choices from 'choices.js';

export default class SearchableSelectController extends Controller {
  private choices: Choices;
  private urlPlaceholder: string;
  private urlWithPlaceholder: string;

  public connect() {
    const containerOuterClassName = this.data.has('outerClass') ? `choices ${this.data.get('outerClass')}` : 'choices';
    this.choices = new Choices(
      this.element as HTMLInputElement,
      {
        itemSelectText: '',
        searchPlaceholderValue: this.data.get('searchPlaceholder'),
        shouldSort: false,
        searchResultLimit: 100,
        fuseOptions: {
          threshold: 0.2,
          distance: 1000
        },
        classNames: {
          containerOuter: containerOuterClassName
        }
      });
    this.urlWithPlaceholder = this.data.get('url');
    this.urlPlaceholder = this.data.get('urlPlaceholder');
  }

  public changed(event: CustomEvent) {
    if (this.urlPlaceholder) {
      window.location.href = this.urlWithPlaceholder.replace(this.urlPlaceholder, event.detail.value);
    }
  }
}
