import * as $ from 'jquery';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CombineSubscriptions, DestroySubscribers } from 'ngx-destroy-subscribers';
import { BehaviorSubject, Unsubscribable, pipe, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, tap, throttleTime, filter } from 'rxjs/operators';
import { Controller } from '@stimulus/core';

import { EventHelper } from '../helpers/event-helper';
import { HistoryHelper } from '../helpers/history-helper';
import { ListView } from '../models/filtered_list/list_view';
import { MapView } from '../models/filtered_list/map_view';
import { SearchTerm } from "../models/search_term";
import SearchBarSearchTermUpdatedEvent from "../models/custom_event/search_bar_search_term_updated_event";
import SearchBarPeriodUpdatedEvent from '../models/custom_event/search_bar_period_updated_event';
import SearchBarGroupSizeUpdatedEvent from '../models/custom_event/search_bar_group_size_updated_event';
import FilteredListFilterRemovedEvent from '../models/custom_event/filtered_list_filter_removed_event';

export interface EnabledFacets {
  [filterKey: string]: string[]
}
export type ProcessEnabledFacetsCallback = (element: HTMLElement) => void;

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class FilteredListController extends Controller {
  public static targets = [
    'filter', 'searchTermFilter',
    'list', 'map', 'filterSummaryItemTemplate', 'clearFilters', 'sortButton',
    'selectedFilters', 'selectedFiltersCount', 'periodDisplay',
    'loadMoreButton', 'filterBar'
  ];

  private applyFilterSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private readonly filterTargets!: HTMLElement[];
  private readonly searchTermFilterTarget: HTMLElement;
  private readonly listTarget!: HTMLElement;
  private readonly mapTarget?: HTMLElement;
  private readonly clearFiltersTargets!: Element[];
  private readonly filterSummaryItemTemplateTarget!: HTMLTemplateElement;
  private readonly selectedFiltersTargets!: HTMLDivElement[];
  private readonly selectedFiltersCountTarget!: HTMLDivElement;
  private readonly sortButtonTarget!: HTMLLinkElement;
  private readonly loadMoreButtonTargets: HTMLAnchorElement[];
  private readonly filterBarTarget: HTMLElement;

  private listView: ListView;
  private mapView: MapView;
  private activeView: ListView | MapView = null;

  private lastFilterKey: string = null;
  private urlQueryParams: object = null;
  private searchTerm: SearchTerm = { query: null, filterKey: null, filterValue: null };

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.searchTerm = {
      query: this.data.get('searchTermQuery'),
      filterKey: this.data.get('searchTermFilterKey'),
      filterValue: this.data.get('searchTermFilterValue')
    };
    this.storeForwardedURLQueryParams();
    this.setupApplyFilterSubscriber();
    this.createViews();
    this.activateView(this.getViewFromLocation());
  }

  public clearFilters() {
    this.clearAllFiltersExcept([]);
  }

  public async loadMore(event: MouseEvent) {
    this.listView.loadMoreClicked(event);
  }

  public selectChanged(event: Event) {
    event.preventDefault();
    const select = event.currentTarget as HTMLSelectElement;
    Array.from(select.options).forEach((option, i) => {
      option.classList.toggle('active', i === select.selectedIndex);
    })
    this.applyFilterSubject.next(select.dataset.filterKey);
  }

  public toggleFilterOption(event: Event) {
    event.preventDefault();
    const option = event.currentTarget as HTMLElement;
    this.toggleOption(option);
  }

  public removeFilterOption(event: MouseEvent) {
    event.stopPropagation();
    const target = event.currentTarget as HTMLElement;
    const filterSummaryItem: HTMLElement = target.closest('.item') as HTMLElement;
    if (filterSummaryItem === null) { alert("not found") };
    this.removeSelectedFilter(filterSummaryItem.dataset.filterKey, filterSummaryItem.dataset.filterValue);
  }

  public updateSorting(event: MouseEvent) {
    const clickElement = event.currentTarget as HTMLLinkElement;
    this.sortButtonTarget.innerHTML = clickElement.innerHTML;
    this.sortButtonTarget.setAttribute('data-order', clickElement.getAttribute('data-order'));
    this.applyFilterSubject.next(null);
  }

  public onSearchBarSearchTermUpdated(event: SearchBarSearchTermUpdatedEvent) {
    const oldSearchTerm = this.searchTerm;
    const newSearchTerm = event.detail.searchTerm;
    if (oldSearchTerm.filterKey) {
      this.switchFilterOption(oldSearchTerm.filterKey, oldSearchTerm.filterValue, false);
    }
    if (newSearchTerm.filterKey) {
      this.clearAllFiltersExcept([newSearchTerm.filterKey, ...event.detail.retainFilterKeys]);
      this.switchFilterOption(newSearchTerm.filterKey, newSearchTerm.filterValue, true, newSearchTerm.query);
    }
    this.searchTerm = newSearchTerm;
    this.urlQueryParams = {
      'f': newSearchTerm.query,
      'fk': newSearchTerm.filterKey,
      'fv': newSearchTerm.filterValue
    };
    this.applyFilterSubject.next(null);
  }

  public onSearchBarPeriodUpdated(event: SearchBarPeriodUpdatedEvent) {
    this.switchFilterOption(event.detail.filterKey, event.detail.filterValue, !!event.detail.filterValue, event.detail.filterLabel)
    this.applyFilterSubject.next(null);
  }

  public onSearchBarGroupSizeUpdated(event: SearchBarGroupSizeUpdatedEvent) {
    this.switchFilterOption(event.detail.filterKey, event.detail.filterValue, !!event.detail.filterValue, event.detail.filterLabel)
    this.applyFilterSubject.next(null);
  }

  public onSearchBarSubmitSearch() {
    this.applyFilterSubject.next(null);
    this.listView?.refreshList(() => this.dispatchLoadingFinishedEvent());
    this.mapView?.refreshMap(() => this.dispatchLoadingFinishedEvent());
  }

  public toggleFilterBar() {
    const visible = this.filterBarTarget.classList.toggle('show');
    window.document.body.classList.toggle('noscroll-sm', visible);
  }

  public toggleShowingMore(event: MouseEvent) {
    const el = event.currentTarget as HTMLElement;
    (el.parentNode as HTMLElement).classList.toggle('showing-more');
  }

  public toggleMapView() {
    if (this.activeView === this.mapView) {
      this.activateView(this.listView);
      EventHelper.dispatch(window, 'filtered-list-snd-switch-to-list-view');
    } else if (this.mapView) {
      this.activateView(this.mapView);
      EventHelper.dispatch(window, 'filtered-list-snd-switch-to-map-view');
    }
  }

  private switchFilterOption(filterKey: string, filterValue: string, selected: boolean, title?: string) {
    const filter = this.filterTargets.find(f => f.dataset.filterKey === filterKey);
    if (!filter) {
      console.error("No filter found with key", filterKey, this.filterTargets.map(f => f.dataset.filterKey))
    }
    const options = Array.from(filter.getElementsByClassName('option')) as HTMLElement[];
    if (filter.dataset.type === 'select') {
      this.clearSelectedOption(filter);
    }
    if (filter.dataset.type === 'facets' || filter.dataset.type === 'select') {
      const option = options.find(option => option.dataset.filterValue === filterValue);
      if (option) {
        option.classList.toggle('active', selected);
      } else {
        console.error("No facet found with value", filterValue, options.map(o => o.dataset.filterValue))
      }
    } else if (filter.dataset.type === 'term') {
      const option = options[0];
      option.dataset.filterValue = selected ? filterValue : null;
      option.innerHTML = title;
      option.classList.toggle('active', selected);
    }
  }

  private clearAllFiltersExcept(filterKeys: string[]) {
    this.selectedFiltersTargets.forEach(target =>
      Array.from(target.getElementsByClassName('item')).forEach(item => {
        const { filterKey, filterValue } = (item as HTMLElement).dataset;
        if (!filterKeys.includes(filterKey)) {
          this.removeSelectedFilter(filterKey, filterValue);
        }
      })
    );
  }

  private removeSelectedFilter(filterKey: string, filterValue: string) {
    this.dispatchFilterRemovedEvent(filterKey);

    const filter = this.filterTargets.find(f => f.dataset.filterKey === filterKey);
    if (!filter) {
      console.error("No filter found", filterKey, this.filterTargets.map(f => f.dataset.filterKey));
      return;
    }
    const select = filter.querySelector("select");
    if (select) {
      select.selectedIndex = 0;
      select.dispatchEvent(new Event('change'))
    } else {
      const activeOptions: HTMLElement[] = Array.from(filter.getElementsByClassName('option active') as HTMLCollectionOf<HTMLElement>);
      const option: HTMLElement = activeOptions.find(opt => opt.dataset.filterValue === filterValue);
      if (option) {
        this.toggleOption(option);
      } else {
        console.error("No option found with value", filterValue, activeOptions.map(o => o.dataset.filterValue));
      }
    }
  }

  private dispatchFilterRemovedEvent(filterKey: string) {
    this.element.dispatchEvent(
      new FilteredListFilterRemovedEvent({ filterKey })
    )
  }

  private activateView(view: ListView | MapView) {
    this.activeView = view;
    const isMapView = (view === this.mapView);
    this.element.classList.toggle('map', isMapView);
    document.body.classList.toggle('map-fullscreen', isMapView);
    this.updateWindowLocationHash();
    this.mapView.setActive(isMapView);
    this.listView.setActive(!isMapView);
  }

  private setupApplyFilterSubscriber() {
    this.subscriber = this.applyFilterSubject.asObservable().pipe(
      tap(filterKey => {
        if (filterKey) {
          this.lastFilterKey = filterKey
        }
      }),
      map(() => this.calculateSearchPath()),
      pipe(distinctUntilChanged()),
      skip(1),
      tap(() => this.generateFilterSummary()),
      debounceTime(500),
    ).subscribe(async (path) => {
      this.pushPathToHistory(path);
      this.sendLandingPageImageUpdateEvent(path);
      await this.applyFiltersToList(path);
    })
  }

  private createViews() {
    const searchPath = this.calculateSearchPath();
    this.listView = new ListView({
      listTarget: this.listTarget,
      filteredListTarget: (this.element as HTMLElement),
      loadMoreButton: this.loadMoreButtonTargets[0],
      basePath: this.basePath,
      filterPath: searchPath,
      active: true,
      processEnabledFacetsCallback: this.processEnabledFacets.bind(this)
    });
    this.mapView = new MapView({
      mapTarget: this.mapTarget,
      basePath: this.basePath,
      filterPath: searchPath,
      processEnabledFacetsCallback: this.processEnabledFacets.bind(this),
      selectListingId: this.selectListingId
    })
  }

  private async applyFiltersToList(filterPath: string) {
    this.listView.filterPathChanged(filterPath, () => this.dispatchLoadingFinishedEvent());
    this.mapView?.filterPathChanged(filterPath, () => this.dispatchLoadingFinishedEvent());
  }

  private dispatchLoadingFinishedEvent() {
    EventHelper.dispatch(window, 'filtered-list-snd-loading-finished');
  }

  private processEnabledFacets(enabledFacets: EnabledFacets) {
    for (const [filterKey, facetIds] of Object.entries(enabledFacets)) {
      const filter = this.filterTargets.find(f => f.dataset.filterKey === filterKey) as HTMLElement;
      const options = Array.from(filter.getElementsByClassName('option')) as HTMLElement[];
      options.forEach(option => {
        const isEnabled = facetIds.indexOf(option.dataset.facetId) > -1;
        option.classList.toggle('disabled', !isEnabled);
        (option.parentNode as HTMLElement).classList.toggle('disabled', !isEnabled);
      })
    };
    this.updateFilterVisibility();
    this.repartitionOptions();
  }

  private updateFilterVisibility() {
    this.filterTargets
      .filter(filterTarget => !('invisible' in filterTarget.dataset))
      .forEach(filterTarget => {
        const hasAllOptionsDisabled = Array.from(filterTarget.getElementsByClassName('option'))
          .every(e => e.classList.contains('disabled'));
        filterTarget.classList.toggle('d-none', hasAllOptionsDisabled);
      });
  }

  private repartitionOptions() {
    this.filterTargets
      .filter(filterTarget => !filterTarget.classList.contains('d-none'))
      .map(filterTarget => filterTarget.getElementsByClassName('filter-body')[0] as HTMLElement)
      .filter(filterBody => filterBody)
      .forEach(filterBody => {
        const numVisible = parseInt(filterBody.dataset.numVisible, 0);
        const optionsDiv: HTMLDivElement = filterBody.querySelector('.options');
        if (optionsDiv) {
          const options: HTMLElement[] = Array.from(optionsDiv.querySelectorAll<HTMLElement>('.option-wrapper'));

          const sorted = this.stableSortBy(options, (o) => (o.classList.contains('disabled') ? 1 : 0));
          sorted.forEach((option, index) => {
            optionsDiv.appendChild(option);
            option.toggleAttribute('data-max-visible', index === (numVisible - 1))
          });
          const showLessLabel = filterBody.querySelector('.show-less-label');
          if (showLessLabel) {
            const lastOption = optionsDiv.lastChild as HTMLElement;
            showLessLabel.classList.toggle('d-none', lastOption.classList.contains('active'));
          }
        }
      })
  }

  private stableSortBy(arr, sort) {
    return arr
      .map((item, index) => ({ item, index }))
      .sort((a, b) => (sort(a.item) - sort(b.item)) * arr.length + (a.index - b.index))
      .map(({ item }) => item)
  }

  private toggleOption(option: HTMLElement) {
    option.classList.toggle('active');
    (option.parentNode as HTMLElement).classList.toggle('active', option.classList.contains('active'));
    this.applyFilterSubject.next(option.dataset.filterKey);
  }

  private createLandingPageImagePath(path: string) {
    return `${this.data.get('landing-page-url')}/${path}`;
  }

  private pushPathToHistory(path: string) {
    const fullPath = `${this.basePath}/${path}${this.viewLocationHash}`;
    HistoryHelper.pushHistory(fullPath);
  }

  private sendLandingPageImageUpdateEvent(path: string) {
    EventHelper.dispatch(window, 'filtered-list-list-updated', { landingPageImageUrl: this.createLandingPageImagePath(path) });
  }

  private calculateSearchPath() {
    const queryOptions = {
      q: this.searchTermFilterValue,
      'order': this.sortButtonTarget.getAttribute('data-order'),
      'l': this.lastFilterKey,
      ...this.urlQueryParams
    }
    const queryString =
      Object
        .keys(queryOptions)
        .filter(param => queryOptions[param] && queryOptions[param].trim().length > 0)
        .map(param => `${param}=${encodeURIComponent(queryOptions[param])}`)
        .join('&');

    const facetFilterPaths =
      this.filterTargets
        .filter(filter => filter !== this.searchTermFilterTarget)
        .filter(filter => (this.getSelectedOptions(filter).length > 0))
        .map(filter => [filter.dataset.filterKey, this.getSelectedOptions(filter).map(o => o.dataset.filterValue)])
        .map(([key, ...filterValues]) => `${key}:${filterValues.join(',')}`);

    const path =
      facetFilterPaths
        .filter(value => (value !== null && value.trim() !== ''))
        .join('/')
        .concat(`?${queryString}`);
    return path;
  }

  private generateFilterSummary() {
    const items: HTMLElement[] = [];
    this.filterTargets.forEach(filterTarget => {
      const selectedOptions = this.getSelectedOptions(filterTarget);
      items.push(...selectedOptions.map(
        option => this.createFilterSummaryItem({
          filterKey: filterTarget.dataset.filterKey,
          title: option.innerHTML,
          filterValue: option.dataset.filterValue
        }))
      );
    });
    this.selectedFiltersTargets.forEach(target => {
      target.innerHTML = '';
      items.forEach(item => { target.appendChild(item.cloneNode(true)) });
    });
    this.selectedFiltersCountTarget.innerHTML = `${items.length}`;
    this.selectedFiltersCountTarget.classList.toggle('d-none', items.length === 0);
    this.clearFiltersTargets.forEach(e => e.classList.toggle('d-none', items.length === 0));
  }

  private getSelectedOptions(filterTarget: HTMLElement): HTMLElement[] {
    return Array.from(
      filterTarget.getElementsByClassName('option active') as HTMLCollectionOf<HTMLElement>
    ).filter(e => e.dataset.filterValueNone !== 'true');
  }

  private clearSelectedOption(filter: HTMLElement) {
    const activeOption = this.getSelectedOptions(filter)[0]
    if (activeOption) {
      activeOption.classList.toggle('active');
    }
  }

  private createFilterSummaryItem(params: { filterKey: string, title: string, filterValue?: string }) {
    const clone = document.importNode(this.filterSummaryItemTemplateTarget.content, true);
    const item = clone.children[0] as HTMLDivElement;
    item.firstChild.textContent = params.title;
    item.dataset.filterTitle = params.title;
    item.dataset.filterKey = params.filterKey;
    item.dataset.filterValue = params.filterValue;
    return item;
  }

  private storeForwardedURLQueryParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.lastFilterKey = urlSearchParams.get('l');
    this.urlQueryParams = {
      'f': urlSearchParams.get('f'),
      'fk': urlSearchParams.get('fk'),
      'fv': urlSearchParams.get('fv'),
    };
  }

  private updateWindowLocationHash() {
    window.location.hash = this.viewLocationHash;
  }

  private getViewFromLocation(): ListView | MapView {
    return window.location.hash === '#mapview' ? this.mapView : this.listView;
  }

  private get basePath() { return this.data.get('basePath') }

  private get viewLocationHash() { return (this.activeView === this.mapView) ? '#mapview' : '' }

  private get selectListingId() {
    const listingId = this.data.get('selectListingId');
    return listingId ? parseInt(listingId) : null;
  }

  private get searchTermFilterValue(): string | null {
    const option = this.getSelectedOptions(this.searchTermFilterTarget)[0];
    return option ? option.dataset.filterValue : null;
  }
}
