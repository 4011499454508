import Cookies from 'js-cookie';
import { LogHelper } from '../helpers/log-helper';

export class CookieHelper {
  public static set(name: string, value: any | any[], options = {
    expires: 365
  }) {
    Cookies.set(name, value, options);
  }

  public static get(name: string) {
    return Cookies.get(name);
  }

  public static setSerialized(name: string, value: any | any[], options = { expires: 365 }) {
    CookieHelper.set(name, JSON.stringify(value), options);
  }

  public static getSerialized(name: string) {
    try {
      const cookie = Cookies.get(name);
      return cookie ? JSON.parse(cookie) : null;
    } catch (e) {
      LogHelper.logError(e);
      return null;
    }
  }
}
