import * as $ from 'jquery';
import { ModalType } from "../models/modal_type";
import { HistoryHelper } from './history-helper';
import { EventHelper } from './event-helper';
import { secureFetch } from '../auth/secure_fetch_function';
import { ModalOption } from 'bootstrap';
import { ResponsiveHelper } from "./responsive-helper";

export class ModalHelper {
  public static async openModalWithUrl(url: string, option?: ModalOption): Promise<JQuery<HTMLElement>> {
    if (!this.isLocalUri(url)) {
      return;
    }
    const response = await secureFetch(url);
    if (response.status === 200) {
      const contents = await response.text();
      const modal = await this.openModalWithContents(contents, null, option);
      modal.on('shown.bs.modal', () => EventHelper.dispatch(window, 'modal-snd-shown'));
      return modal;
    } else {
      throw new Error(`Error ${response.status} opening modal from "${url}"`);
    }
  }

  public static openModalWithContents(stringOrDocument: string | Document, type?: ModalType, option?: ModalOption): Promise<JQuery<HTMLElement>> {
    const html = this.convertToHTML(stringOrDocument);
    type = type || this.detectModalType(html);
    const root = this.findOrCreateModalRootElement(type);
    const container = root.getElementsByClassName('container')[0] as HTMLElement;
    let $modal = $(root).find('.modal');
    if ($modal.length > 0) {
      const existingModalVisible = $modal.get(0).classList.contains('show');
      if (existingModalVisible && type !== ModalType.FULLSCREEN) {
        return new Promise((resolve) => {
          $modal.modal('hide');
          $modal.on('hidden.bs.modal', async () => {
            $modal.remove();
            resolve(await this.openModalWithContents(stringOrDocument, type, option));
          })
        });        
      } else {
        $modal.remove();
      }
    }
    container.innerHTML = html;
    $(container).find('[data-toggle="tooltip"]').tooltip();
    $modal = $(root).find('.modal');      
    $modal.modal(option);
    return Promise.resolve($modal);
  }

  public static openModalWithId(modalId: string): JQuery<HTMLElement> {
    const $modal = $(document.getElementById(modalId));

    $modal.modal({
      backdrop: ResponsiveHelper.isBreakpointAtLeast('md')
    });

    document.querySelectorAll('.modal.show').forEach(modalElement => {
      if (modalElement.id !== modalId) {
        $(modalElement).modal('hide');
      }
    });

    return $modal;
  }

  public static closeModalWithId(modalId: string) {
    const modal = $(document.getElementById(modalId));
    $(modal).modal('hide');
  }

  public static closeModal(type: ModalType) {
    $(`#modal-${type}`).find('.modal').modal('hide');
  }

  public static checkModalURL() {
    const modalURLParams = new URLSearchParams(window.location.search);
    if (modalURLParams.has('modal_url')) {
      this.openModalWithUrl(modalURLParams.get('modal_url'));
      modalURLParams.delete('modal_url');
      const qs = modalURLParams.toString().length > 0 ? `?${modalURLParams.toString()}` : '';
      HistoryHelper.replaceHistory(`${location.origin}${location.pathname}${qs}`);
    }
  }

  private static detectModalType(body: string): ModalType {
    if ($(body).find('.modal-dialog.registration').length) {
      return ModalType.FULLSCREEN;
    } else if ($(body).find('.modal-dialog.prompt').length) {
      return ModalType.PROMPT;
    }
    return ModalType.ALERT;
  }

  private static findOrCreateModalRootElement(type: ModalType): HTMLElement {
    let modalRoot = document.getElementById(`modal-${type}`);
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.id = `modal-${type}`;
      const container = document.createElement('div');
      container.className = 'container';
      modalRoot.appendChild(container);
      document.body.appendChild(modalRoot);

    }
    return modalRoot;
  }

  private static convertToHTML(contents: string | Document): string {
    if (typeof contents === 'object' && contents.body) {
      // When receiving response through Stimulus, the data can already be converted
      // to a document node. In that case, read the HTML from the body element
      return contents.body.innerHTML;
    }
    return (contents as string);
  }

  private static isLocalUri(urlString) {
    return urlString && urlString.indexOf('://') === -1 && urlString.indexOf('//') !== 0;
  }
}
