import { Controller } from '@stimulus/core';

export default class AddressController extends Controller {
  public static targets = ['addresses', 'countryCode', 'postalCode', 'houseNumber', 'houseNumberExt', 'line1', 'line2', 'city', 'state'];

  private readonly addressesTarget: HTMLSelectElement;
  private readonly hasAddressesTarget: boolean;
  private readonly countryCodeTarget: HTMLInputElement;
  private readonly postalCodeTarget: HTMLInputElement;
  private readonly houseNumberTarget: HTMLInputElement;
  private readonly houseNumberExtTarget: HTMLInputElement;
  private readonly line1Target: HTMLInputElement;
  private readonly line2Target: HTMLInputElement;
  private readonly cityTarget: HTMLInputElement;
  private readonly stateTarget: HTMLInputElement;

  public selectAddress(event: Event) {
    const select = event.currentTarget as HTMLSelectElement;

    if (select.selectedIndex === null) {
      return;
    }

    const option = select.options[select.selectedIndex];
    const address = JSON.parse(option.dataset.address);
    this.countryCodeTarget.value = address.countryCode;
    this.postalCodeTarget.value = address.postalCode;
    this.houseNumberTarget.value = address.houseNumber;
    this.houseNumberExtTarget.value = address.houseNumberExt;
    this.line1Target.value = address.line1;
    this.line2Target.value = address.line2;
    this.cityTarget.value = address.city;
    this.stateTarget.value = address.state;
  }
}
