import { DirectUpload } from '@rails/activestorage';
import { Controller } from '@stimulus/core';
import { CombineSubscriptions, DestroySubscribers } from 'ngx-destroy-subscribers';
import { BehaviorSubject, Unsubscribable, fromEvent } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { EventHelper } from '../helpers/event-helper';

export interface UploadQueueEntry {
  id: number;
  progress: number;
  state: UploadQueueState;
  file: File;
  signed_id?: string;
  error_reason?: string;
  xhr?: XMLHttpRequest;
}
export type UploadQueueState = 'waiting' | 'allowedforupload' | 'inprogress' | 'ready' | 'error' | 'error_dispatched';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class FileUploadController extends Controller {
  public static targets = ['form', 'fileInput', 'uploadedFile'];

  private uploadQueueSubject: BehaviorSubject<{ uploads: UploadQueueEntry[], propagate: boolean }> = new BehaviorSubject({ uploads: [], propagate: false });

  private readonly fileInputTarget!: HTMLInputElement;
  private readonly formTarget!: HTMLFormElement;
  private readonly uploadedFileTarget: HTMLDivElement;
  private readonly hasUploadedFileTarget: boolean;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  private CONCURRENT_FILE_UPLOADS = 1;

  public connect() {
    this.fileInputTarget.addEventListener('change', () => {
      Array.from(this.fileInputTarget.files).forEach(file => {
        if (this.uploadAllowed(file)) {
          this.addFileToUploadQueue(file)
        }
      });
      this.fileInputTarget.value = null
    });

    this.subscriber = this.uploadQueueSubject.asObservable().pipe(
      tap(queue => this.processQueuedUploads(queue.uploads)),
      tap(queue => this.disableSubmitWhileUploading(queue.uploads)),
      filter(queue => queue.propagate),
      map(queue => {
        const erroredUploads = queue.uploads.filter(row => row.state === 'error')
        if (erroredUploads.length) {
          EventHelper.dispatch(this.element, 'file-upload-snd-upload-error', { erroredUploads });
          erroredUploads.forEach(row => this.markUploadEntryAs(row.id, 'error_dispatched', { propagate: false }));
        }
        return {
          ...queue,
          uploads: queue.uploads.filter(row => row.state !== 'error')
        }
      })
    ).subscribe(queue => {
      EventHelper.dispatch(this.element, 'file-upload-snd-filelist-updated', { uploads: queue.uploads });
    });

    this.setupPageExitListener();
  }

  public onDrop(event: CustomEvent) {
    const files = event.detail.files;
    Array.from(files).forEach((file: File) => {
      if (this.uploadAllowed(file)) {
        this.addFileToUploadQueue(file)
      }
    })
  }

  public selectFiles() {
    this.fileInputTarget.click();
  }

  public removeFile(event: CustomEvent) {
    const { id, signedId } = event.detail;
    this.removeFromQueue(parseInt(id));
    this.removeFromForm(signedId);
  }

  public downloadFile(event: Event) {
    const url = (event.currentTarget as HTMLElement).dataset.url;
    window.location.href = url;
  }

  private processQueuedUploads(uploads: UploadQueueEntry[]) {
    const inProgressCount = uploads.filter(row => ['inprogress', 'allowedforupload'].includes(row.state)).length;
    const waitingForUploadCount = uploads.filter(row => row.state === 'waiting').length;
    if (inProgressCount < this.CONCURRENT_FILE_UPLOADS && waitingForUploadCount > 0) {
      let counter = inProgressCount;
      this.uploadQueueSubject.next({
        uploads: uploads.map(row => {
          if (row.state === 'waiting' && counter < this.CONCURRENT_FILE_UPLOADS) {
            row.state = 'allowedforupload';
            counter++;
          }
          return row;
        }), propagate: true
      })
    }
    uploads.filter(row => row.state === 'allowedforupload').forEach(row => this.startUploadingEntry(row));
  }

  private disableSubmitWhileUploading(uploads: UploadQueueEntry[]) {
    if (uploads.filter(row => ['waiting', 'allowedforupload', 'inprogress'].indexOf(row.state) > -1).length > 0) {
      this.element.querySelectorAll('input[type=submit]').forEach(e => e.setAttribute('disabled', 'disabled'));
    } else {
      this.element.querySelectorAll('input[type=submit]').forEach(e => e.removeAttribute('disabled'));
    }
  }

  private removeFromQueue(id: number) {
    const currentUploadObj = this.uploadQueueSubject.getValue().uploads.find(row => row.id === id);
    if (currentUploadObj) {
      if (currentUploadObj.xhr) {
        currentUploadObj.xhr.abort();
      }
      this.uploadQueueSubject.next({ uploads: this.uploadQueueSubject.getValue().uploads.filter(row => row.id !== id), propagate: true });
    }
  }

  private removeFromForm(signedId: string) {
    const hiddenInput = this.formTarget.querySelector(`input[value="${signedId}"]`);
    if (hiddenInput) {
      hiddenInput.remove();
    }
  }

  private markUploadEntryAs(id: number, state: UploadQueueState, options: {
    progress?: number,
    error_reason?: string,
    signed_id?: string,
    xhr?: XMLHttpRequest,
    propagate?: boolean
  } = {}) {
    let uploads = this.uploadQueueSubject.getValue().uploads;
    const uploadObj = uploads.find(row => row.id === id);
    if (uploadObj) {
      uploadObj.state = state;
      uploadObj.progress = options.progress || uploadObj.progress;
      uploadObj.signed_id = options.signed_id;
      uploadObj.xhr = options.xhr;
      uploadObj.error_reason = options.error_reason;
      uploads = uploads.map(row => row.id === id ? uploadObj : row);
    }
    this.uploadQueueSubject.next({ uploads, propagate: options.propagate === false ? false : true });
  }

  private startUploadingEntry(uploadObj) {
    const index = this.uploadQueueSubject.getValue().uploads.findIndex(obj => obj.id === uploadObj.id);
    this.markUploadEntryAs(uploadObj.id, 'inprogress');
    const url = this.fileInputTarget.dataset.directUploadUrl;
    const that = this;
    const upload = new DirectUpload(uploadObj.file, url, {
      directUploadWillStoreFileWithXHR: (xhr) => {
        xhr.upload.addEventListener("progress",
          event => {
            that.markUploadEntryAs(uploadObj.id, 'inprogress', { xhr, progress: (event.loaded / event.total) * 100 });
          });
      }
    })

    upload.create((error, blob) => {
      if (error) {
        this.markUploadEntryAs(uploadObj.id, 'error', { error_reason: error });
      } else {
        const hiddenField = document.createElement('input');
        hiddenField.id = `new_file_${blob.signed_id}`;
        hiddenField.type = 'hidden';
        hiddenField.value = blob.signed_id;
        hiddenField.name = this.fileInputTarget.name.replace('[]', `[${index}]`);
        this.formTarget.appendChild(hiddenField);
        this.markUploadEntryAs(uploadObj.id, 'ready', {
          signed_id: blob.signed_id
        });
      }
    })
  }

  private addFileToUploadQueue(file) {
    const currentFiles = this.uploadQueueSubject.getValue().uploads;
    this.uploadQueueSubject.next({
      uploads: [
        ...currentFiles,
        {
          id: currentFiles.length + 1,
          progress: 0,
          state: 'waiting',
          file
        }
      ], propagate: true
    });
  }

  private uploadAllowed(file: File): boolean {
    const maxFileSize = parseInt(this.data.get('maxFileSize'), 0);
    const allowedToUpload = this.data.get('uploadAllowed') === 'true';
    const allowedFileSize = file.size <= maxFileSize;
    if (!allowedToUpload) {
      EventHelper.dispatch(this.element, 'file-upload-snd-upload-refused', { filename: file.name, reason: 'max-items-reached' });
    } else if (!allowedFileSize) {
      EventHelper.dispatch(this.element, 'file-upload-snd-upload-refused', { filename: file.name, reason: 'file-too-large' });
    }
    return allowedToUpload && allowedFileSize;
  }

  private setupPageExitListener() {
    this.subscriber = fromEvent(window, 'turbolinks:before-visit').subscribe(e => {
      if (this.pendingFiles.length > 0) {
        if (confirm(this.data.get('pendingExitConfirmation'))) {
          this.pendingFiles.forEach(item => {
            this.removeFromQueue(item.id);
          });
        } else {
          e.preventDefault();
        }
      }
    });
  }

  private get pendingFiles() {
    const queue = this.uploadQueueSubject.getValue();
    return queue.uploads.filter(f => ['allowedforupload', 'waiting', 'inprogress'].includes(f.state));
  }
}
