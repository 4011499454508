import { CombineSubscriptions, DestroySubscribers } from 'ngx-destroy-subscribers';
import { Unsubscribable } from 'rxjs';
import { Controller } from '@stimulus/core';
import { Container } from 'typedi';
import { CookieHelper } from '../helpers/cookie-helper';
import { FormValidationHelper } from '../helpers/form-validation-helper';
import { ServerResponseHelper } from '../helpers/server-response-helper';
import { ModalHelper } from '../helpers/modal-helper';
import { ModalType } from '../models/modal_type';
import { SessionToken } from '../subjects/session_subject';
import { AppBridge } from '../helpers/app-bridge';
import LikeController from './like_controller';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class LoginController extends Controller {
  private formValidator: FormValidationHelper;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.formValidator = new FormValidationHelper();
    this.formValidator.attachFormValidation(this.element);
  }

  public disconnect() {
    this.formValidator.cleanUp();
  }

  public onLoginSuccess(event: CustomEvent) {
    const [data] = event.detail;
    Container.get(SessionToken).next(data.session);
    CookieHelper.set(LikeController.LIKE_COOKIE_PATH, data.session.user.likes);
    ModalHelper.closeModal(ModalType.ALERT);
    if (data.redirect !== undefined) {
      window.location.href = data.redirect.url;
    }
    AppBridge.sendMessage("vipio.session.signIn", { session: data.session });
  }

  public onLoginError(event: CustomEvent) {
    const [data] = event.detail;
    if (!ServerResponseHelper.handleServerError(event)) {
      Container.get(SessionToken).next(null);
      this.formValidator.setErrorsFromServerResponse(data.errors);
    }
  }

  public onSubmit() {
    this.formValidator.hideFormErrors();
    this.formValidator.disableAllInputFields();
  }
}
