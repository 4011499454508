import { Controller } from '@stimulus/core';
import * as $ from "jquery";
import * as JQuery from 'jquery';
import { CombineSubscriptions, DestroySubscribers } from 'ngx-destroy-subscribers';
import { Unsubscribable } from 'rxjs';
import Container from 'typedi';
import { Toast } from '../models/toast';
import { ToastToken } from '../subjects/toast_subject';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class ToastController extends Controller {
  public static targets = ["toastTemplate", 'toast'];

  @CombineSubscriptions()

  private subscriber: Unsubscribable;
  private $compiledToastTemplate: JQuery<HTMLTemplateElement>;
  private readonly toastTemplateTarget!: Element;
  private readonly toastTargets: HTMLElement[];

  public connect() {
    this.$compiledToastTemplate = $(this.toastTemplateTarget.innerHTML);
    this.subscriber = Container.get(ToastToken)
      .subscribe(toastMessage => {
        this.deleteToastsOfType(toastMessage.type);
        if(toastMessage.action === "create") {
          this.createToast(toastMessage);
        }
      })
  }

  private compileToastTemplate(toast: Toast) {
    const $node = this.$compiledToastTemplate.clone();
    $node.data('target', 'toast.toast')
    if (toast.type) {
      $node.addClass(toast.type);
    }
    ['message'].forEach(key => {
      if (toast[key]) {
        $node.find(`[data-field='${key}']`).html(toast[key]);
      } else {
        $node.find(`[data-field='${key}']`).remove();
      }
    })
    return $node;
  }

  private createToast(toast: Toast) {
    const $newToast = this.compileToastTemplate(toast);
    $newToast.toast({
      autohide: false,
      animation: true,
      ...toast.options
    });
    $('#toastContainer').append($newToast);
    $newToast.toast('show');
  }

  private deleteToastsOfType(type: string) {
    this.toastTargets.forEach(target => {
      if(target.classList.contains(type)) {
        target.remove();
      }
    })
  }

}
