import { Controller } from "@stimulus/core";
import { secureFetch } from "../auth/secure_fetch_function";
import { EventHelper } from "../helpers/event-helper";
import { DateHelper } from "../helpers/date-helper";
import { ModalHelper } from '../helpers/modal-helper';
import { LogHelper } from "../helpers/log-helper";

export default class ListingItemsController extends Controller {
  public static targets = ['list', 'item','confirmButton', 'startDate', 'endDate'];

  private readonly listTarget!: HTMLElement;
  private readonly itemTargets!: HTMLElement[];
  private readonly confirmButtonTarget!: HTMLElement;
  private readonly startDateTarget!: HTMLSpanElement;
  private readonly endDateTarget!: HTMLSpanElement;
  private readMoreOpened: boolean;
  private latestRequest: Promise<Response>; 

  private readonly hasConfirmButtonTarget: boolean;

  public selectItem(event: Event) {
    event.stopPropagation();

    if (this.data.get('mode') !== 'select-item') { return }
    const target = (event.currentTarget instanceof HTMLElement) ? event.currentTarget : event.target;
    const itemId = (target as HTMLElement).dataset.itemId;
    this.itemTargets.forEach(item => item.classList.toggle('selected', item.dataset.itemId === itemId));

    this.enableConfirmButton()
  }

  public selectAndConfirmItem(event: Event) {
    this.selectItem(event);
    this.confirmSelectedItem(event);
  }

  public bookItem(event: Event) {
    this.selectItem(event);
    this.confirmSelectedItem(null, true);
  }

  public unselectItem(event: Event) {
    event.stopPropagation();
    if (this.itemTargets.length === 1) { return }
    this.itemTargets.forEach(item => { item.classList.toggle('selected', false) });
    EventHelper.dispatch(window, 'listing-items-snd-item-selected', { id: null });
  }

  public showPricesClicked(event: Event) {
    event.stopPropagation();
    EventHelper.dispatch(this.element, 'listing-items-snd-choose-dates', {});
  }

  public async onCriteriaUpdated(event: CustomEvent) {
    const { startDate, endDate, groupSize, itemId } = event.detail;

    const params = {
      check_in: startDate && DateHelper.isoDateString(startDate),
      check_out: endDate && DateHelper.isoDateString(endDate),
      group_size: groupSize
    };

    const baseUrl = this.data.get('url')
    const query = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
    const url = baseUrl.indexOf('?') > 0 ? `${baseUrl}&${query}` : `${baseUrl}?${query}`;

    this.mode = 'loading';

    const request = secureFetch(url);
    this.latestRequest = request;

    const result = await request;
    if (request !== this.latestRequest) {
      // Request has been superseded by newer request
      return
    } 

    if (result.status === 200) {
      this.mode = endDate ? 'select-item' : 'choose-dates';
      this.listTarget.innerHTML = await result.text();
      if (this.listTarget.querySelectorAll('.card').length === 1) {
        this.confirmSelectedItem(event, false, true);
        this.enableConfirmButton();
      } else {
        this.itemTargets.forEach(item => item.classList.toggle('selected', item.dataset.itemId === itemId.toString()));
      }
    } else {
      this.mode = 'choose-dates';
      alert("Er is onverwacht iets misgegaan. Probeer het nog eens, of kies een andere datum voor jouw boeking");
      LogHelper.logError(`Listing items request to ${url} returned unexpected status ${result.status}`);
    }
  }

  public flashItems() {
    this.element.classList.remove('flash-items');
    // CSS Animation hack: force document reflow to fix triggering an animation again
    (this.element as HTMLElement).offsetWidth;
    this.element.classList.add('flash-items');
  }

  public confirmSelectedItem(event: Event, submit: boolean = false, autoSelected: boolean = false) {
    const selectedItem = this.itemTargets.find(item => item.classList.contains('selected'));
    if (selectedItem) {
      const imageSrc = (selectedItem.querySelector('.room-preview-image').querySelector('img') as HTMLImageElement).src;
      const title = selectedItem.querySelector('.room-title').textContent;

      EventHelper.dispatch(window, 'listing-items-snd-item-selected', 
        { 
          id: selectedItem.dataset.itemId, 
          priceUrl: selectedItem.dataset.priceUrl,
          imageSrc,
          title,
          submit,
          autoSelected
        } 
      );
    }
  }

  public toggleItem(event: Event) {
    event.stopPropagation();
    const toggleState = (event.currentTarget as HTMLElement).parentElement.classList.contains('expanded');

    if (toggleState === true) {
      this.collapseItem(event);
    } else {
      this.expandItem(event);
    }
  }

  public expandItem(event: Event) {
    event.stopPropagation();
    const itemId = (event.currentTarget as HTMLElement).dataset.itemId;

    this.itemTargets.forEach(item => item.classList.toggle('expanded', item.dataset.itemId === itemId));
  }

  public collapseItem(event: Event) {
    event.stopPropagation();
    const itemId = (event.currentTarget as HTMLElement).dataset.itemId;

    this.itemTargets.forEach(item => {
      if (itemId === item.dataset.itemId) { item.classList.toggle('expanded', false) }
    });
  }

  public async readMore(event: Event) {
    if (!this.readMoreOpened) {
      this.readMoreOpened = true;
      const url = (event.currentTarget as HTMLElement).dataset.readMoreUrl;
      const modal = await ModalHelper.openModalWithUrl(url);
      modal.on('hidden.bs.modal', () => this.readMoreOpened = false);
    }
  }

  private setDateSelectedDate(event: CustomEvent) {
    const { startDate, endDate } = event.detail;

    this.startDateTarget.innerHTML = startDate ? DateHelper.humanFormat(startDate, 'short') : null;
    this.endDateTarget.innerHTML = endDate ? DateHelper.humanFormat(endDate, 'short') : null;
  }

  private enableConfirmButton() {
    if (this.hasConfirmButtonTarget) { this.confirmButtonTarget.classList.remove('disabled'); }
  }

  set mode(mode: 'choose-dates' | 'loading' | 'select-item') {
    this.data.set('mode', mode);
  }
}
