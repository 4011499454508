import { SearchTerm } from "../search_term";

export default class SearchBarSearchTermUpdatedEvent extends CustomEvent<{
  searchTerm: SearchTerm;
  retainFilterKeys: string[];
}> {
  constructor(detail: {
    searchTerm: SearchTerm;
    retainFilterKeys: string[];
  }) {
    super('search-bar-snd-search-term-updated', { bubbles: true, detail });
  }
}