import { Controller } from '@stimulus/core';
import { secureFetch } from '../auth/secure_fetch_function';

export default class ToggleController extends Controller {
  public async createOrDestroy(event: Event) {
    const checkbox = event.currentTarget as HTMLInputElement;
    if (checkbox.checked) {
      const url = checkbox.dataset.createUrl;
      const mailing = checkbox.dataset.mailing;
      const formData = new FormData();
      formData.append('mailing', mailing);
      const response = await secureFetch(url, { method: 'POST', body: formData })
      if (!response.ok) {
        checkbox.checked = !checkbox.checked;
      }
    } else {
      const url = checkbox.dataset.deleteUrl;
      const response = await secureFetch(url, { method: 'DELETE' })
      if (!response.ok) {
        checkbox.checked = !checkbox.checked;
      }
    }
  }
}
