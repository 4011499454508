import { Controller } from '@stimulus/core';
import { EventHelper } from '../helpers/event-helper';

export default class DropzoneController extends Controller {
  public static targets = ['form', 'fileInput', 'dropzone'];

  readonly fileInputTarget!: HTMLInputElement;
  readonly formTarget!: HTMLFormElement;
  readonly dropzoneTarget!: HTMLDivElement;

  acceptedFileTypes: string[] = [];

  connect() {
    if (this.data.get('acceptedTypes')) {
      this.acceptedFileTypes = this.data.get('acceptedTypes').split(',');
    }
    document.addEventListener("dragenter", (event: DragEvent) => {
      if (event.target === this.element && event.dataTransfer.types.indexOf("Files") > -1) {
        (event.target as HTMLDivElement).classList.add('file-hover');
      }
    });

    document.addEventListener("dragover", (event: DragEvent) => {
      event.preventDefault();
    });

    document.addEventListener("dragleave", (event: DragEvent) => {
      if (event.target === this.element && event.dataTransfer.types.indexOf("Files") > -1) {
        (event.target as HTMLDivElement).classList.remove('file-hover');
      }
    });

    document.addEventListener("drop", (event: DragEvent) => {

      if (event.target === this.element && event.dataTransfer.types.indexOf("Files") > -1) {
        event.preventDefault();
        if (event.dataTransfer.files) {
          const files = this.filterFileTypes(event);
          EventHelper.dispatch(this.element, 'dropzone-snd-on-drop', { files });
          (event.target as HTMLDivElement).classList.remove('file-hover');
        }
      }

    });
  }

  private filterFileTypes(event: DragEvent) {
    let files = Array.from(event.dataTransfer.files);
    if (this.acceptedFileTypes.length > 0) {
      files = files.filter(f => this.acceptedFileTypes.indexOf(f.type) > -1);
    }
    return files;
  }
}