import { Controller } from '@stimulus/core';
import {ToastHelper} from "../helpers/toast-helper";
import {ToastType} from "../models/toast";

export default class ListingToastController extends Controller {

  private daysRemainingTimeoutId: number;
  private selectedPeriodWarningTimeoutId: number;
  private percentageRemainingTimeoutId: number;

  public connect() {
    if (this.data.get('daysRemainingMessage')) {
      this.daysRemainingTimeoutId = window.setTimeout(
        () => this.sendDaysRemainingToast(), 5000);
    }
    if (this.data.get('selectedPeriodWarning')) {
      this.selectedPeriodWarningTimeoutId = window.setTimeout(
        () => this.sendSelectedPeriodWarningToast(), 1000);
    }
    if (this.data.get('percentageRemaining')) {
      this.percentageRemainingTimeoutId = window.setTimeout(
        () => this.sendPercentageRemainingToast(), 5000);
    }
  }

  public disconnect() {
    // Use clearTimeout to ensure the function won't be called if
    // the controller was disconnected before the timeout completes
    window.clearTimeout(this.daysRemainingTimeoutId);
    window.clearTimeout(this.selectedPeriodWarningTimeoutId);
    window.clearTimeout(this.percentageRemainingTimeoutId);
  }

  public sendPricePeakSeasonToast(event: CustomEvent) {
    const maxCents = parseFloat(this.data.get('maximumRate').replace(/\D/g, ''));
    const currentCents =  parseFloat(event.detail.pricePerNight.replace(/\D/g, ''));
    const priceDifferencePercentage = 100 - (currentCents / maxCents * 100);

    if (priceDifferencePercentage > 25) {
      this.dispatchPricePeakSeasonToast(this.data.get('maximumRate'), event.detail.pricePerNightFormatted);
    } else {
      this.deletePricePeakSeasonToast();
    }
  }

  private sendSelectedPeriodWarningToast() {
    const message = this.data.get('selectedPeriodWarning');
    ToastHelper.create(ToastType.SELECTED_PERIOD_WARNING, message);
  }

  private sendDaysRemainingToast() {
    const daysRemainingMessage = this.data.get('daysRemainingMessage');
    ToastHelper.create(ToastType.DAYS_REMAINING, daysRemainingMessage );
  }

  private sendPercentageRemainingToast() {
    ToastHelper.create(
        ToastType.PERCENTAGE_REMAINING,
        this.data.get('percentageRemaining'),
    );
  }

  private dispatchPricePeakSeasonToast(max: string, current: string) {
    ToastHelper.create(
      ToastType.PRICE_PEAK_SEASON,
      this.data.get('peakSeasonMessage').replace('max', max).replace('current', current),
    );
  }

  private deleteSelectedPeriodWarningToast() {
    ToastHelper.delete(ToastType.SELECTED_PERIOD_WARNING);
  }

  private deletePricePeakSeasonToast() {
    ToastHelper.delete(ToastType.PRICE_PEAK_SEASON);
  }
}
