import FormController from './form_controller';

export default class NewsletterSubscriptionController extends FormController {
  public static targets = ['email'];

  private emailTarget : HTMLInputElement;

  public onAjaxSuccessHtml(event: CustomEvent) {
    super.onAjaxSuccessHtml(event);
    this.emailTarget.value = null;
  }
}
