import * as $ from 'jquery';
import { DatePickerMode } from './date-picker-types';
import { DateRange } from './date-range';

export class DatePickerCalendarDay {
  private static TODAY = new Date(new Date().setHours(0, 0, 0, 0));

  public isStartDate: boolean = false;
  public isEndDate: boolean = false;
  public isPast: boolean;
  public isInRange: boolean = false;
  public arrivalAvailable: boolean = false;
  public arrivalAllowed: boolean = false;
  public departureAvailable: boolean = false;
  public departureAllowed: boolean = false;
  private arrivalTooltip: string;
  private departureTooltip: string;

  constructor(public date: Date, public div: HTMLElement, defaultAvailability: boolean, arrivalTooltip, departureTooltip) { 
    this.isPast = date < DatePickerCalendarDay.TODAY;
    this.arrivalAvailable = this.arrivalAllowed = defaultAvailability;
    this.arrivalTooltip = arrivalTooltip;
    this.departureTooltip = departureTooltip;
  }

  public render(mode: DatePickerMode) {
    this.div.classList.toggle('is-in-range', this.isInRange);
    this.div.classList.toggle('is-start-date', this.isStartDate);
    this.div.classList.toggle('is-end-date', this.isEndDate);

    switch(mode) {
      case 'arrival':
        this.div.classList.toggle('is-available', !this.isPast && this.arrivalAvailable);
        this.div.classList.toggle('is-selectable', !this.isPast && this.arrivalAllowed);
        this.updateTooltip(!this.isPast && this.arrivalAvailable && !this.arrivalAllowed, this.arrivalTooltip);
        break;
      case 'departure':
        this.div.classList.toggle('is-available', this.departureAvailable);
        this.div.classList.toggle('is-selectable', this.departureAllowed);
        this.updateTooltip(this.departureAvailable && !this.departureAllowed && !this.isStartDate, this.departureTooltip);
        break;
      case 'plain':
        this.div.classList.toggle('is-available', !this.isPast);
        this.div.classList.toggle('is-selectable', !this.isPast);
    }
  }

  public get isSelectable() {
    return this.div.classList.contains('is-selectable');
  }

  public updateForSelection(selection: DateRange | null, startDate: Date | null) {
    if (selection === null) {
      this.isStartDate = startDate && this.date.valueOf() === startDate.valueOf();
      this.isEndDate = this.isInRange = false;
    } else {
      this.isStartDate = this.date.valueOf() === selection.begin.valueOf();
      this.isEndDate = this.date.valueOf() === selection.end.valueOf();
      this.isInRange = selection.contains(this.date);
    }
  }

  private updateTooltip(enable: boolean, text: string) {
    if (enable) {
      this.div.dataset.originalTitle = text;
      $(this.div).tooltip('enable');
    } else {
      $(this.div).tooltip('disable');
    }
  }
}
