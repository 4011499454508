import * as $ from 'jquery';
import { Controller } from '@stimulus/core';
import { secureFetch } from '../../auth/secure_fetch_function';

export default class CalendarController extends Controller {
  public static targets = ['calendar']

  private readonly calendarTarget: HTMLElement;

  public connect() {
    this.connectTooltips();
  }

  public async refreshCalendar() {
    const url = this.calendarTarget.dataset.calendarRefreshUrl;
    const mode = this.calendarTarget.dataset.calendarRefreshMode;
    const months = this.calendarTarget.dataset.calendarRefreshMonths;
    this.calendarTarget.classList.add('loading');
    const newCalendarResponse = await secureFetch(`${url}?mode=${mode}&months=${months}`);
    if (newCalendarResponse.ok) {
      this.calendarTarget.innerHTML = await newCalendarResponse.text();
      this.connectTooltips();
    }
    this.calendarTarget.classList.remove('loading');
  }

  public connectTooltips() {
    $(this.element).find('[data-toggle="tooltip"]').tooltip();
  }
}
