import * as $ from 'jquery';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Controller } from '@stimulus/core';
import { DateHelper } from '../helpers/date-helper';
import { EventHelper } from '../helpers/event-helper';
import { LogHelper } from '../helpers/log-helper';
import { DatePickerError } from '../models/date-picker-error';
import { DatePickerCalendar } from '../models/date-picker-calendar';
import { DatePickerMode } from '../models/date-picker-types';
import { Month } from '../models/month';
import { DateRange } from '../models/date-range';
import { fromEvent, Unsubscribable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';
import { secureFetch } from '../auth/secure_fetch_function';
import { ModalHelper } from "../helpers/modal-helper";

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class DatePickerController extends Controller {
  public static targets = ['root', 'loadEarlierButton', 'scrollLeftButton', 'loadLaterButton', 'summary',
                                  'summaryNumberOfNights', 'summaryStartDate', 'summaryEndDate', 'months',
                                  'startDateTextField', 'startDateISO8601', 'endDateTextField', 'endDateISO8601',
                                  'clearStartDate', 'clearEndDate', 'clearDates', 'applyDates', 'toggle', 'skipDates'];

  private readonly rootTarget: HTMLElement;
  private readonly loadEarlierButtonTarget: HTMLElement;
  private readonly loadLaterButtonTarget: HTMLElement;
  private readonly scrollLeftButtonTarget: HTMLElement;
  private readonly hasToggleTarget: boolean;
  private readonly summaryTarget: HTMLElement | null;
  private readonly hasSummaryTarget: boolean;
  private readonly summaryStartDateTarget: HTMLElement | null;
  private readonly hasSummaryStartDateTarget: boolean;
  private readonly summaryEndDateTarget: HTMLElement | null;
  private readonly hasSummaryEndDateTarget: boolean;
  private readonly summaryNumberOfNightsTarget: HTMLElement | null;
  private readonly hasSummaryNumberOfNightsTarget: boolean;
  private readonly monthsTarget: HTMLElement | null;
  private readonly startDateTextFieldTargets: HTMLInputElement[];
  private readonly startDateISO8601Target: HTMLInputElement;
  private readonly endDateTextFieldTargets: HTMLInputElement[];
  private readonly endDateISO8601Target: HTMLInputElement;
  private readonly clearStartDateTargets: HTMLElement[];
  private readonly clearEndDateTargets: HTMLElement[];
  private readonly clearDatesTargets: HTMLElement[];
  private readonly skipDatesTargets: HTMLElement[];
  private readonly hasSkipDatesTarget: boolean;
  private readonly applyDatesTargets: HTMLElement[];
  private readonly toggleTarget: HTMLElement;

  // Calendar state
  private calendar: DatePickerCalendar;
  private reselectingStartDate = false;
  private disableCloseOnClickOutside = false;
  private showPastMonths: boolean;

  private calendarStartMonth: Month;
  private twoColumnViewport: Month[] = [];
  private startDate: Date;
  private endDate: Date;
  private groupSize: number;
  private trackGroupSize: boolean;

  private startDateSelectedSubject: Subject<Date> = new Subject();

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    if (!this.data.get('pickerInitialized')) {
      this.data.set('pickerInitialized', 'true');
      this.initDatePicker();
    }

    this.initDates();
    this.initGroupSize();

    if (this.startDate) {
      this.reselectingStartDate = true;
    }
    this.startDateSelectedSubject.next(null);
    this.setSafariClassOnBody();
  }

  public initDatePicker() {
    this.startDate = this.startDateISO8601Target.value ? DateHelper.parseISODateUTC(this.startDateISO8601Target.value) : null;
    this.endDate = this.endDateISO8601Target.value ? DateHelper.parseISODateUTC(this.endDateISO8601Target.value) : null;
    this.calendarStartMonth = Month.fromDate(DateHelper.parseISODateUTC(this.data.get('calendarStart')) || this.startDate || new Date());
    this.validateCalendarStart(this.calendarStartMonth, this.data.get('calendarStart'), this.startDate);
    this.showPastMonths = this.data.get('showPast') === 'true';

    if (this.data.get('noCloseOnClickOutside') !== 'true') {
      this.setupCloseOnClickOutside();
    }
    this.setupDepartureDatesListener();
    this.setupTurboLinksCacheListener();
    this.setupDocumentClickListener();

    this.initGroupSize();
    this.createCalendar(this.calendarStartMonth, 3);
    this.updateSummary();
    this.toggleFooterControls();

    if (this.data.get('show') === 'true') {
      this.showPopup()
    } else {
      this.hidePopup()
    }
  }

  public disconnect() {
    this.hidePopup();
  }

  public onFocusNextDate() {
    if (this.startDate && !this.endDate) {
      this.onFocusEndDate();
    } else {
      this.onFocusStartDate();
    }
  }

  public onClickStartDate(e: Event) {
    e.stopPropagation();
    this.onFocusStartDate();
  }

  public onClickEndDate(e: Event) {
    e.stopPropagation();
    this.onFocusEndDate();
  }

  public onFocusStartDate() {
    if (this.startDate) {
      this.reselectingStartDate = true;
    }
    this.startDateSelectedSubject.next(null);
    this.showPopup();

    this.startDateTextFieldTargets.forEach(el => el.classList.add('focus'));
    this.endDateTextFieldTargets.forEach(el => el.classList.remove('focus'));
    this.updateSummary();

    if (this.startDate) {
      this.scrollMonthIntoView(Month.fromDate(this.startDate))
    }
  }

  public onFocusEndDate() {
    if (this.startDate) {
      this.startDateSelectedSubject.next(this.startDate);
      this.reselectingStartDate = false;
      this.calendar.setMode('departure');
    }

    this.showPopup();
    this.startDateTextFieldTargets.forEach(el => el.classList.remove('focus'));
    this.endDateTextFieldTargets.forEach(el => el.classList.add('focus'));
    this.updateSummary();

    if (this.endDate) {
      this.scrollMonthIntoView(Month.fromDate(this.endDate))
    } else if (this.startDate) {
      this.scrollMonthIntoView(Month.fromDate(this.startDate))
    }
  }

  public clearStartDate() {
    this.updateStartDate(null);
    this.updateEndDate(null);
    this.renderCalendar();
    this.showPopup();

    this.focusFrontmostStartDateTarget();
    this.dispatchDatesChanged();
  }

  public clearEndDate(e?: Event | null) {
    e?.stopPropagation();
    this.updateEndDate(null);
    this.renderCalendar();
    this.showPopup();
    this.focusFrontmostEndDateTextFieldTarget();
    this.dispatchDatesChanged();
  }

  public scrollViewportLeft() {
    const previousMonth = this.twoColumnViewport[0].prev();
    this.loadMonthSections([previousMonth], 'prepend');
    this.setViewportStart(previousMonth);
    EventHelper.dispatch(this.element, 'date-picker-snd-scroll-left');
  }

  public scrollViewportRight() {
    const nextMonth = this.twoColumnViewport[1].next();
    this.loadMonthSections([nextMonth], 'append');
    this.setViewportStart(this.twoColumnViewport[1]);
    EventHelper.dispatch(this.element, 'date-picker-snd-scroll-right');
  }

  public loadEarlierMonths() {
    const firstMonth = this.calendar.firstMonth().subMonths(4);
    const monthsRange = DateRange.monthsRange(firstMonth, 4).filter(month => (this.showPastMonths || !month.isPast()));
    this.loadMonthSections(monthsRange, 'prepend');
    this.setViewportStart(monthsRange[0]);
    EventHelper.dispatch(this.element, 'date-picker-snd-load-earlier');
  }

  public loadLaterMonths() {
    const nextMonth = this.calendar.lastMonth().next();
    this.loadMonthSections(DateRange.monthsRange(nextMonth, 4), 'append');
    EventHelper.dispatch(this.element, 'date-picker-snd-load-later');
  }

  public setDates(startDate?: Date, endDate?: Date) {
    this.updateStartDate(startDate, false);
    this.updateEndDate(endDate, false);
    this.reselectingStartDate = !!endDate;
    this.renderCalendar();
  }

  public applyDates() {
    EventHelper.dispatch(this.element, 'date-picker-snd-apply-dates', {
      startDate: this.startDate,
      endDate: this.endDate
    });
    this.startDateTextFieldTargets.forEach(el => el.classList.remove('focus'));
    this.endDateTextFieldTargets.forEach(el => el.classList.remove('focus'));
    this.hidePopup();
  }

  public clearDates() {
    this.updateStartDate(null)
    this.updateEndDate(null);
    this.onFocusStartDate();
    this.renderCalendar();
    if (this.data.get('closeOnClearDates') === 'true') {
      this.applyDates();
      this.dispatchDatesChanged();
    } else {
      this.dispatchDatesChanged();
    }
  }

  public reload() {
    this.createCalendar(this.calendarStartMonth, 3);
    this.clearDates();
    this.showPopup();
  }

  public hidePopup() {
    if (this.data.get("hide") !== "false") {
      this.rootTarget.classList.add('popup-hidden');
      this.startDateTextFieldTargets.forEach(el => el.classList.remove('focus'));
      this.endDateTextFieldTargets.forEach(el => el.classList.remove('focus'));
    }
  }

  public async updateGroupSize(event: CustomEvent) {
    if (this.trackGroupSize && this.groupSize !== event.detail.groupSize) {
      this.groupSize = event.detail.groupSize;
      this.calendar.reloadArrivalAvailabilities().then(() => {
        if (this.startDate && !this.calendar.isDateAllowedForArrival(this.startDate)) {
          this.clearStartDate();
        } else if (this.endDate && !this.calendar.isDateAllowedForDeparture(this.endDate)) {
          this.clearEndDate();
        }
      });
    }
  }

  public onConfirmButtonClicked(): void {
    EventHelper.dispatch(window, 'date-picker-snd-group-size-confirmed');
  }

  private setSafariClassOnBody() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.body.classList.add('safari');
    }
  }

  private loadMonthSections(monthsRange: Month[], mode: 'append' | 'prepend') {
    const newSections = this.calendar.loadMonths(monthsRange);
    if (this.monthsTarget === null) {
      LogHelper.logError(new DatePickerError('this.monthsRange is null'));
      return;
    } else if (this.monthsTarget === undefined) {
      LogHelper.logError(new DatePickerError('this.monthsRange is undefined'));
      return;
    } else if (this.monthsTarget.append === undefined) {
      LogHelper.logError(new DatePickerError('this.monthsRange.append is undefined'));
      return;
    }

    if (newSections) {
      if (mode === 'prepend') {
        this.monthsTarget.prepend(...newSections)
      } else {
        const button = this.loadLaterButtonTarget;
        button.remove();
        this.monthsTarget.append(...newSections, button);
      }
    }
  }

  private setViewportStart(startMonth: Month) {
    this.twoColumnViewport = [startMonth, startMonth.next()];
    this.calendar.monthSections.forEach((section, dateValue) => {
      section.classList.toggle('two-col-viewport-show', this.isDateValueInViewport(dateValue))
    });
    this.scrollLeftButtonTarget?.classList.toggle('hide', !this.showPastMonths && startMonth.isPastOrToday());
    this.loadEarlierButtonTarget?.classList.toggle('hide', !this.showPastMonths && this.calendar.firstMonth().isPastOrToday());
  }

  private isDateValueInViewport(dateValue) {
    return this.twoColumnViewport.map(m => m.valueOf()).indexOf(dateValue) > -1;
  }

  private isMonthInViewport(month) {
    return this.isDateValueInViewport(month.valueOf());
  }

  private async fetchArrivalAvailabilities(from: Date, till: Date) {
    const url = this.data.get('arrivalDatesUrl')
      .replace('FROM_DATE', DateHelper.isoDateString(from))
      .replace('TILL_DATE', DateHelper.isoDateString(till))
      .replace('GROUP_SIZE', this.groupSize?.toString());

    const response = await secureFetch(url);
    const result = await response.json();
    return {
      'available': result.available.map(row => DateHelper.parseISODateUTC(row)),
      'selectable': result.selectable.map(row => DateHelper.parseISODateUTC(row)),
    }
  }

  private setupDepartureDatesListener() {
    if (this.data.get('departureDatesUrl')) {
      this.subscriber = this.startDateSelectedSubject.asObservable().pipe(
        distinctUntilChanged(),
      ).subscribe(async (date) => {
        try {
          await this.calendar.selectArrivalDate(date)
        } catch (e) {
          LogHelper.logError(e);
          alert('De gekozen aankomstdatum is op dit moment helaas niet beschikbaar. Probeer een andere datum');
          this.updateStartDate(null);
        }
      })
    }
  }

  private async fetchDepartureAvailabilities(date: Date) {
    const url = this.data.get('departureDatesUrl')
      .replace('ARRIVAL_DATE', DateHelper.isoDateString(date))
      .replace('GROUP_SIZE', this.groupSize?.toString());

    const response = await secureFetch(url);
    const result = await response.json();
    return {
      'available': result.available.map(row => DateHelper.parseISODateUTC(row)),
      'selectable': result.selectable.map(row => DateHelper.parseISODateUTC(row)),
    }
  }

  private setupTurboLinksCacheListener() {
    this.subscriber = fromEvent(document, "turbolinks:before-cache").subscribe(() => {
      this.data.delete('pickerInitialized');
      if (this.hasToggleTarget) {
        this.toggleTarget.classList.remove('show');
        $(this.element).find(`[data-target='#${this.toggleTarget.id}']`).attr('aria-expanded', 'false');
      }
    });
  }

  private setupDocumentClickListener() {
    this.subscriber = fromEvent(document, "click").subscribe((e: MouseEvent) => {
      const $clickTarget = $(e.target);
      if (this.hasToggleTarget && this.toggleTarget.classList.contains('show') && $clickTarget.parents(`[data-toggle='collapse']`).length === 0) {
        $(this.toggleTarget).collapse('hide');
        this.applyDates();
      }
    });
  }

  private isPopupVisible() {
    return !this.rootTarget.classList.contains('popup-hidden');
  }

  private scrollMonthIntoView(month: Month) {
    if (!this.isMonthInViewport(month) && this.calendar.isMonthLoaded(month)) {
      this.setViewportStart(month);
    }
  }

  private createCalendar(startMonth: Month, months: number) {
    const mode: DatePickerMode = this.data.get('arrivalDatesUrl') ? 'arrival' : 'plain';
    this.calendar = new DatePickerCalendar(
      mode, this.data.get('locale'), this.data.get('arrivalTooltip'), this.data.get('departureTooltip'), startMonth, months,
      this.onEnterDay.bind(this), this.onClickDay.bind(this),
      (mode === 'arrival' && this.fetchArrivalAvailabilities.bind(this)),
      (mode === 'arrival' && this.fetchDepartureAvailabilities.bind(this))
    );

    // Empty monthsTarget to remove any Turbolinks caches
    Array.from(this.monthsTarget.children).forEach(e => e.remove());

    this.calendar.monthSections.forEach(section => this.monthsTarget.append(section));
    this.calendar.setSelection(this.startDate, this.endDate);
    this.renderCalendar();
    this.setViewportStart(startMonth);
  }

  private setupCloseOnClickOutside() {
    document.addEventListener('click', (event: MouseEvent) => {
      if (!this.disableCloseOnClickOutside && this.isPopupVisible()) {
        if (!this.rootTarget.contains(event.target as Node)) {
          this.hidePopup();
        }
      }
    });
  }

  private toggleClearStartDate(show: boolean) {
    this.clearStartDateTargets.forEach(e => e.classList.toggle('d-none', !show));
  }

  private toggleClearEndDate(show: boolean) {
    this.clearEndDateTargets.forEach(e => e.classList.toggle('d-none', !show));
  }

  private dispatchDatesChanged() {
    EventHelper.dispatch(this.element, 'date-picker-snd-dates-changed', {
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  private showPopup() {
    const wasPopupVisible = this.isPopupVisible();
    this.rootTarget.classList.remove('popup-hidden');

    if (!wasPopupVisible) {
      this.disableCloseOnClickOutside = true;
      setTimeout(() => {
        this.disableCloseOnClickOutside = false
      }, 300);
      EventHelper.dispatch(this.element, 'date-picker-snd-opened');

      this.calendar.getSelectionStartMonthSection()?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      if (this.startDate && this.endDate) {
        this.rootTarget.dataset.reselecting = 'true';
      }
    }
  }

  private toggleFooterControls() {
    if(this.hasSkipDatesTarget) {
      this.clearDatesTargets.forEach(
        e => e.classList.toggle('d-none', !this.startDate)
      );
      this.skipDatesTargets.forEach(
        e => e.classList.toggle('d-none', !!this.startDate)
      );
    } else {
      this.clearDatesTargets.forEach(
        e => e.classList.toggle('disabled', !this.startDate)
      );
    }

    const enableApply = this.data.get('enableApply') === 'always' || (this.startDate && this.endDate);
    this.applyDatesTargets.forEach(e => e.classList.toggle('disabled', !enableApply));
  }

  private onEnterDay(target: HTMLElement) {
    if (this.startDate && !this.endDate && !this.reselectingStartDate) {
      const date = this.calendar.days.find(d => d.div === target).date;
      if (date >= this.startDate) {
        this.calendar.setSelection(this.startDate, date);
      } else {
        this.calendar.setSelection(this.startDate, null);
      }
      this.renderCalendar();
    }
  }

  private onClickDay(target: HTMLElement) {
    const calendarDay = this.calendar.days.find(e => e.div === target);
    const date = calendarDay.date;

    if (!calendarDay.isSelectable) {
      return
    }
    if (!this.startDate) {
      // Start selection
      this.updateStartDate(date);
      this.reselectingStartDate = false;
    } else if (this.reselectingStartDate) {
      this.reselectingStartDate = false;
      this.updateStartDate(date);
      if (date > this.startDate) {
        this.updateEndDate(null);
      }
    } else if (date < this.startDate) {
      // Restart selection
      this.updateEndDate(null);
      this.updateStartDate(date);
    } else if (date === this.startDate) {
      if (this.endDate) {
        // If selecting the end date, clicking the start date should do nothing
        return
      } else {
        // If still selecting start date, regard this as confirming the startdate
        this.updateStartDate(date);
      }
    } else {
      // Finish selection
      this.updateEndDate(date);
      this.reselectingStartDate = true;
      this.handleEndDateChosen();
    }
    this.renderCalendar();
  }

  private renderCalendar() {
    this.calendar.render();
  }

  private updateStartDate(date?: Date, dispatchChangeEvents = true) {
    const startDateWas = this.startDate;
    const endDateWas = this.endDate;

    // Update data layer
    this.startDate = date;
    this.endDate = null;
    this.startDateISO8601Target.value = date ? DateHelper.isoDateString(date) : null;

    // Update UI
    this.updateStartDateTextFields(date);
    this.toggleClearStartDate(date !== null);
    this.toggleFooterControls();
    this.updateSelection();

    if (date) {
      this.startDateTextFieldTargets.forEach(el => el.classList.remove('focus'));
    }

    // Dispatch change events
    if (dispatchChangeEvents) {
      this.startDateSelectedSubject.next(date);

      if (date) {
        this.focusFrontmostEndDateTextFieldTarget();
        this.updateEndDate(null);
        this.dispatchDatesChanged();
        EventHelper.dispatch(this.element, 'date-picker-snd-arrival-chosen', {});
      }
      if (this.startDate !== startDateWas) {
        this.startDateTextFieldTargets.forEach(e => EventHelper.dispatch(e, 'change'));
      }
      if (this.endDate !== endDateWas) {
        this.endDateTextFieldTargets.forEach(e => EventHelper.dispatch(e, 'change'));
      }
    }
  }

  private updateEndDate(date?: Date, dispatchChangeEvents = true) {
    const endDateWas = this.endDate;

    // Update data layer
    this.endDate = date;
    this.endDateISO8601Target.value = date ? DateHelper.isoDateString(date) : null;

    // Update UI layer
    this.updateEndDateTextFields(date);
    this.toggleClearEndDate(date !== null);
    this.toggleFooterControls();
    this.updateSelection();

    if (date) {
      this.endDateTextFieldTargets.forEach(el => el.classList.remove('focus'));
    }

    // Dispatch change events
    if (dispatchChangeEvents) {
      if (date) {
        this.dispatchDatesChanged();
        EventHelper.dispatch(this.element, 'date-picker-snd-departure-chosen', {});
      }
      if (this.endDate !== endDateWas) {
        this.endDateTextFieldTargets.forEach(e => EventHelper.dispatch(e, 'change'));
      }
    }
  }

  private handleEndDateChosen() {
    if (this.hasNoApplyDatesButtons()) {
      this.applyDates();
    }
  }

  private updateSelection() {
    this.calendar.setSelection(this.startDate, this.endDate || this.startDate);
    this.calendar.setMode((this.startDate && !this.endDate) ? 'departure' : 'arrival');
    this.updateSummary();
  }

  private hasNoApplyDatesButtons() {
    return this.applyDatesTargets.filter(e => e.offsetParent).length === 0;
  }

  private updateStartDateTextFields(date) {
    const dateFormatted = date ? DateHelper.humanFormat(date, 'short') : null;
    this.startDateTextFieldTargets.forEach(el => {
      el.value = dateFormatted
    });
  }

  private updateEndDateTextFields(date) {
    const dateFormatted = date ? DateHelper.humanFormat(date, 'short') : null;
    this.endDateTextFieldTargets.forEach(el => {
      el.value = dateFormatted
    });
  }

  private focusFrontmostEndDateTextFieldTarget() {
    const target = this.endDateTextFieldTargets.find(el => el.dataset.frontmost) || this.endDateTextFieldTargets[0];
    target?.focus();
  }

  private focusFrontmostStartDateTarget() {
    const target = this.startDateTextFieldTargets.find(el => el.dataset.frontmost) || this.startDateTextFieldTargets[0];
    target?.focus();
  }

  private updateSummary() {
    if (this.hasSummaryTarget) {
      if (this.startDate && this.endDate) {
        this.summaryTarget.dataset.state = 'selected';
        if (this.hasSummaryNumberOfNightsTarget) {
          const nights = DateHelper.daysDiff(this.startDate, this.endDate);
          this.summaryTarget.dataset.quantity = (nights > 1) ? 'many' : 'one';
          this.summaryNumberOfNightsTarget.innerHTML = nights.toString();
        }
        if (this.hasSummaryStartDateTarget) {
          this.summaryStartDateTarget.innerHTML = DateHelper.humanFormat(this.startDate, 'short');
        }
        if (this.hasSummaryEndDateTarget) {
          this.summaryEndDateTarget.innerHTML = DateHelper.humanFormat(this.endDate, 'short');
        }
      } else if (this.startDate) {
        this.summaryTarget.dataset.state = 'select-end';
      } else {
        this.summaryTarget.dataset.state = 'select-start';
      }
    }
  }

  private validateCalendarStart(startMonth, calendarStart, startDate) {
    if (startMonth.date() >= Month.current().date()) {
      return
    }

    LogHelper.logError(new DatePickerError('StartMonth is in the past'), { startMonth, calendarStart, startDate });
  }

  private initDates() {
    const startDate = DateHelper.parseISODateUTC(this.startDateISO8601Target.value);
    const endDate = DateHelper.parseISODateUTC(this.endDateISO8601Target.value);
    this.updateStartDate(startDate, endDate === null);
    this.updateEndDate(endDate);
  }

  private initGroupSize() {
    if (this.data.get('groupSize')) {
      this.trackGroupSize = true;
      this.groupSize = parseInt(this.data.get('groupSize'), 10);
    } else {
      this.trackGroupSize = false;
    }
  }

  private isInsideModal() {
    return true;
  }
}