import { Controller } from '@stimulus/core';

export default class ExtensibleCheckBoxListController extends Controller {
  public static targets = ['checkBoxColumn', 'checkBoxDiv']

  private readonly checkBoxColumnTargets: HTMLDivElement[];
  private readonly checkBoxDivTargets: HTMLDivElement[];

  public additionalOptionSelected(event: Event) {
    const select = event.currentTarget as HTMLSelectElement;
    const selectedOption = select[select.selectedIndex] as HTMLOptionElement;
    const type = selectedOption.dataset.type;
    const sourceCheckBoxDiv = this.checkBoxDivTargets.find(box => (type === undefined || type === box.dataset.type))
    const checkBoxDiv = sourceCheckBoxDiv.cloneNode(true) as HTMLDivElement;
    const checkBox = checkBoxDiv.getElementsByTagName('input')[0];
    const label = checkBoxDiv.getElementsByTagName('label')[0];

    checkBox.setAttribute('checked', 'checked');
    checkBox.setAttribute('id', selectedOption.value);
    checkBox.setAttribute('value', selectedOption.value);
    label.setAttribute('for', selectedOption.value);
    label.innerHTML = selectedOption.innerHTML;

    this.pickCheckBoxColumnToAdd().appendChild(checkBoxDiv);

    select.selectedIndex = 0;
    select.removeChild(selectedOption);
    if (select.children.length === 1) {
      select.classList.add('d-none');
    }
  }

  private pickCheckBoxColumnToAdd() {
    if (this.checkBoxColumnTargets.length === 1) {
      return this.checkBoxColumnTargets[0]
    }
    if (this.checkBoxColumnTargets[0].querySelectorAll('label').length <= this.checkBoxColumnTargets[1].querySelectorAll('label').length) {
      return this.checkBoxColumnTargets[0]
    } else {
      return this.checkBoxColumnTargets[1]
    }
  }
}
