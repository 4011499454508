import { Controller } from '@stimulus/core';
import { secureFetch } from '../auth/secure_fetch_function';

export default class WaitForPaymentController extends Controller {
  private intervalId: number;

  public connect() {
    this.intervalId = window.setInterval(() => { this.checkForRedirect() }, 1000);
  }

  public disconnect() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private async checkForRedirect() {
    const response = await secureFetch(this.data.get('url'));
    if (response.status === 302) {
      const body = await response.json();
      window.location.href = body.url;
    }
  }
}
