import { Controller } from "stimulus"
export default class FaqNavigationController extends Controller {
  private faqGroups: NodeListOf<Element>

  public connect() {
    this.faqGroups = document.querySelectorAll('.faq-group')
    this.updateClosest();
  }

  private updateClosest() {
    let closestElement = null
    let minimalDistance = Infinity

    this.faqGroups.forEach(group => {
      const rect = group.getBoundingClientRect()
      const distance = Math.abs(rect.top)

      if (distance < minimalDistance) {
        minimalDistance = distance
        closestElement = group
      }
    })

    if (closestElement) {
      this.activateLink(closestElement.id)
    }
  }

  private activateLink(id: string) {
    document.querySelectorAll('.toggle-accordion').forEach(link => {
      link.classList.remove('active')
      if (link.getAttribute('href') === `#${id}`) {
        link.classList.add('active')
      }
    })
  }
}