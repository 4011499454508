import { ReplaySubject, BehaviorSubject, Subject } from 'rxjs';
import { Service, Token } from 'typedi';
import { Toast } from '../models/toast';

export const ToastToken = new Token<ToastSubject>();

class ToastFactory {
  create() {
    return new ToastSubject();
  }
}

@Service({
  id: ToastToken,
  factory: [ToastFactory, "create"]
})
export class ToastSubject extends Subject<Toast>  { }