import { Controller } from '@stimulus/core';

export default class FavoritesController extends Controller {
  public static targets = ['list', 'emptyMessage'];

  private readonly listTargets: HTMLElement[];
  private readonly emptyMessageTarget: HTMLElement;

  public connect() {
    this.update();
  }

  public async update() {
    const isEmpty = this.listTargets.every(list => (list.dataset.isEmpty === 'true'));
    this.emptyMessageTarget.classList.toggle('d-none', !isEmpty);
  }
}
