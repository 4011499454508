import { Month } from './month';

export class DateRange {
  public static monthsRange(startMonth: Month, months: number) {
    const range = [];

    for (let i = 0; i < months; i++) {
      const month = startMonth.addMonths(i);
      range.push(month);
    }
    return range;
  }

  constructor(public begin: Date, public end: Date) { }

  public contains(date: Date) {
    return (date >= this.begin) && (date <= this.end)
  }

  public [Symbol.iterator]() {
    const self = this;
    let date = this.begin;

    return {
      next() {
        date = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1));
        const done = (date >= self.end);
        return { value: date, done };
      }
    };
  }
}
