import { Controller } from '@stimulus/core';
import { secureFetch } from '../auth/secure_fetch_function';

export default class FormFieldTranslatorController extends Controller {
  public static targets = ['input'];

  private readonly inputTargets!: HTMLInputElement[];
  private sourceInput: HTMLInputElement;
  private lastTranslatedValue: string;
  private lastRequest: Promise<Response>;
  private paramName: string;
  private url: string; ''

  public connect() {
    this.paramName = this.data.get('paramName');
    this.url = this.data.get('url');
    this.sourceInput = this.inputTargets.find(i => (i.dataset.locale === this.data.get("sourceLocale")));
    if (this.sourceInput === null) { debugger };
    this.lastTranslatedValue = this.sourceInput.value;
  }

  public async onFocusOut(e: FocusEvent) {
    const input = (e.currentTarget as HTMLInputElement);

    if (input === this.sourceInput && this.lastTranslatedValue !== input.value) {
      this.fetchAndFillTranslations(input.value)
    }
  }

  private async fetchAndFillTranslations(value) {
    const request = secureFetch(
      `${this.url}?${this.paramName}=${encodeURIComponent(value)}`,
      { headers: { "Accept": "application/json" } }
    );
    this.lastRequest = request;
    const response = await request;
    if (request == this.lastRequest) {
      if (response.status >= 200 && response.status < 300) {
        const json = await response.json();
        this.fillTranslations(json);
        this.lastTranslatedValue = value;
      }
    }
  }

  private fillTranslations(translationsJson) {
    Object.entries(translationsJson).forEach(([locale, translation]: [string, string]) => {
      this.inputTargets.find(i => i.dataset.locale === locale).value = translation;
    });
  }
}
