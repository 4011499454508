export default class SearchBarGroupSizeUpdatedEvent extends CustomEvent<{
  groupSize: number | null;
  filterKey: string;
  filterValue: string;
  filterLabel: string;
}> {
  constructor(detail: {
    groupSize: number | null;
    filterKey: string;
    filterValue: string;
    filterLabel: string;
  }) {
    super('search-bar-snd-group-size-updated', { bubbles: true, detail });
  }
}