import { Controller } from "@stimulus/core";
import { secureFetch } from "../auth/secure_fetch_function";
import {LogHelper} from "../helpers/log-helper";

export default class LandingPageImageController extends Controller {
    public static targets = ['landingPageImageContainer'];

    private landingPageImageContainerTarget: HTMLDivElement;

    private latestRequest: Promise<Response>;

    public async onFilterChange(event: CustomEvent) {
        const url = event.detail.landingPageImageUrl

        const request = secureFetch(url);
        this.latestRequest = request;

        try {
            const result = await request;
            if (request !== this.latestRequest) {
                // Request has been superseded by newer request
                return
            }

            if (result.status === 200) {
                this.landingPageImageContainerTarget.insertAdjacentHTML('afterbegin', await result.text());

                const newImage = this.landingPageImageContainerTarget.firstElementChild;
                const oldImage = this.landingPageImageContainerTarget.lastElementChild;

                newImage.querySelector('img').addEventListener('load', async () => {
                    oldImage.querySelector('img').classList.add('transparent');

                    const oldImages = Array.from(this.landingPageImageContainerTarget.children).slice(1);
                    oldImages.forEach(image => {
                        const img = image.getElementsByTagName('img')[0];
                        img.classList.add('opaque');
                        img.addEventListener('transitionend', () => image.remove());
                    });
                });
            }
        } catch (error) {
            LogHelper.logError(error);
        }
    }
}
