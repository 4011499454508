import { Controller } from 'stimulus';
import { DateHelper } from '../../helpers/date-helper';

export default class extends Controller {
  public static targets = ['startDateInput', 'endDateInput', 'dateDiv', 'save'];

  private readonly startDateInputTarget: HTMLInputElement;
  private readonly endDateInputTarget: HTMLInputElement;
  private readonly dateDivTargets: HTMLInputElement[];
  private readonly saveTarget: HTMLInputElement;

  private dragStartAt: HTMLDivElement;
  private dragEndAt: HTMLDivElement;
  private isDragging = false;

  public connect() {
    this.updateDivs();
  }

  public dragStart(event: MouseEvent) {
    event.preventDefault();
    this.isDragging = true;
    this.dragStartAt = event.currentTarget as HTMLDivElement;
    this.dragEndAt = this.dragStartAt;
    this.selectDate(this.dragStartAt, false);
  }

  public moving(event: MouseEvent) {
    if (this.isDragging) {
      event.preventDefault();
      const div = event.currentTarget as HTMLDivElement;
      this.dragEndAt = div;
      this.updateDivs();
    }
  }

  public dragEnd(event: MouseEvent) {
    this.isDragging = false;
    const dragEndAt = event.currentTarget as HTMLDivElement;
    const dragEndDate = DateHelper.parseISODateUTC(dragEndAt.dataset.date);

    if (!DateHelper.isSameDay(dragEndDate, this.startDate)) {
      this.selectDate(dragEndAt);
    }
  }

  private selectDate(div: HTMLDivElement, allowEndDateSelection = true) {
    const date = DateHelper.parseISODateUTC(div.dataset.date);

    if (this.startDate === null || (date.valueOf() < this.startDate.valueOf()) || this.endDate) {
      // (re)selecting start date
      this.startDate = date;
      this.endDate = null;
      this.updateDivs();
    } else if (!DateHelper.isSameDay(date, this.startDate) && allowEndDateSelection) {
      // selecting end date
      this.endDate = date;
      this.updateDivs();
    }
  }

  private updateDivs() {
    const startDate = this.startDate;
    const endDate = this.endDate;
    const dragEndDate = this.dragEndAt && DateHelper.parseISODateUTC(this.dragEndAt.dataset.date);
    const dragValid = dragEndDate && startDate && dragEndDate.valueOf() >= startDate.valueOf();
    const selectionEndDate = dragValid ? dragEndDate : endDate || startDate;

    this.dateDivTargets.forEach(div => {
      const date = DateHelper.parseISODateUTC(div.dataset.date);
      div.classList.toggle('selection-start', startDate && DateHelper.isSameDay(date, startDate));
      div.classList.toggle('selection-end', selectionEndDate && DateHelper.isSameDay(date, selectionEndDate));
      div.classList.toggle('selection-part', startDate && selectionEndDate && date.valueOf() >= startDate.valueOf() && date.valueOf() <= selectionEndDate.valueOf());
    });
  }


  get startDate() {
    return DateHelper.parseISODateUTC(this.startDateInputTarget.value);
  }

  set startDate(date: Date) {
    this.startDateInputTarget.value = date ? DateHelper.isoDateString(date) : null;
  }

  get endDate() {
    return DateHelper.parseISODateUTC(this.endDateInputTarget.value);
  }

  set endDate(date: Date) {
    this.endDateInputTarget.value = date ? DateHelper.isoDateString(date) : null;
  }
}
