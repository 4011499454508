import * as $ from 'jquery';

type BreakpointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export class ResponsiveHelper {
  public static get screenState() {
    return $(window).width() >= 992 ? 'desktop' : 'mobile';
  }

  public static get isMobile() {
    return ResponsiveHelper.screenState === 'mobile';
  }

  public static get isDesktop() {
    return ResponsiveHelper.screenState === 'desktop';
  }

  public static get getScreenHeight() {
    return $(window).height()
  }

  public static get getScreenWidth() {
    return $(window).width();
  }

  public static isBreakpointAtLeast(breakpoint: BreakpointName): boolean {
    return window.innerWidth >= this.breakpoints[breakpoint];
  }

  public static isBreakpointBelow(breakpoint: BreakpointName): boolean {
    return window.innerWidth < this.breakpoints[breakpoint];
  }

  private static breakpoints: { [key in BreakpointName]: number } = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440
  };
}