import { secureFetch } from "../../auth/secure_fetch_function";
import { LogHelper } from "../../helpers/log-helper";
import { ProcessEnabledFacetsCallback } from "../../controllers/filtered_list_controller";

interface ConstructorParams {
  listTarget: HTMLElement;
  filteredListTarget: HTMLElement;
  loadMoreButton: HTMLElement;
  basePath: string;
  filterPath?: string;
  active?: boolean;
  processEnabledFacetsCallback?: ProcessEnabledFacetsCallback | null;
}

export class ListView {
  private listTarget: HTMLElement;
  private filteredListTarget: HTMLElement;
  private loadMoreButton: HTMLElement;
  private basePath: string;
  private filterPath: string;
  private active: boolean;
  private processEnabledFacetsCallback: ProcessEnabledFacetsCallback;

  private loadingMore = false;

  constructor(params: ConstructorParams) {
    this.listTarget = params.listTarget;
    this.filteredListTarget = params.filteredListTarget;
    this.loadMoreButton = params.loadMoreButton;
    this.basePath = params.basePath;
    this.filterPath = params.filterPath;
    this.active = params.active;
    this.processEnabledFacetsCallback = params.processEnabledFacetsCallback;
  }

  public async loadMoreClicked(event: MouseEvent) {
    this.setLoadingClass(true);

    if (this.loadingMore) {
      return
    }

    this.loadingMore = true;
    const buttonElement = event.currentTarget as HTMLButtonElement;
    const page = parseInt(buttonElement.dataset.nextPage, 0);
    buttonElement.classList.add('loading');

    await this.fetchNextPage(page);
    buttonElement.remove();

    this.setLoadingClass(false);
    this.loadingMore = false;
  }

  public setActive(active: boolean) {
    if (active && !this.active) {
      this.fetchNewList();
    }
    this.active = active;
  }

  public filterPathChanged(filterPath: string, finishedCallback: () => void) {
    if (this.filterPath === filterPath) {
      return
    }
    this.filterPath = filterPath;
    this.refreshList(finishedCallback);
  }

  public refreshList(finishedCallback: () => void) {
    if (this.active) {
      this.fetchNewList(finishedCallback);
    }
  }

  private async fetchNewList(finishedCallback: () => void) {
    this.setLoadingClass(true);

    const fetchUrl = `${this.buildUrl}&thin`;
    const response = await secureFetch(fetchUrl, { headers: { "Accept": "text/html" } });

    this.listTarget.innerHTML = await response.text();

    const enabledFacetsJSON = response.headers.get('X-Enabled-Facets');
    if (enabledFacetsJSON) {
      try {
        this.processEnabledFacetsCallback(JSON.parse(enabledFacetsJSON));
      } catch (e) {
        LogHelper.logError('JSON from result could not be parsed correctly, skipping', { enabledFacetsJSON, error: e });
      }
    }
    window.scrollTo(0, 0);

    this.setLoadingClass(false);
    finishedCallback();
  }

  private async fetchNextPage(page: number) {
    const fetchUrl = `${this.buildUrl}&page=${page}&thin`;
    const response = await secureFetch(fetchUrl, { headers: { "Accept": "text/html" } });
    const htmlContents = await response.text();

    const documentFragment = document.createDocumentFragment();
    const div = document.createElement('div');
    div.innerHTML = htmlContents.trim();
    documentFragment.appendChild(div);

    const newGrid = div.querySelector('.grid');
    const buttonRow = div.querySelector('.btn-row');
    this.listTarget.appendChild(newGrid);
    if (buttonRow) {
      this.listTarget.appendChild(buttonRow);
    }
  }

  private setLoadingClass(loading: boolean) {
    this.filteredListTarget.classList.toggle('loading', loading);
  }

  private get isLoadingMore() {
    return this.loadingMore;
  }

  private get buildUrl() {
    return `${this.basePath}/${this.filterPath}`;
  }
}
