import * as Turbolinks from 'turbolinks';

export class HistoryHelper {
  public static pushHistory(url) {
    if (Turbolinks.supported && Turbolinks.controller) {
      Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(url, Turbolinks.uuid());
    } else {
      window.history.pushState({}, '', url);
    }
  }

  public static replaceHistory(url) {
    if (Turbolinks.supported && Turbolinks.controller) {
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(url, Turbolinks.uuid()) 
    } else {
      window.history.replaceState({}, '', url)
    }
  }

  public static visit(url) {
    if (Turbolinks.supported) {
      Turbolinks.visit(url)
    } else {
      window.location.href = url;
    }
  }
}
