import { Controller } from '@stimulus/core';
import { fromEvent, Unsubscribable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class AdditionalCostController extends Controller {
  public static targets = ['checkbox', 'costInput', 'costUnitColumn', 'costColumn', 'costMaxAmountColumn', 'fulfillmentSelect', 'unitSelect'];

  private readonly checkboxTarget!: HTMLInputElement;
  private readonly costInputTarget!: HTMLInputElement;
  private readonly costUnitColumnTarget!: HTMLDivElement;
  private readonly costColumnTarget!: HTMLDivElement;
  private readonly costMaxAmountColumnTarget!: HTMLDivElement;
  private readonly fulfillmentSelectTarget!: HTMLSelectElement;
  private readonly unitSelectTarget!: HTMLSelectElement;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.subscriber = fromEvent(this.costInputTarget, 'blur').pipe(
      delay(50)
    ).subscribe(() => {
      if (this.costInputTarget.value.trim() !== '' && this.costInputTarget.classList.contains('is-valid')) {
        this.checkboxTarget.checked = true;
        return;
      }
      // (JIRA: VIP2-516) this.checkboxTarget.checked = false;
    });

    this.subscriber = fromEvent(this.checkboxTarget, 'change').subscribe(() => {
      this.costInputTarget.setAttribute('form-pristine-value', '');
      this.costInputTarget.setAttribute('form-touched', '0');
      this.costInputTarget.value = '';
      if (this.checkboxTarget.checked) {
        this.costInputTarget.focus();
      }
    });

    this.toggleCostColumns();
  }

  public toggleCostColumns() {
    this.toggleUnitAndPrice();
    this.toggleMaxAmount();
  }

  private toggleUnitAndPrice() {
    const value = this.fulfillmentSelectTarget.value;
    const option = this.fulfillmentSelectTarget.querySelector(`option[value="${value}"]`);
    const show = option.hasAttribute('data-show-unit-and-price');

    [this.costUnitColumnTarget, this.costColumnTarget].forEach(target => {
      target.querySelectorAll('select,input').forEach(item => {
        if (show) {
          item.removeAttribute('disabled');
          item.classList.remove('d-none');
        } else {
          item.setAttribute('disabled', 'disabled');
          item.classList.add('d-none');
        }
      });
      target.querySelectorAll('.input-group').forEach(el => el.classList.toggle('d-none', !show));
      target.querySelectorAll('.not-applicable-message').forEach(el => el.classList.toggle('d-none', show));
    });
  }

  private toggleMaxAmount() {
    const value = this.unitSelectTarget.value;
    const option = this.unitSelectTarget.querySelector(`option[value="${value}"]`);
    const show = option.hasAttribute('data-show-max-amount');
    const input = this.costMaxAmountColumnTarget.querySelector('select');
    if (show) {
      input?.removeAttribute('disabled');
    } else {
      input?.setAttribute('disabled', 'disabled');
    }
    this.costMaxAmountColumnTarget.querySelector('.input-group')?.classList?.toggle('d-none', !show);
    this.costMaxAmountColumnTarget.querySelector('.not-applicable-message').classList.toggle('d-none', show);
  }
}
