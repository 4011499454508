import { Controller } from "stimulus";

export default class GiftCardThemesController extends Controller {
    public static targets = ['theme','hiddenField','previewImage'];

    public themeTargets: HTMLElement[];
    public hiddenFieldTarget: HTMLInputElement;
    public previewImageTargets: HTMLImageElement[];

    public selectTheme(event) {
        this.hiddenFieldTarget.value = event.currentTarget.dataset.themeName;

        this.unselectAllThemes();
        this.selectClickedTheme(event.currentTarget);
        this.setImage(event.currentTarget);
    }

    private unselectAllThemes() {
        this.themeTargets.forEach(theme => {
            theme.classList.remove('selected');
        });
    }

    private selectClickedTheme(theme) {
        theme.classList.add('selected');
    }

    private setImage(themetarget) {
        this.previewImageTargets.forEach(previewImage => {
            previewImage.classList.remove('opaque');
            if (previewImage.dataset.themeName === themetarget.dataset.themeName) {
                previewImage.classList.add('opaque');
            }
        });
    }
}
