import { Controller } from '@stimulus/core';

export default class CookieBarController extends Controller {
  public static targets = ["analytics", "preferences", "marketing", "modal", "customize", "acceptSelection", "consentOptions"];

  private readonly acceptSelectionTarget!: HTMLElement;
  private readonly customizeTarget!: HTMLElement;
  private readonly analyticsTarget!: HTMLInputElement;
  private readonly preferencesTarget!: HTMLInputElement;
  private readonly marketingTarget!: HTMLInputElement;
  private readonly modalTarget!: HTMLElement;
  private readonly consentOptionsTarget!: HTMLElement;


  public connect() {
    const showCookieBar: boolean = localStorage.getItem('consentMode') === null;
    const alreadyAccepted: boolean = document.cookie.indexOf('allow_cookies=true') >= 0;

    if (showCookieBar) {
      if (alreadyAccepted) {
        this.acceptAll();
        return;
      }
      this.element.classList.add('show');
      document.body.classList.add('cookie-visible');
    }
  }

  public acceptAll() {
    this.setConsent({
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true
    })
    this.hideBanner()
  }

  public customize(event: CustomEvent): void {
    this.customizeTarget.hidden = true;
    this.acceptSelectionTarget.hidden = false;
    this.consentOptionsTarget.style.display = 'flex';

    event.stopPropagation();
  }

  public acceptSelection(): void {
    this.setConsent({
      necessary: true,
      analytics: this.analyticsTarget.checked,
      preferences: this.preferencesTarget.checked,
      marketing: this.marketingTarget.checked
    })
    this.hideBanner();
  }

  public rejectAll(): void {
    this.setConsent({
      necessary: true,
      analytics: false,
      preferences: false,
      marketing: false
    })
    this.hideBanner();
  }

  public hideBanner(): void {
    this.modalTarget.classList.remove('show');
    document.body.classList.remove('cookie-visible');
  }

  private setConsent(consent: { necessary: boolean, analytics: boolean, preferences: boolean, marketing: boolean }): void {
    const consentMode = {
      'functionality_storage': consent.necessary ? 'granted' : 'denied',
      'security_storage': consent.necessary ? 'granted' : 'denied',
      'ad_storage': consent.marketing ? 'granted' : 'denied',
      'ad_user_data': consent.marketing ? 'granted' : 'denied',
      'ad_personalization': consent.marketing ? 'granted' : 'denied',
      'analytics_storage': consent.analytics ? 'granted' : 'denied',
      'personalization_storage': consent.preferences ? 'granted' : 'denied'
    }
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
    window.gtag('consent', 'update', consentMode);
  }
}
