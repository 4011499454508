export class Month {
  public static fromDate(date: Date) {
    return new Month(date.getUTCFullYear(), date.getUTCMonth());
  } 

  public static fromDateValue(utcDateValue: number) {
    return this.fromDate(new Date(utcDateValue));
  } 

  public static current() {
    return this.fromDate(new Date());
  }

  constructor(public year: number, public month: number) { }

  public date() {
    return new Date(Date.UTC(this.year, this.month, 1));
  }

  public valueOf() {
    return this.date().valueOf();
  }

  public next() {
    return this.addMonths(1);
  }

  public prev() {
    return this.subMonths(1);
  }

  public isPastOrToday() {
    return this.valueOf() < (new Date()).valueOf();
  }

  public isPast() {
    return this.valueOf() < (Month.current().valueOf());
  }

  public addMonths(months: number) {
    const newDate = new Date(Date.UTC(this.year, this.month + months));
    return new Month(newDate.getUTCFullYear(), newDate.getUTCMonth());
  }

  public subMonths(months: number) {
    const newDate = new Date(Date.UTC(this.year, this.month - months));
    return new Month(newDate.getUTCFullYear(), newDate.getUTCMonth());
  }

  public toString() {
    return `${this.year}-${this.month}`;
  }
}
