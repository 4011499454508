import { Controller } from '@stimulus/core';
declare var Chart: any;

export default class ChartController extends Controller {

  public static targets = ['canvas'];
  private readonly canvasTarget: HTMLCanvasElement;
  private chart: any;
  private defaultMin: any;

  public connect() {
    this.initChart();
  }

  public setScale(event: Event) {
    const min = (event.currentTarget as HTMLElement).dataset.min;
    this.setChartScaleAndRefresh(min);
  }

  public resetScale() {
    this.setChartScaleAndRefresh(this.defaultMin);
  }

  public initChart() {
    const options = JSON.parse(this.data.get('options'));
    this.chart = new Chart(
      this.canvasTarget.getContext('2d'),
      options
    );
    this.defaultMin = this.chart.options.scales.xAxes[0].ticks.min;
  }

  private setChartScaleAndRefresh(min: string) {
    this.chart.options.scales.xAxes[0].ticks.min = min;
    this.chart.update();
  }
}