import { Controller } from '@stimulus/core';
import { fromEvent, Unsubscribable } from 'rxjs';
import { secureFetch } from '../../auth/secure_fetch_function';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class BookingsController extends Controller {
  public static targets = ['slideIn'];

  private readonly slideInTarget!: HTMLDivElement;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.subscriber = fromEvent(document.body, 'click').subscribe((e) => {
      if ((e.target as HTMLElement).closest('.slideIn') === null) {
        if (this.slideInTarget.innerHTML.length > 0) {
          this.slideInTarget.innerHTML = '';
          this.slideInTarget.classList.remove('show');
        }
      }
    });
  }

  public async toggleDetail(e) {
    e.stopPropagation();
    const url = e.currentTarget.dataset.url;

    if (this.slideInTarget.classList.contains('show')) {
      this.slideInTarget.innerHTML = '';
      this.slideInTarget.classList.remove('show');
    }
    else {
      const slideInContents = await secureFetch(url, { method: 'GET' });
      this.slideInTarget.innerHTML = await slideInContents.text();
      this.slideInTarget.classList.add('show');
    }
  }
}
