import { Controller } from '@stimulus/core';

export default class LinkActionTextImagesController extends Controller {
  public connect() {
    this.element.querySelectorAll('action-text-attachment figure img').forEach(img => {
      const attachment = this.findParent(img, 'action-text-attachment');
      const link = this.findPrecedingSibling(attachment, 'a');
      if (link) {
        const imageWithinLink = link.cloneNode();
        (img as HTMLElement).replaceWith(imageWithinLink);
        imageWithinLink.appendChild(img);
      }
    });
  }

  private findParent(el, selector) {
    while(el && !el.matches(selector)) {
      el = el.parentElement;
    }
    return el;
  }

  private findPrecedingSibling(el, selector) {
    while(el && !el.matches(selector) && el.previousElementSibling) {
      if (el.previousElementSibling.classList.contains('btn')) {
        return
      }
      el = el.previousElementSibling
    }
    return el;
  }
}
