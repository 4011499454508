import { Controller } from "stimulus";

export default class CancellationPolicyFormController extends Controller {
    public static targets = ['policyCard', 'policy'];

    private readonly policyCardTargets!: HTMLElement[];
    private policyTarget!: HTMLInputElement;

    public selectPolicy(event: CustomEvent) {
        const target = (event.currentTarget instanceof HTMLElement) ? event.currentTarget : event.target;

        const tier = (target as HTMLElement).dataset.tier;
        this.policyCardTargets.forEach(item => item.classList.toggle('selected', item.dataset.tier === tier));

        this.policyTarget.value = tier;
        this.policyTarget.dispatchEvent(new Event('change'));
    }
}
