export interface Toast {
  type: ToastType;
  action: "delete" | "create";
  message?: string;
  options?: ToastOptions;
}

export interface ToastOptions {
  animation?: boolean;
  autohide?: boolean;
  delay?: number;
}

export enum ToastType {
  DAYS_REMAINING = 'days-remaining',
  PERCENTAGE_REMAINING = 'percentage-remaining',
  PRICE_PEAK_SEASON = 'price-peak-season',
  SELECTED_PERIOD_WARNING = 'selected-period-warning',
  LIKED_A_LISTING = 'liked-a-listing',
}
