import { Controller } from "stimulus";

export default class BalancePickerController extends Controller {
    public static targets = ['button','inputField', 'previewAmount'];

    public buttonTargets: HTMLElement[];
    public inputFieldTarget: HTMLInputElement;
    public previewAmountTarget: HTMLElement;

    public selectBalance(event) {
        // FormValidationHelper only listins to change events if the attribute 'data-form-validation' is set to true
        // This workaround is needed to trigger the validation
        this.inputFieldTarget.setAttribute('data-form-focused', 'true')
        this.inputFieldTarget.value = event.currentTarget.dataset.amount;
        this.inputFieldTarget.dispatchEvent(new Event('change'));
        this.inputFieldTarget.removeAttribute('data-form-focused');


        this.unselectAllButtons();
        this.selectClickedBalance(event.currentTarget);
        this.previewAmountTarget.innerText = '€' + this.checkInput(event.currentTarget.dataset.amount);
    }

    public onInput(event) {
        this.unselectAllButtons();
        this.previewAmountTarget.innerText = '€' + this.checkInput(event.currentTarget.value);
    }

    private selectClickedBalance(balance) {
        balance.classList.add('btn-success');
        balance.classList.remove('btn-white');
    }

    private unselectAllButtons() {
        this.buttonTargets.forEach(button => {
            button.classList.remove('btn-success');
            button.classList.add('btn-white');
        });
    }

    private checkInput(stringValue) {
        const noDecimals = stringValue.split(/[.,]+/)[0];
        const onlyNumbers = noDecimals.replace(/\D/g, '');
        const int = parseInt(onlyNumbers, 0);
        if (isNaN(int)) {
            return 0;
        } else if (int > 10000) {
            return 10000;
        } else {
            return int;
        }
    }

}