(function () {
    document.addEventListener('turbolinks:load', function (event) {
        window.dataLayer.push({'event': 'custom.pageView', 'virtualUrl': event.data.url, 'pageTitle': document.title});
    }, false);
    if (document.readyState === "interactive") {
        window.dataLayer.push({
            'event': 'custom.pageView',
            'pageTitle': document.title,
            'virtualUrl': document.location.href
        });
    }
})();