import { Controller } from "@stimulus/core";
import { EventHelper } from "../helpers/event-helper";

export default class GroupSizePickerController extends Controller {
  public static targets: string[] = ["groupSizeItem", "confirmButton"];

  private readonly groupSizeItemTargets: HTMLElement[];

  public onItemClicked(event: CustomEvent): void {
    const item = event.currentTarget as HTMLElement;
    this.setSelectedElement(item);

    const groupSize = parseInt(item.dataset.groupSize, 10);
    EventHelper.dispatch(window, 'group-size-picker-snd-group-size-changed', { groupSize });
  }

  public onConfirmButtonClicked(): void {
    EventHelper.dispatch(window, 'group-size-picker-snd-group-size-confirmed');
  }

  public setGroupSize(groupSize: number) {
    const target = this.groupSizeItemTargets.find((item) => parseInt(item.dataset.groupSize, 10) === groupSize);
    this.setSelectedElement(target);
  }

  public clearGroupSize() {
    this.setSelectedElement(null);
    EventHelper.dispatch(window, 'group-size-picker-snd-group-size-changed', { groupSize: null, confirmed: true });
  }

  public getLabelForGroupSize(groupSize: number | null): string {
    const target = this.groupSizeItemTargets.find((item) => parseInt(item.dataset.groupSize, 10) === groupSize);
    return target ? target.dataset.label : '';
  }

  public getSmallLabelForGroupSize(groupSize: number | null): string {
    const target = this.groupSizeItemTargets.find((item) => parseInt(item.dataset.groupSize, 10) === groupSize);
    if (!target) {
      return '';
    }
    if (target.dataset.label.includes('+')) {
      return groupSize.toString() + '+';
    } else {
      return groupSize.toString();
    }
  }

  public getFilterValueForGroupSize(groupSize: number): string {
    const target = this.groupSizeItemTargets.find((item) => parseInt(item.dataset.groupSize, 10) === groupSize);
    return target ? target.dataset.filterValue : '';
  }

  private setSelectedElement(element: HTMLElement): void {
    this.groupSizeItemTargets.forEach((item) => {
      item.classList.toggle('selected', item === element);
    });
  }
}