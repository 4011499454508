import { Controller } from '@stimulus/core';

export default class AlertController extends Controller {
  public static targets = ['close'];
  
  toggleMessage() {
    const show = this.element.classList.contains('message-hidden');
    this.element.classList.toggle('message-hidden', !show);
    this.element.classList.toggle('message-shown', show);
  }

}
