import { DatePickerCalendar } from '../models/date-picker-calendar';
import { Month } from '../models/month';
import { DatePickerCalendarDay } from '../models/date-picker-calendar-day';
import { LogHelper } from '../helpers/log-helper';
import { DateRange } from '../models/date-range';

export type mouseEventCallback = (HTMLElement) => void;

export class DatePickerHelper {
  public static createEmptyDay() {
    return this.createElement('div', 'is-previous-month');
  }

  public static createMonthSection(month: Month, days: DatePickerCalendarDay[], locale: string, isLoading: boolean = false) {
    if (days.length < 28) { LogHelper.logError('Unexpected number of days', { days }) }
    const section = this.createElement('section', 'lightpick__month');
    section.classList.toggle('loading', isLoading);
    section.dataset.target = 'date-picker.month';

    const monthName = month.date().toLocaleString(locale, { month: 'long', timeZone: 'UTC' });
    const year = month.year;

    section.innerHTML =
      '<header class="lightpick__month-title-bar">' +
      `<div class="lightpick__month-title">${monthName} ${year}</div>` +
      '</header>';

    section.append(this.createDaysOfWeek(locale));

    const monthDiv = this.createElement('div', 'lightpick__days');

    const nrOfEmptyDays = (month.date().getUTCDay() + 7 - 1) % 7;
    for (let i = 0; i < nrOfEmptyDays; ++i) {
      monthDiv.append(this.createEmptyDay());
    }

    for (const day of days) {
      monthDiv.append(day.div);
    }

    section.append(monthDiv);

    return section;
  }

  public static createCalendarDayDiv(date: Date, onEnter: mouseEventCallback, onClick: mouseEventCallback) {
    const div = this.createElement('div', 'lightpick__day');
    div.innerHTML = date.getUTCDate().toString();
    const handleEvent = (e, func) => { e.stopPropagation(); e.preventDefault(); func.call(null, e.currentTarget) };
    div.addEventListener('click', e => handleEvent(e, onClick));
    div.addEventListener('mouseenter', e => handleEvent(e, onEnter))
    div.dataset.toggle = 'tooltip';
    return div;
  }

  private static createDaysOfWeek(locale: string) {
    const div = this.createElement('div', 'lightpick__days-of-the-week');
    for (let w = 5; w < 5 + 7; ++w) {
      const day = this.createElement('div', 'lightpick__day-of-the-week');
      day.setAttribute('title', this.weekdayName(w, 'long', locale));
      day.innerHTML = this.weekdayName(w, 'short', locale);
      div.append(day);
    }
    return div;
  }

  private static weekdayName(day, weekdayStyle, locale) {
    return new Date(1970, 0, day, 12, 0, 0, 0).
      toLocaleString(locale, { weekday: weekdayStyle, timeZone: 'UTC' })
  }

  private static createElement(tagName, className = ''): HTMLElement {
    const div = document.createElement(tagName);
    div.className = className;
    return div;
  }
}
