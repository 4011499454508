import { Controller } from '@stimulus/core';

export default class UspBarController extends Controller {
  public static targets = ['usp'];

  private uspTargets: HTMLInputElement[];
  private intervalPointer: number;
  private activeIndex: number = 0;
  private uspCount: number;

  public connect() {
    if (window.innerWidth && (window.innerWidth < 992)) {
      if (!this.intervalPointer && this.uspTargets.length > 0) {
        this.intervalPointer = window.setInterval(this.switchUsp.bind(this), 4000);
      }
      this.uspCount = this.uspTargets.length;
    }
  }

  private switchUsp() {
    const currentTarget = this.uspTargets[this.activeIndex];
    this.activeIndex = (this.activeIndex + 1) % this.uspCount;
    const nextTarget = this.uspTargets[this.activeIndex];

    currentTarget.classList.remove('active');
    nextTarget.classList.add('active');
  }
}
