
export class ClipboardHelper {
  public static copyInputValueToClipboard(element: HTMLInputElement): boolean {
    try {
      element.select();
      element.setSelectionRange(0, 99999); /*For mobile devices*/

      document.execCommand("copy");
      return true;
    } catch (e) {
      return false;
    }
  }
}