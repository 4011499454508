export default class SearchBarPeriodUpdatedEvent extends CustomEvent<{
  startDate: Date;
  endDate: Date;
  filterKey: string;
  filterValue: string;
  filterLabel: string;
}> {
  constructor(detail: {
    startDate: Date;
    endDate: Date;
    filterKey: string;
    filterValue: string;
    filterLabel: string;
  }) {
    super('search-bar-snd-period-updated', { bubbles: true, detail });
  }
}