import "reflect-metadata";
import { Controller } from '@stimulus/core';
import { combineLatest, fromEvent, Unsubscribable, Subscription, pipe } from "rxjs";
import { debounceTime, filter, map, startWith, tap, distinctUntilChanged } from "rxjs/operators";
import { secureFetch } from "../auth/secure_fetch_function";
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { SearchTerm } from "../models/search_term";
import { EventHelper } from '../helpers/event-helper';
import { LogHelper } from '../helpers/log-helper';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class SearchTermController extends Controller {
  public static readonly SEARCH_TERM_FILTER_KEY = 'search_term';

  public static targets = [
    'queryInput', 'autoCompleteResults', 'autoCompleteTemplate',
    'clearInputBtn', 'autoCompleteItem', 'startDateISO8601', 'endDateISO8601',
    'groupSizeSelect', 'datePicker', 'flexiblePeriodInput'
  ];
  // TODO: A 'search term' suggestion is always included in the suggestions; we can use that suggesetions filter key for
  // keyboard input suggestions instead of this magic string;
  private MIN_SEARCH_LENGTH = 3;

  private readonly queryInputTarget!: HTMLInputElement;
  private readonly clearInputBtnTarget!: HTMLElement;
  private readonly autoCompleteResultsTarget!: HTMLElement;
  private readonly autoCompleteItemTargets!: HTMLElement[];
  private readonly autoCompleteTemplateTarget!: HTMLTemplateElement;

  private searchTerm: SearchTerm;

  @CombineSubscriptions()
  private subscribers: Unsubscribable;

  private queryInputSubscription: Subscription;

  public connect() {
    this.showClearInputBtnOnInput(this.queryInputTarget.value);

    this.queryInputTarget.addEventListener('focus', () => {
      this.queryInputTarget.style.opacity = '0';
      setTimeout(() => {
        this.queryInputTarget.style.opacity = '1';
        this.queryInputTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100)
    })
  }

  public disconnect() {
    if (this.queryInputSubscription) {
      this.queryInputSubscription.unsubscribe();
    }
    if (this.subscribers) {
      this.subscribers.unsubscribe();
    }
  }

  public onConfirmButtonClicked() {
    this.dispatchSearchTermConfirmed(this.queryInputTarget.value);
  }

  public onQueryInputFocused() {
    if (!this.queryInputSubscription || this.queryInputSubscription.closed) {
      this.setupSearchSubscription();
    }
  }

  public onQueryInputBlurred() {
    if (this.queryInputSubscription) {
      this.queryInputSubscription.unsubscribe();
    }
  }

  public onAutoCompleteItemClick(targetOrEvent: PointerEvent | HTMLElement | null) {
    const resultNode = ((targetOrEvent as PointerEvent).currentTarget || targetOrEvent) as HTMLElement;
    if (resultNode) {
      this.selectAutoCompleteItem(resultNode);
      this.emptyAutoCompleteResults();
      this.hideAutoCompleteResults();
    }
  }

  public clearQueryInput() {
    this.hideAutoCompleteResults();
    this.setQueryInputValue('');
    this.setSearchTerm({ query: '', filterKey: null, filterValue: null });
    this.dispatchSearchTermChanged({ confirmed: false });
    this.clearInputBtnTarget.classList.add('d-none');
    this.focusQueryInput();
    this.fillDefaultSuggestions();
  }

  public focusQueryInput() {
    this.queryInputTarget.focus();
  }

  public blurQueryInput() {
    this.queryInputTarget.blur();
  }

  public setQueryInputValue(value?: string) {
    this.queryInputTarget.value = value;
    EventHelper.dispatch(this.queryInputTarget, 'change');
    this.showClearInputBtnOnInput(value);
  }

  public fillDefaultSuggestionsOnConnect() {
    const isQueryInputEmpty: boolean = this.queryInputTarget.value.length === 0;

    if (isQueryInputEmpty) {
      this.fillDefaultSuggestions();
    }
  }

  private setupSearchSubscription() {
    this.queryInputSubscription = fromEvent(this.queryInputTarget, 'keydown').pipe(
      filter(this.handleQueryInputKeyboardEvent.bind(this)),
      debounceTime(50),
      map(() => this.queryInputTarget.value.trim()),
      pipe(distinctUntilChanged()),
      tap(this.hideAutoCompleteResultsWhenInputBelowMinLength.bind(this)),
      tap(this.showClearInputBtnOnInput.bind(this)),
      tap(query => this.setSearchTerm({ query, filterKey: SearchTermController.SEARCH_TERM_FILTER_KEY, filterValue: query })),
      tap(() => this.dispatchSearchTermChanged({ confirmed: false })
      ),
    ).subscribe(
      this.updateAutoCompleteResults.bind(this)
    )
  }

  private setSearchTerm({ query, filterKey, filterValue }: SearchTerm) {
    if (query.length === 0) {
      this.searchTerm = { query: null, filterKey: null, filterValue: null };
    } else {
      this.searchTerm = { query, filterKey, filterValue };
    }
  }

  private dispatchSearchTermChanged({ confirmed }: { confirmed: boolean }) {
    EventHelper.dispatch(
      window,
      'search-term-snd-search-term-changed',
      { searchTerm: this.searchTerm, confirmed }
    );
  }

  private dispatchSearchTermConfirmed(searchTerm: string) {
    EventHelper.dispatch(
      window,
      'search-term-snd-search-term-confirmed',
      { searchTerm }
    );
  }

  private selectAutoCompleteItem(item: HTMLElement) {
    const query = item.dataset.value;
    this.setQueryInputValue(query);
    this.setSearchTerm({ query, filterKey: item.dataset.filterKey, filterValue: item.dataset.filterValue });
    this.dispatchSearchTermChanged({ confirmed: true });
  }

  private handleQueryInputKeyboardEvent(event: KeyboardEvent): boolean {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
      event.preventDefault();
      this.moveAutoCompleteSelection(event.code === 'ArrowDown' ? 'down' : 'up')
      return false;
    } else if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (this.isAutoCompleteResultsVisible()) {
        const selectedItem =
          this.autoCompleteItemTargets.find(item => item.classList.contains('selected')) ||
          this.autoCompleteItemTargets[0];

        if (selectedItem) {
          event.preventDefault();
          this.selectAutoCompleteItem(selectedItem);
          this.hideAutoCompleteResults();
          return false;
        }
      } else {
        EventHelper.dispatch(window, 'search-term-snd-search-term-confirmed');
      }
    }
    return true;
  }

  private hideAutoCompleteResultsWhenInputBelowMinLength(input: string) {
    if (input.length < this.MIN_SEARCH_LENGTH) {
      this.emptyAutoCompleteResults();
      this.hideAutoCompleteResults();
    }
  }

  private showClearInputBtnOnInput(input: string) {
    if (input.length > 0) {
      this.clearInputBtnTarget.classList.remove('d-none');
    }
  }

  private emptyAutoCompleteResults() {
    this.autoCompleteResultsTarget.innerHTML = '';
  }

  private showAutoCompleteResults() {
    this.autoCompleteResultsTarget.classList.remove('d-none');
  }

  private hideAutoCompleteResults() {
    this.autoCompleteResultsTarget.classList.add('d-none');
  }

  private isAutoCompleteResultsVisible() {
    return !this.autoCompleteResultsTarget.classList.contains('d-none');
  }

  private async updateAutoCompleteResults(input: string) {
    if (input.length >= this.MIN_SEARCH_LENGTH) {
      const url = new URL(this.data.get('autocompleteUrl'), location.href);
      url.searchParams.append('q', input);
      url.searchParams.append('_ts', Date.now().toString());
      const result = await secureFetch(url.toString(), { cache: 'no-cache' });
      if (result.status === 200) {
        const list: AutoCompleteResult[] = await result.json();
        this.emptyAutoCompleteResults();
        list.forEach(item => this.addAutoCompleteItem(item));
        this.showAutoCompleteResults();
        if (list.length < 1) {
          this.fillDefaultSuggestions();
        }
      } else {
        LogHelper.logError(`Request to ${url} returned status ${result.status}`);
      }
    } else {
      this.fillDefaultSuggestions();
    }
  }

  private fillDefaultSuggestions() {
    const list: AutoCompleteResult[] = JSON.parse(this.data.get("results"));
    this.emptyAutoCompleteResults();
    list.forEach(item => this.addAutoCompleteItem(item));
    this.showAutoCompleteResults();
  }

  private addAutoCompleteItem(item: AutoCompleteResult) {
    const templateInstance = this.autoCompleteTemplateTarget.content.cloneNode(true) as HTMLElement;
    const nodeContainer = document.createElement("div");
    nodeContainer.appendChild(templateInstance);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{filterKey}}/g, item.filterKey || '');
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{filterValue}}/g, item.filterValue);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{value}}/g, item.value);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{highlight}}/g, item.highlight);
    nodeContainer.innerHTML = nodeContainer.innerHTML.replace(/{{type}}/g, item.type);
    this.autoCompleteResultsTarget.appendChild(nodeContainer.firstChild);
  }

  private moveAutoCompleteSelection(upOrDown: 'up' | 'down') {
    let selectedItemIndex = this.autoCompleteItemTargets.findIndex(item => item.classList.contains('selected'));

    if (selectedItemIndex !== -1 || (selectedItemIndex === -1 && upOrDown === 'down')) {
      selectedItemIndex = upOrDown === 'up' ? selectedItemIndex - 1 : selectedItemIndex + 1;
      selectedItemIndex = Math.max(0, selectedItemIndex);
      selectedItemIndex = Math.min(selectedItemIndex, this.autoCompleteItemTargets.length - 1);
      this.autoCompleteItemTargets.forEach((item, index) => {
        if (index === selectedItemIndex) {
          item.classList.add('selected');
        } else {
          item.classList.remove('selected');
        }
      })
    }
  }
}

interface AutoCompleteResult {
  value: string;
  type: string;
  filterKey: string;
  filterValue: string;
  highlight: string;
}
