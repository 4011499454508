import { DateHelper } from '../helpers/date-helper';
import { DatePickerError } from './date-picker-error';
import { DatePickerHelper } from '../helpers/date-picker-helper';
import { LogHelper } from '../helpers/log-helper';
import { DatePickerCalendarDay } from './date-picker-calendar-day';
import { Availability, DatePickerMode, Locale } from './date-picker-types';
import { Month } from './month';
import { DateRange } from './date-range';

type FetchArrivalAvailabilitiesCallback = (from: Date, till: Date) => Promise<Availability>;
type FetchDepartureAvailabilitiesCallback = (from: Date) => Promise<Availability>;
type OnEnterDayCallback = (element: HTMLElement) => void;
type OnClickDayCallback = (element: HTMLElement) => void;

export class DatePickerCalendar {
  public days: DatePickerCalendarDay[] = [];
  public monthSections: Map<number, HTMLElement> = new Map();
  public selection: DateRange = null;
  public locale: Locale;

  private lastDepartureAvailabilities: Availability;
  private readonly arrivalTooltip: string;
  private readonly departureTooltip: string;

  constructor(public mode: DatePickerMode, locale, arrivalTooltip: string, departureTooltip: string, startMonth: Month, months: number,
              private onEnterDayCallback: OnEnterDayCallback, private onClickDayCallback: OnClickDayCallback,
              private fetchArrivalAvailabilitiesCallback: FetchArrivalAvailabilitiesCallback,
              private fetchDepartureAvailabilitiesCallback: FetchDepartureAvailabilitiesCallback) {
    this.setLocale(locale);
    this.arrivalTooltip = arrivalTooltip;
    this.departureTooltip = departureTooltip;
    this.loadMonths(DateRange.monthsRange(startMonth, months));
  }

  public async selectArrivalDate(arrivalDate: Date | null) {
    if (arrivalDate) {
      await this.loadDepartureAvailabilities(arrivalDate);
      this.setMode('departure');
    } else {
      this.setMode('arrival');
    }
  }

  public loadMonths(monthsRange: Month[]) {
    const monthsToLoad = monthsRange.filter(month => !this.monthSections.get(month.valueOf()));
    if (monthsToLoad.length > 0) {
      const sections = monthsToLoad.map(month => this.initMonth(month));
      const from = monthsToLoad[0].date();
      const till = monthsToLoad.slice(-1)[0].next().date();
      this.loadArrivalAvailabilities(from, till);
      return sections;
    } else {
      return null
    }
  }

  public isMonthLoaded(month: Month) {
    return this.monthSections.has(month.valueOf());
  }

  public firstMonth() {
    const monthValue = Array.from(this.monthSections.keys()).sort()[0];
    return Month.fromDateValue(monthValue);
  }

  public lastMonth() {
    const monthValue = Array.from(this.monthSections.keys()).sort().slice(-1)[0];
    return Month.fromDateValue(monthValue);
  }

  public setSelection(startDate: Date | null, endDate?: Date | null) {
    this.selection = startDate && endDate && new DateRange(startDate, endDate);
    this.days.forEach(day => day.updateForSelection(this.selection, startDate));
  }

  public render() {
    this.days.forEach(day => day.render(this.mode));
  }

  public getSelectionStartMonthSection() {
    const startDate = this.selection?.begin;
    if (startDate) {
      return this.monthSections.get(Month.fromDate(startDate).valueOf());
    } else {
      return null;
    }
  }

  public setMode(mode: DatePickerMode) {
    if (this.mode === 'plain') {
      return;
    }
    this.mode = mode;
    this.render();
  }

  public async reloadArrivalAvailabilities() : Promise<void> {
    if (this.monthSections.size > 0) {
      this.clearArrivalAvailability();
      this.toggleMonthsLoading(true);
      return this.loadArrivalAvailabilities(this.firstMonth().date(), this.lastMonth().next().date());
    }
  }

  public isDateAllowedForArrival(date: Date) {
    return this.dayForDate(date)?.arrivalAllowed;
  }

  public isDateAllowedForDeparture(date: Date) {
    return this.dayForDate(date)?.departureAllowed;
  }
  
  private clearArrivalAvailability() {
    this.days.forEach(day => { day.arrivalAvailable = day.arrivalAllowed = false });
  }

  private initMonth(month: Month) {
    const section = DatePickerHelper.createMonthSection(month, this.buildMonth(month), this.locale, true);
    this.monthSections.set(month.valueOf(), section);
    return section;
  }

  private loadArrivalAvailabilities(from: Date, till: Date) : Promise<void> {
    if (!this.fetchArrivalAvailabilitiesCallback) {
      this.finishLoading();
    } else {
      return this.fetchArrivalAvailabilitiesCallback(from, till)
          .catch(error => {
              LogHelper.logError(error);
              return({ available: [], selectable: [] });
            })
          .then(this.processArrivalAvailabilities.bind(this))
          .then(this.finishLoading.bind(this));
    }
  }

  private finishLoading() {
    this.render();
    this.toggleMonthsLoading(false);
  }

  private loadDepartureAvailabilities(arrivalDate: Date) {
    this.toggleMonthsLoading(true);
    return this
      .fetchDepartureAvailabilitiesCallback(arrivalDate)
      .then(this.processDepartureAvailabilities.bind(this))
      .catch(e => { this.finishLoading(); throw(e) })
      .then(this.finishLoading.bind(this));
  }

  private processArrivalAvailabilities({ available, selectable }: Availability | null) {
    if (available === null) {
      return;
    }
    available.forEach(date => {
      const day = this.dayForDate(date);
      if (day) {
        day.arrivalAvailable = true;
        // Selectable dates are always a subset of available dates
        day.arrivalAllowed = selectable.some(selectableDate => date.valueOf() === selectableDate.valueOf());
      }
    });
  }

  private processDepartureAvailabilities({ available, selectable }: Availability) {
    this.lastDepartureAvailabilities = { available, selectable };
    this.days.forEach(day => {
      day.departureAvailable = available.some(date => DateHelper.isSameDay(date, day.date));
      day.departureAllowed = selectable.some(date => DateHelper.isSameDay(date, day.date));
    });
  }

  private buildMonth(month: Month) {
    const startOfNextMonth = month.next().date();
    const monthDays = [];
    for(let date = month.date(); date < startOfNextMonth; date = DateHelper.addDays(date, 1)) {
      const div = DatePickerHelper.createCalendarDayDiv(date, this.onEnterDayCallback, this.onClickDayCallback);
      const day = new DatePickerCalendarDay(date, div, this.mode === 'plain', this.arrivalTooltip, this.departureTooltip);
      monthDays.push(day);
    }
    this.days.push(...monthDays);
    if (this.mode === 'departure') {
      this.processDepartureAvailabilities(this.lastDepartureAvailabilities)
    }
    if (monthDays.length < 28) {
      LogHelper.logError(new DatePickerError('DatePickerCalendar: days < 28'), { month, startOfNextMonth, monthDays });
    }
    return monthDays;
  }

  private toggleMonthsLoading(loading: boolean) {
    this.monthSections.forEach(section => {
      section.classList.toggle('loading', loading);
    });
  }

  private dayForDate(date: Date) {
    return this.days.find(d => d.date.valueOf() === date.valueOf());
  }

  private setLocale(locale: Locale) {
    this.locale = locale ?? 'en';
  }
}
