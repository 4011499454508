import Container from "typedi";
import {Toast, ToastOptions, ToastType} from "../models/toast";
import { ToastToken } from "../subjects/toast_subject";

export class ToastHelper {
  public static create(type: ToastType, message?: string, options?: ToastOptions) {
    const toast: Toast = { type, message, action: 'create', options };
    Container.get(ToastToken).next(toast);
  }

  public static delete(type: ToastType) {
    const toast: Toast = { type, action: 'delete' };
    Container.get(ToastToken).next(toast);
  }
}