import * as Honeybadger from '@honeybadger-io/js';

export class LogHelper {
  public static log(_message, _object?) { 
    // do nothing 
  }
  public static trace(_message, _object?) { 
    // do nothing
  }
  public static logError(error, context = {}) {
    try {
      if (typeof Honeybadger !== 'undefined') { 
        Honeybadger.notify(error, { context });
      } else {
        console.log(error, context);
      }
    } catch {
      // ignore
    }
  }
  /*
  public static log(message, data?) {
    try { 
      console.info(`${(new Date()).toLocaleTimeString()} ${message}`, data);
    } catch(e) {
      console.log(e);
    }
  }

  public static trace(message, data?) {
    try { 
      console.trace(`${(new Date()).toLocaleTimeString()} ${message}`, data);
    } catch(e) {
      console.trace(e);
    }
  }
  */
}
