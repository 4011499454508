import { Controller } from '@stimulus/core';

export default class ListingDescriptionController extends Controller {
    public static targets = ['sourceText', 'translatedText', 'bannerButton'];

    private readonly sourceTextTarget!: HTMLFormElement;
    private readonly translatedTextTarget!: HTMLFormElement;
    private readonly bannerButtonTarget!: HTMLSpanElement;

    private translated = true;

    public switch() {
        if (this.translated) {
            this.sourceTextTarget.classList.remove('d-none');
            this.translatedTextTarget.classList.add('d-none');
            this.bannerButtonTarget.innerText = this.data.get('translatedButtonText')
            this.translated = false;
        } else {
            this.sourceTextTarget.classList.add('d-none');
            this.translatedTextTarget.classList.remove('d-none');
            this.bannerButtonTarget.innerText = this.data.get('originalButtonText')
            this.translated = true;
        }
    }
}
