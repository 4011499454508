import { Controller } from '@stimulus/core';

export default class FormUnaryInputController extends Controller {
  public static targets = ['hiddenInput', 'visibleValue', 'incrementBtn', 'decrementBtn', 'destroyInput'];

  private readonly hiddenInputTarget!: HTMLInputElement;
  private readonly visibleValueTarget!: HTMLDivElement;
  private readonly incrementBtnTarget!: HTMLDivElement;
  private readonly decrementBtnTarget!: HTMLDivElement;
  private readonly destroyInputTarget: HTMLInputElement;
  private readonly hasDestroyInputTarget: boolean;

  public connect() {
    this.setInputValue(this.getInputValue());
  }

  public increment() {
    let value = this.getInputValue();
    this.setInputValue(++value);
  }

  public decrement() {
    let value = this.getInputValue();
    this.setInputValue(--value);
  }

  private setInputValue(amount: number) {
    const min = parseInt(this.data.get('min'), 0);
    const max = parseInt(this.data.get('max'), 0);
    amount = amount > max ? max : amount < min ? min : amount;

    this.incrementBtnTarget.classList.toggle('disabled', amount === max);
    this.decrementBtnTarget.classList.toggle('disabled', amount === min);
    this.hiddenInputTarget.value = `${amount}`;
    this.setVisibleValue();
    if (this.hasDestroyInputTarget) {
      this.destroyInputTarget.disabled = !(amount === 0);
    }
  }

  private getInputValue(): number {
    return parseInt(this.hiddenInputTarget.value, 0);
  }

  private setVisibleValue() {
    this.visibleValueTarget.innerHTML = `${this.getInputValue()}`;
  }
}
