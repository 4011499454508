import Container from 'typedi';
import { ModalHelper } from '../helpers/modal-helper';
import { ModalType } from '../models/modal_type';
import { SessionToken } from '../subjects/session_subject';
import FormController from './form_controller';

export default class ModalFormController extends FormController {
  public onAjaxSuccessHtml(event: CustomEvent) {
    this.formValidator.enableAllInputFields();
    const [data] = event.detail;
    ModalHelper.openModalWithContents(data);
  }

  public onAjaxSuccessJson(event: CustomEvent) {
    const [data] = event.detail;

    if (data.session) {
      Container.get(SessionToken).next(data.session);
    }
    if (data.redirect) {
      ModalHelper.openModalWithUrl(data.redirect.url)
    } else if (data.reload) {
      window.location.reload();
    } else {
      this.formValidator.enableAllInputFields();
      ModalHelper.closeModal(ModalType.ALERT);
    }
  }
}
