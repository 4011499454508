import * as $ from 'jquery';
import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { Unsubscribable, BehaviorSubject } from 'rxjs';
import { Controller } from '@stimulus/core';
import { secureFetch } from '../auth/secure_fetch_function';
import { filter } from 'rxjs/operators';
import { EventHelper } from '../helpers/event-helper';
import { LogHelper } from '../helpers/log-helper';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class CacheController extends Controller {
  public static targets = ['updatable'];

  private readonly updatableTargets!: Element[];
  private cacheDataSubject: BehaviorSubject<CacheValueMap> = new BehaviorSubject({ liveData: {} });

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public initialize() {
    this.listenToCacheUpdates();
  }

  public connect() {
    switch(this.data.get('updateMode')) {
      case 'always':
        this.fetchFreshData();
        break;
      case 'if-stale':
        this.fetchIfStale(1500);
        break;
    } 
  }

  private async fetchFreshData() {
    if (this.data.get('url')) {
      const cacheURL = this.data.get('url');
      const ids = this.updatableTargets.map((u) => (u as HTMLElement).dataset.cacheId).join(',');

      try {
        if (ids !== '') {
          const response = await secureFetch(`${cacheURL}?ids=${ids}`);
          const cachedResult: CacheValueMap = await response.json();
          this.cacheDataSubject.next(cachedResult);
        }
      } catch (e) {
        LogHelper.logError(e);
      }
    }
  }

  private fetchIfStale(thresholdMs) {
    const now = +(new Date());
    const generated = parseInt(this.data.get('generatedAt'), 10);
    if (now - generated > thresholdMs) {
      this.fetchFreshData();
    }
  }

  private listenToCacheUpdates() {
    this.subscriber = this.cacheDataSubject.asObservable().pipe(
      filter(data => Object.keys(data.liveData).length > 0)
    ).subscribe(data => {
      this.updatableTargets.forEach((cachedElement: HTMLElement) => {
        if (cachedElement.dataset.cacheId && data.liveData[cachedElement.dataset.cacheId]) {
          this.applyDataToCachedElement(cachedElement as HTMLElement, data.liveData[cachedElement.dataset.cacheId])
        }
      })
    })
  }

  private applyDataToCachedElement(element: HTMLElement, data: CacheValueMapData) {
    const keys = Object.keys(data);
    keys.forEach(key => {
      [element, ...Array.from(element.querySelectorAll(`[data-cache-replace-key*='${key}']`))].forEach((childElement: HTMLElement) => {
        switch (childElement.dataset.cacheReplaceType) {
          case 'attribute':
            this.replaceElementAttribute(childElement, key, data[key]);
            break;
          case 'innerHTML':
            childElement.innerHTML = data[key];
            break;
          default:
            break;
        }

        childElement.dataset.cacheReplaced = '';
      })
    })
  }

  private replaceElementAttribute(element: HTMLElement, key: string, value: string) {
    const attributeKey = key.replace('_', '-');
    const prefix = element.dataset.cacheReplacePrefix;
    element.setAttribute(`data-${prefix}-${attributeKey}`, value);
    EventHelper.dispatch(element, 'update', { key });
  }
}

interface CacheValueMap {
  liveData: { 
    [id: string]: CacheValueMapData
  }
}

interface CacheValueMapData {
  [key: string]: any;
}
