export function secureFetch(input: RequestInfo, init?: RequestInit) {
  console.log('Fetch: ', input);
  init = init || {};
  init = {
    ...init,
    headers: {
      ...init.headers,
      'X-CSRF-Token': getCSRFToken()
    },
    credentials: 'same-origin'
  }
  return fetch(input, init);
}

export function getCSRFToken() {
  return document.querySelector("meta[name=csrf-token]").getAttribute('content');
}
